<template>
    <form-record :model="model" :modelState="v$" :validation="v$" :validators="validators" @is-reset="onRecordReset" :saveOnly="saveOnly" :title="title" :viewBack="viewBack" :viewOnly="viewOnly">
        <div v-if="!recordNotFound" class="p-d-flex p-grid p-col-12">
            <div class="p-col-12 p-md-4">
                <form-input id="code" :v$="v$" v-code v-focus v-uppercase :value="model.code" @is-sync="model.code = $event.value" :max-length="20" />
                <form-input id="name" label="Registered Name" :v$="v$" :value="model.name" @is-sync="model.name = $event.value" :max-length="100" />
                <form-input id="altName" label="Common Name" :v$="v$" :value="model.altName" @is-sync="model.altName = $event.value" />
                <form-lookup v-if="model?.type > 2" :disabled="true" :value="model.environmentID" :source="ds?.data?.filter((x) => x.type === 1 || x.type === 2) ?? []" id="environmentID" label="Environment" :v$="v$" textField="name" />
                <form-lookup v-if="model?.type === 0" :source="[]" id="forwarderID" label="Forwarder" :v$="v$" />
                <form-lookup v-if="model?.type === 32" :source="(ds?.data ?? []).filter((x) => x.type === 16 && x.environmentID === model.environmentID)" id="customerID" label="Customer" :v$="v$" />
                <form-lookup v-if="model?.type === 128" :source="(ds?.data ?? []).filter((x) => x.type === 64 && x.environmentID === model.environmentID)" id="departmentid" label="Department" :v$="v$" />
            </div>
            <div class="p-col-12 p-md-4">
                <form-address id="address" :value="address" :v$="v$" @is-sync="translate($event)" :useCompany="false" :showLookup="true" />
                <form-lookup id="localTimeZone" :required="true" type="dropdown" sourceType="url" source="timezone" :value="model.id === 0 ?  (model.localTimeZone ?? 'UTC') : model.localTimeZone" :textField="'text'" :valueField="'value'" label="Time Zone" @is-sync="model.localTimeZone = $event.value ?? model.localTimeZone" :v$="v$"></form-lookup>
            </div>
            <div class="p-col-12 p-md-4">
            </div>
        </div>
        <div v-if="!recordNotFound" class="p-grid" style="margin: 0 -20px;">
            <div class="p-col-12">
                <pv-tab ref="companyTab" v-model:activeIndex="activeIndex">
                    <pv-tabpanel header="SCOPES" v-if="(model !== null && model !== undefined) && (model.id > 0 && (model.type === companyType.Warehouse || model.type === companyType.Customer))">
                        <company-scope :is_parentID="parentID" :is_parentMask="parentMask"></company-scope>
                    </pv-tabpanel>
                    <pv-tabpanel header="CARRIERS" v-if="(model !== null && model !== undefined) && (model.id > 0 && (model.type === companyType.Customer))">
                        <company-carrier v-if="model.type === 16" :customerID="model.id" :environmentID="model.environmentID" @is-sync="test = $event"></company-carrier>
                    </pv-tabpanel>
                    <pv-tabpanel header="DEFAULTS" v-if="(model !== null && model !== undefined) && model.id != 0 && (model.type == companyType.Environment || model.type == companyType.Customer)">
                        <!--<company-general-setting :formData="model.generalSetting" :showDefault="true" :is_parentMask="parentMask" @is-sync="sync_default($event)" @is-initial-sync="sync_generalSetting($event)" @is-mounted="isMounted($event)" />-->
                        <company-general-setting v-if="model.id != 0 && ((model.generalSetting?.id ?? 0) != 0)" :formData="model.generalSetting" :companyType="model?.type" :showDefault="true" :is_parentMask="parentMask" :serviceGroupDS="source?.serviceGroup ?? []" :accountCustomerDS="source?.accountCustomer ?? []" />
                        <company-package :parentID="model?.id" :formData="model" v-if="model.type == companyType.Customer"></company-package>
                    </pv-tabpanel>
                    <pv-tabpanel header="SCHEDULE" v-if="(model !== null && model !== undefined) && model.id > 0 && model.type == companyType.Warehouse">
                        <!--<company-general-setting :formData="model.generalSetting" :showSchedule="true" :is_parentMask="parentMask" @is-sync="sync_schedule($event)" @is-initial-sync="sync_generalSetting($event)" @is-mounted="isMounted($event)" />-->
                        <company-general-setting :formData="model.generalSetting" :showSchedule="true" :is_parentMask="parentMask" />
                    </pv-tabpanel>
                    <pv-tabpanel header="GENERAL SETTINGS" v-if="(model !== null && model !== undefined) && model.id != 0 && model.type != companyType.System">
                        <!--<company-general-setting :formData="model.generalSetting" :showSetting="true" :is_parentMask="parentMask" @is-sync="sync_setting($event)" @is-initial-sync="sync_generalSetting($event)" @is-mounted="isMounted($event)" />-->
                        <company-general-setting :formData="model.generalSetting" :minWarehouseLimit="((warehouses ?? []).filter(x => x.recordStatus <= 2)?.length ?? 0)" :companyType="model?.type" :showSetting="true" :is_parentMask="parentMask" />
                    </pv-tabpanel>
                    <pv-tabpanel id="Profile" v-if="model.id > 0 && model.type != companyType.Environment">
                        <template #header>
                            <div style="border-bottom: 1px solid inherit !important; padding: 0.9rem; margin: -1rem; min-width: 8rem; max-width: 8rem; text-align: center;">PROFILE<i v-if="state.profile" class="ml-2 fas fa-triangle-exclamation fa-fw" style="margin-left: 0.5rem; color: var(--text-danger)"></i></div>
                        </template>
                        <company-profile :is_reset="resetKey" :is_parentMask="parentMask" @is-sync="sync_profile($event)" @is-initial-sync="sync_profile($event)" @is-mounted="isMounted($event)" @is-validation-status="state.profile = $event" />
                    </pv-tabpanel>
                    <pv-tabpanel header="ALERT" v-if="model.id > 0 && (model.type == companyType.Customer)">
                        <company-alert :formData="model"></company-alert>
                    </pv-tabpanel>
                    <pv-tabpanel header="IMAGES">
                        <div class="p-grid" style="padding: 2rem 1rem;">
                            <div class="p-col-12 p-md-4">
                                <form-file :disabled="limitedAccess" id="file" label="Logo (light)" :required="true" theme="light" accept="image/*" :value="model.lightTheme?.logo" @is-sync="onImageUpload('lightTheme', 'logo', $event)"></form-file>
                                <form-file :disabled="limitedAccess" id="file" label="Logo (dark)" :required="true" theme="dark" accept="image/*" :value="model.darkTheme?.logo" @is-sync="onImageUpload('darkTheme', 'logo', $event)"></form-file>
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <form-file :disabled="limitedAccess" id="file" label="Icon (light)" :required="true" theme="light" accept="image/*" :value="model.lightTheme?.icon" @is-sync="onImageUpload('lightTheme', 'icon', $event)"></form-file>
                                    </div>
                                    <div class="p-col-6">
                                        <form-file :disabled="limitedAccess" id="file" label="Icon (dark)" :required="true" theme="dark" accept="image/*" :value="model.darkTheme?.icon" @is-sync="onImageUpload('darkTheme', 'icon', $event)"></form-file>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4">
                                <form-file :disabled="limitedAccess" id="file" label="Report Logo" :required="true" theme="document" accept="image/*" :value="model.reportLogo?.logo" @is-sync="onImageUpload('reportLogo', 'logo', $event)"></form-file>
                                <form-file :disabled="limitedAccess" id="file" label="Stamp" :required="true" theme="document" accept="image/*" :value="model.reportLogo?.stamp" @is-sync="onImageUpload('reportLogo', 'stamp', $event)"></form-file>
                            </div>
                            <div class="p-col-12 p-md-4">
                                <form-file :disabled="limitedAccess" id="file" label="Signature" :required="true" theme="document" accept="image/*" :value="model.reportLogo?.signature" @is-sync="onImageUpload('reportLogo', 'signature', $event)"></form-file>
                                <form-input :disabled="limitedAccess" id="signatory" ph="Name To Appear On Reports" :max-length="35" :value="model.signatory" @is-sync="model.signatory = $event.value" :showCopy="false" />
                                <form-input :disabled="limitedAccess" id="designation" ph="Designation of Signatory" :max-length="50" :value="model.designation" @is-sync="model.designation = $event.value" :showCopy="false" />
                                <form-input :disabled="limitedAccess" id="shippingConsultant" :max-length="35" :value="model.shippingConsultant" @is-sync="model.shippingConsultant = $event.value" :showCopy="false" />
                            </div>
                        </div>
                    </pv-tabpanel>
                    <pv-tabpanel header="PRINTERS" v-if="model.id > 0 && ((model.type == companyType.Customer) || (model.type == companyType.Warehouse))">
                        <company-printer :formData="model"></company-printer>
                    </pv-tabpanel>
                    <!--<pv-tabpanel header="ICS SETTINGS">
            ICS SETTINGS
        </pv-tabpanel>-->
                </pv-tab>
            </div>

        </div>
    </form-record>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import validator from '@/assets/_js/cosmos-validators.js';
    import generalsetting from "@/views/settings/defaults/company/generalsetting.vue";
    import scope from "@/views/settings/defaults/company/scope.vue";
    import profile from "@/views/settings/defaults/company/profile.vue";
    import carrier from "@/components/admin/provideraccount/customeraccount-form.vue";
    import defaultPackage from '@/components/settings/company/default-package-form.vue';
    import alertForm from '@/components/settings/company/companyalert-form.vue';
    import printer from '@/components/settings/company/companyprinter-form.vue';
    const moduleName = 'Company';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'company-general-setting': generalsetting,
            'company-scope': scope,
            'company-carrier': carrier,
            'company-profile': profile,
            'company-package': defaultPackage,
            'company-alert': alertForm,
            'company-printer': printer
        },
        data() {
            return {
                //address : null,
                model: {
                    code: null,
                    name: null,
                    altName: null,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    postalCode: null,
                    countryID: null,
                    signatory: null,
                    designation: null,
                    shippingConsultant: null,
                    lightTheme: { logo: null, icon: null },
                    darkTheme: { logo: null, icon: null },
                    reportLogo: { logo: null, stamp: null, signature: null },
                    localTimeZone: 'UTC'
                },
                resetKey: null,
                isChildMounted: false,
                test: false,
                validators: {
                    model: {
                        code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                        name: { required, maxLength: maxLength(100), $autoDirty: true },
                        altName: { maxLength: maxLength(35), $autoDirty: true },
                        signatory: { $autoDirty: true },
                        designation: { $autoDirty: true },
                        shippingConsultant: { $autoDirty: true },
                        lightTheme: {
                            logo: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                            icon: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        },
                        darkTheme: {
                            logo: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                            icon: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        },
                        reportLogo: {
                            logo: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                            stamp: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                            signature: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        },
                        //  default_ParcelCategory: { $autoDirty: true }
                        generalSetting: {
                            // $autoDirty: true,
                            default_ParcelCategory: { $autoDirty: true }
                        },
                        //address: {
                        //    countryId: { required, $autoDirty: true },
                        //    city: { required, maxLength: maxLength(35), $autoDirty: true },
                        //    postalCode: { maxLength: maxLength(35), $autoDirty: true },
                        //    state: { maxLength: maxLength(35), $autoDirty: true },
                        //    address1: { required, maxLength: maxLength(35), $autoDirty: true },
                        //    address2: { maxLength: maxLength(35), $autoDirty: true },
                        //    address3: { maxLength: maxLength(35), $autoDirty: true },
                        //}
                    }
                },
                activeIndex: 0,
                state: {
                    profile: false
                },
                warehouses: [],
                source: {
                    serviceGroup: [],
                    accountCustomer:[]
                }
                ,processing: {
                    serviceGroup: false,
                    accountCustomer: false
                }
            }
        },
        watch: {
            $route(to, from) {
                if (to?.params?.mask && from?.params?.mask)
                    this.$router.go(0);
            },
            "state.profile":
            {
                handler() {
                    if (this.model.id > 0 && (this.v$.$invalid || this.v$.$error)) {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                if (this.state.profile != false) {
                                    this.activeIndex = (this.$refs.companyTab?.tabs ?? []).findIndex(x => x.props?.id === "Profile") !== -1 ? (this.$refs.companyTab?.tabs ?? []).findIndex(x => x.props?.id === "Profile") : 0;
                                }                                
                            }, 1000);
                        })
                    }
                },
                deep: true,
                immediate: true
            },
            "model.mask":
            {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.mask) {
                            this.getAccountCustomer();
                            this.getServiceGroup();
                            this.$nextTick(() => {
                                    this.getGeneralSetting();
                            });
                            if ((this.model?.id ?? 0) !== 0)
                                this.onGetWarehouse();
                        }
                    });
                },
                deep: true
            },
            //"model.countryID": {
            //    handler() {
            //        this.$nextTick(() => {
            //            if (this.model?.id === 0 && this.model.countryID === null) {
            //                this.model.countryID = this.model.countryID ?? this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
            //            }
            //        });
            //    },
            //    deep: true
            //}
        },
        validations() {
            return {
                test: { $autoDirty: true },
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, maxLength: maxLength(100), $autoDirty: true },
                    altName: { maxLength: maxLength(35), $autoDirty: true },
                    address1: { $autoDirty: true },
                    address2: { $autoDirty: true },
                    address3: { $autoDirty: true },
                    state: { $autoDirty: true },
                    city: { $autoDirty: true },
                    postalCode: { $autoDirty: true },
                    countryID: { $autoDirty: true },
                    companyID: { $autoDirty: true },
                    signatory: { $autoDirty: true },
                    designation: { $autoDirty: true },
                    shippingConsultant: { $autoDirty: true },
                    localTimeZone: { $autoDirty: true },
                    lightTheme: {
                        logo: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        icon: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                    },
                    darkTheme: {
                        logo: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        icon: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                    },
                    reportLogo: {
                        logo: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        stamp: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                        signature: [{ file: { $autoDirty: true }, url: { $autoDirty: true } }],
                    },
                  //  default_ParcelCategory: { $autoDirty: true }
                    generalSetting: {
                       // $autoDirty: true,
                        default_ParcelCategory: { $autoDirty: true }
                    },
                    //address: {
                    //    countryId: { required, $autoDirty: true },
                    //    city: { required, maxLength: maxLength(35), $autoDirty: true },
                    //    postalCode: { maxLength: maxLength(35), $autoDirty: true },
                    //    state: { maxLength: maxLength(35), $autoDirty: true },
                    //    address1: { required, maxLength: maxLength(35), $autoDirty: true },
                    //    address2: { maxLength: maxLength(35), $autoDirty: true },
                    //    address3: { maxLength: maxLength(35), $autoDirty: true },
                    //}
                }
                
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            parentID() {
                return this.model.id;
            },
            parentMask() {
                return this.model.mask;
            },
            ds() { return this.$store.getters["company/ds"]; },
            record() { return this.$store.getters["company/record"]; },
            errors() { return this.$store.getters["company/errors"]; },
            address() {
                return  {
                    address1: this.model.address1,
                    address2: this.model.address2,
                    address3: this.model.address3,
                    city: this.model.city,
                    state: this.model.state,
                    postalCode: this.model.postalCode,
                    countryID: this.model.countryID
                };
            },
            saveOnly() {
                return this.$router.currentRoute.value.meta.type.toLowerCase() === "company";
            },
            viewOnly() {
                return this.user?.userType > 5 || this.user?.userType ===4;
            },
            viewBack() {
                return this.$router.currentRoute.value.meta.type.toLowerCase() !== "company";
            }
            ,title() {
                return this.$router.currentRoute.value.meta.type.toLowerCase() !== "company" ?
                    this.$filters.singularize(this.model?.altName ?? this.model?.name ?? this.$router.currentRoute.value.meta.title ?? "") 
                    : (this.model?.altName ?? this.model?.name)
            },
            companyType() {
                return {
                    System: 1,
                    Environment: 2,
                    Forwarder: 4,
                    Warehouse: 8,
                    Customer: 16
                }
            },
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            recordNotFound() {
                return this.model.id === 0 && this.$route.params.mask;
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            },
        },
        methods: {
            onRecordReset() {
                this.resetRecord();
                this.getCompanyList();
                if ((this.model?.id ?? 0) != 0) {
                    this.getServiceGroup();
                    this.getGeneralSetting();
                }
                this.resetKey = Math.random(100);
            },
            async onTimeZone() {
                this.model.localTimeZone = "UTC";
            },
            onImageUpload(property, subProperty, event) {
                const file = event;
                if (this.model[property] === undefined)
                    this.model[property] = {};
                this.model[property][subProperty] = file;
            },
            async getCompanyList() {
                return await this.$store.dispatch("company/getAll", { sort: null, where: null });
            },
            async isMounted(value) { 
                this.$nextTick(() => {
                    this.isChildMounted = value;
                    this.v$.$reset();
                });
               
            },
             sync_generalSetting(model) {
                this.$nextTick(() => {
                    this.model.generalSetting = model;
                    if (this.model.generalSetting.companyID === null)
                        this.model.generalSetting.companyID = this.model.id;
                });
            },
            async sync_default(model) {
                this.$nextTick(() => {
                    if (this.model.generalSetting.id === 0)
                        this.sync_generalSetting(model);
                    this.model.generalSetting.default_ParcelCategory = model.default_ParcelCategory;
                    this.model.generalSetting.default_IncoTerm = model.default_IncoTerm;
                    this.model.generalSetting.default_CurrencyID = model.default_CurrencyID;
                    this.model.generalSetting.default_DeclaredValue = model.default_DeclaredValue;
                    this.model.generalSetting.default_InsuranceProportion = model.default_InsuranceProportion;
                    this.model.generalSetting.default_ParcelContent = model.default_ParcelContent;
                    this.model.generalSetting.default_WeightUnit = model.default_WeightUnit;
                    this.model.generalSetting.default_LengthUnit = model.default_LengthUnit;
                    this.model.generalSetting.default_EstablishmentType = model.default_EstablishmentType;
                    this.model.generalSetting.default_CustomerCategory = model.default_CustomerCategory;
                    this.model.generalSetting.default_ServiceGroupID = model.default_ServiceGroupID;
                    this.model.generalSetting.default_AccountCustomerID = model.default_AccountCustomerID;

                    this.model.generalSetting.default_InvoiceCurrencyID = model.default_InvoiceCurrencyID;
                    this.model.generalSetting.default_InvoiceVATRate = model.default_InvoiceVATRate;
                    this.model.generalSetting.default_InvoiceVATRateType = model.default_InvoiceVATRateType;
                    this.model.generalSetting.default_Notification = model.default_Notification;
                    this.model.generalSetting.default_NotificationRecipient = model.default_NotificationRecipient;

                    if (this.isChildMounted)
                        this.v$.$touch();
                });
             
            },
            async sync_schedule(model) {
                this.$nextTick(() => {
                    if (model !== null && model !== undefined) {
                        if (this.model.generalSetting.id === 0)
                            this.sync_generalSetting(model);
                        this.model.generalSetting.schedule_CutOffTime = model.schedule_CutOffTime;
                        this.model.generalSetting.schedule_ReadyTime = model.schedule_ReadyTime;
                        this.model.generalSetting.schedule_CollectionTime = model.schedule_CollectionTime;
                        this.model.generalSetting.shedule_ClosingTime = model.schedule_ClosingTime;
                        this.model.generalSetting.schedule_CollectionDays = model.schedule_CollectionDays;
                        this.model.generalSetting.schedule_StrictCutOff = model.schedule_StrictCutOff;
                    }
                    
                    if (this.isChildMounted)
                        this.v$.$touch();
                });
               
            },
            async sync_setting(model) {
                this.$nextTick(() => {
                    if (model !== null && model !== undefined) {
                        if (this.model.generalSetting.id === 0)
                            this.sync_generalSetting(model);
                        this.model.generalSetting.setting_CheckState = model.setting_CheckState;
                        this.model.generalSetting.setting_CheckPostalCode = model.setting_CheckPostalCode;
                        this.model.generalSetting.setting_UseSupport = model.setting_UseSupport;
                        this.model.generalSetting.setting_SupportRedirectEmail = model.setting_SupportRedirectEmail;
                        this.model.generalSetting.setting_AutoArchive = model.setting_AutoArchive;
                        this.model.generalSetting.setting_DaysToAutoArchive = model.setting_DaysToAutoArchive;
                        this.model.generalSetting.setting_AutoSystemArchive = model.setting_AutoSystemArchive;
                        this.model.generalSetting.setting_DaysToAutoSystemArchive = model.setting_DaysToAutoSystemArchive;
                        this.model.generalSetting.setting_AutoCompanyArchive = model.setting_AutoCompanyArchive;
                        this.model.generalSetting.setting_DaysToAutoCompanyArchive = model.setting_DaysToAutoCompanyArchive;
                        this.model.generalSetting.setting_UserLimit = model.setting_UserLimit;
                        this.model.generalSetting.setting_WarehouseLimit = model.setting_WarehouseLimit;
                        this.model.generalSetting.setting_ParcelLimit = model.setting_ParcelLimit;

                        this.model.generalSetting.setting_UseSupport = model.setting_UseSupport;
                        this.model.generalSetting.setting_SupportRedirectEmail = model.setting_SupportRedirectEmail;
                        this.model.generalSetting.setting_AutoNumberConsignment = model.setting_AutoNumberConsignment;
    
                    }
                    
                    if (this.isChildMounted)
                        this.v$.$touch();
                });
               
            },
            async sync_profile(model) {
                this.$nextTick(() => {
                    this.model.profile = model;
                    if (this.model.profile.companyID === null)
                        this.model.profile.companyID = this.model.id;

                    if (this.isChildMounted)
                        this.v$.$touch();
                });
               
            },
            getGeneralSetting() {
                this.$axios
                    .get(this.$config.config.endpoint.api + `/services/settings/companygeneralsetting/ByCompany/${this?.model?.mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.sync_generalSetting(response.data.result);
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            getServiceGroup() {
                let isProcessing = false;
                isProcessing = (this.processing.serviceGroup ?? false);
                if (!isProcessing) {
                    
                    this.processing.serviceGroup = true;
                    this.$nextTick(() => {
                        if ((this.model?.id ?? 0) != 0) {
                            this.$axios.get(this.$config.config.endpoint.api + `/services/facility/servicegroup/customer/id/${this.model?.id}`.replaceAll("//", "/")).then((result) => {
                                this.source.serviceGroup = result?.data?.result ?? result?.result ?? [];
                                this.processing.serviceGroup = false;
                            });
                        }
                        else {
                            this.processing.serviceGroup = false;
                        }
                    });
                }
            },
            getAccountCustomer() {
                let isProcessing = false;
                isProcessing = (this.processing.accountCustomer ?? false);
                if (!isProcessing) {
                    this.processing.accountCustomer = true;
                    this.$nextTick(() => {
                        if ((this.model?.id ?? 0) != 0) {
                            
                            this.$axios.get(this.$config.config.endpoint.api + `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.model?.id}`.replaceAll("//", "/")).then((result) => {
                                this.source.accountCustomer = result?.data?.result ?? result?.result ?? [];
                                this.processing.accountCustomer = false;
                            });
                        }
                        else {
                            this.processing.accountCustomer = false;
                        }
                    });
                }
            },
            onGetWarehouse() {
                if (this.model.type === this.companyType.Environment) {
                    this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/company?type=8&bpf=1&activeOnly=true&XXX=1`.replaceAll("//", "/"))
                        .then((response) => {
                            this.warehouses = (response.data?.result ?? response.result ?? []).filter(x => x.environmentID == (this.model.id) && x.type == 8);
                        })
                        .catch((ex) => {
                            this.errors = ex.data;
                        });
                }
            },
            translate(e) {
                this.model.address1 = e.address1;
                this.model.address2 = e.address2;
                this.model.address3 = e.address3;
                this.model.city = e.city;
                this.model.state = e.state;
                this.model.postalCode = e.postalCode;
                this.model.countryID = e.countryID;
                this.address = {
                    address1: this.model.address1,
                    address2: this.model.address2,
                    address3: this.model.address3,
                    city: this.model.city,
                    state: this.model.state,
                    postalCode: this.model.postalCode,
                    countryID: this.model.countryID
                };
            },
            command() {
                console.log("command");
            }
        },
        created() {
            this.model.countryID = this.model.countryID ?? this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
        },
        mounted() {
            this.getCompanyList();
            this.activity.loading = false;
            if ((this.model?.id ?? 0) != 0) {
                this.getServiceGroup();
                this.getGeneralSetting();
            }
            if (this.model.id === 0)
            {
                this.model.countryID = this.model.countryID ?? this.$vm.$countries.filter(x => x.code === this.$vm.$location.country)[0].id;
                this.model.environmentID = Number(atob(sessionStorage.getItem('activekeys')).split('|')[1]);
            }
            this.activeIndex = 0;
        },
        beforeUnmount() {
            if (this.model.id !== 0 && (this.model.type === 1 || this.model.type === 2)) {
                this.$store.dispatch("companyprofile/getURL", { url: `/services/settings/companygeneralsetting/bycompany/${this.model?.mask}`.replaceAll("//", "/") })
                    .then((response) => {
                        this.$store.commit("auth/SET_SESSION_SETTING", response.result);
                    });
            }
        },
        updated() {
          
            switch (this.$router.currentRoute.value.meta.type.toLowerCase()) {
                case "environment":
                case "contract":
                    this.model.type = 2;
                    this.model.environmentID = null;
                    break;
                case "forwarder":
                    this.model.type = 4;
                  
                    break;
                case "warehouse":
                    this.model.type = 8;
                    break;
                case "customer":
                    this.model.type = 16;
                    break;
                case "retailer":
                    this.model.type = 32;
                    break;
                case "department":
                    this.model.type = 64;
                    break;
                case "team":
                    this.model.type = 128;
                    break;
            }
        }
    }
</script>
