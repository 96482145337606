<template>
    <div class="p-d-flex p-flex-wrap p-flex-row p-flex-md-wrap p-flex-md-row ">
        <div class="p-col" style="min-width: 400px; padding: 0;">
            <div class="p-d-flex p-nogutter">
                <div class="p-col">
                    <form-datetime-utc id="effectiveDateFrom" type="date" :value="model?.effectiveDateFROM" @is-sync="model.effectiveDateFROM = $event?.value" :required="true" />
                </div>
                <div class="p-col">
                    <form-datetime-utc id="effectiveDateTo" type="date" :value="model?.effectiveDateTO" @is-sync="model.effectiveDateTO = $event?.value" />
                </div>
            </div>
            <form-object :id="id ?? 'services'" :autoFit="autoFit" label="Services" :floatLabel="floatLabel && (!disabled || objBlank)">
                <pv-multiselect v-model="model.services" :options="services" optionLabel="text" placeholder="All Services" optionValue="productDetail.id" dataKey="value" display="chip" />
            </form-object>
            <form-lookup id="rateCardType" type="enum" ph="Inherit From Account" source="ratecardtype" :value="model.rateCardType" @is-sync="model.rateCardType = $event?.value" />
            <div v-if="model.rateCardType === 100" class="p-d-flex p-nogutter">
                <div class="p-col-fixed" style="width:10rem">
                    <form-lookup id="markUpType" source="markupratetype" type="enum" :required="true" :sorted="false" :value="model?.rateType" @is-sync="model.rateType = $event.value" />
                </div>
                <div class="p-col">
                    <form-numeric id="rateApplied" type="decimal" :max="model?.rateType == 200 ? 100 : 9999999999.99" :required="true" :value="model?.rateApplied" @is-sync="model.rateApplied = $event.value" />
                </div>
                <div v-if="model?.rateType === 300" class="p-col-fixed" style="width:12rem">
                    <form-lookup id="chargePer" label="Charge Per" source="ratechargingtype" type="enum" :required="true" :sorted="false" :value="model?.rateChargeType" @is-sync="model.rateChargeType = $event.value" />
                </div>
            </div>
            <div v-if="model?.rateType === 300" style="color: var(--text-danger); font-size: 0.85rem; padding: 0 0.75rem; margin-top: -1rem;">When using <strong>[FIXED RATE]</strong> it means that when quoting the customer the rate applied value will be used.</div>
        </div>
        <div class="p-col" style="min-width: 400px; max-height: 70vh; overflow-y: auto">
            <markup-ruleconditions-form :parentID="model?.id"
                                        :source="conditions"
                                        :currencyCode="currencyCode"
                                        :countryCode="countryCode"
                                        :selectedProducts="model.services"
                                        :carrierProducts="services"
                                        :providerID="model.serviceProviderID ?? providerID"
                                        :currencyID="model.contractCurrencyID ?? countryID"
                                        :countryID="model.contractCountryID ?? countryID"
                                        :zones="sources.zones"
                                        @is-sync="model.conditions = $event" />
        </div>
    </div>
</template>
<script>
    import markupruleconditionsform from '@/components/facility/rates/markup/markup-ruleconditions-form.vue';
    export default {
        name: 'MarkupRuleDetailForm',
        components: { 'markup-ruleconditions-form': markupruleconditionsform },
        props: {
            source: { type: Object }, // This should only have value for 
            parentID: { type: Number }, // Markup Contract Account ID
            accountID: { type: Number },  // Customer Account ID
            currencyCode: { type: String },
            countryCode: { type: String },
            providerID: { type: Number }, // Markup Contract Account ID
            currencyID: { type: Number },  // Customer Account ID
            countryID: { type: Number },  // Customer Account ID
        },
        data() {
            return {
                model: {
                    id: 0,
                    markupContractAccountID: 0,
                    effectiveDateFROM: null,
                    effectiveDateTO: null,
                    rateType: 200,
                    rateApplied: 0,
                    rateChargeType: 100,
                    environmentID: 0,
                    currencyCode: null,
                    countryCode: null,
                    providerID: null,
                    currencyID: null,
                    countryID: null,
                    conditions: [],
                    services: []
                },
                sources: {
                    services: []
                }
            }
        },
        computed: {
            services() {
                return this.sources.services;
            },
            conditions() {
                return this.model?.conditions ?? [];
            }
        },
        watch: { model: { handler() { this.$emit("is-sync", this.model); }, deep: true } },
        methods: {
            onSourceRebind() {
                if (this.accountID) {
                    this.$store.dispatch("markupcontractaccount/getURL", { url: `/services/admin/serviceprovideraccountcustomerproduct/bycustomeraccount/id/${this.accountID}` })
                        .then((response) => {
                            this.sources.services = [];
                            if (response.success)
                                this.sources.services = response.result;
                        });
                }
                if ((this.model?.id ?? 0) > 0)
                    this.$store.dispatch("markupcontractrulecondition/getURL", { url: `/services/facility/markupcontractrulecondition/byrule/id/${this.model.id}` })
                        .then((response) => {
                            this.model.conditions = [];
                            if (response.success)
                                this.model.conditions = [...response.result];
                        });

            }
        },
        created() {
            this.model = this.$filters.init(this.source ?? {
                id: 0,
                markupContractAccountID: this.parentID,
                customerAccountDetailID: this.accountID,
                effectiveDateFROM: this.$moment.utc(),
                effectiveDateTO: null,
                rateType: 200,
                rateApplied: 0,
                rateChargeType: 100,
                environmentID: 0,
                currencyCode: this.currencyCode,
                countryCode: this.countryCode,
                currencyID: this.currencyID,
                countryID: this.countryID,
                providerID: this.providerID,
                conditions: [],
                services: []
            });
            if (this.source)
                this.model.services = this.source.services;
            this.$nextTick(() => {
                this.onSourceRebind();
            });
        },
        mounted() {
            this.$nextTick(() => {
                this.onSourceRebind();
            });
        }
    }
</script>
