<template>
    <!-- ALERT CONFIGURATION -->
    <keep-alive>
        <is-dialog header="ALERT CONFIGURATION" :visible="dialog.delete && (dialog.edit || dialog.add)" @is-confirm="onManageAlert(model)" @is-cancel="this.dialog.delete = false;this.dialog.edit = false;this.dialog.add = false" :breakpoints="{'960px': '75vw', '100px': '10vw'}" :style="{width: '80vw'}" :confirmDisabled="this.v$.$invalid" :confirmLoading="activity.processing">
            <div class="confirmation-content p-fluid p-grid" style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:-3.5rem;">
                <div class="p-grid p-col-12">
                    <div class="p-col-12 p-md-8">
                        <div class="p-d-flex p-col-6">
                            <div class="p-col-12">
                                <form-lookup v-if="(model?.parentID ?? 0) !== 0" id="parentLanguageID" :required="true" sourceType="array" :source="languageSource.parent" :textField="'name'" :valueField="'id'" :value="model.parentLanguageID" label="Language" @is-sync="model.parentLanguageID = $event?.value ?? model.parentLanguageID" :v$="v$"></form-lookup>
                                <form-lookup v-show="(model?.parentID ?? 0) === 0" id="alertType" :disabled="model?.id > 0" :required="true" sourceType="array" :source="alertTypeDS" :value="model.alertType" :textField="'text'" :valueField="'id'" label="Alert Type" @is-sync="model.alertType = $event.value" :v$="v$"></form-lookup>
                                <form-input id="senderName" label="Sender Name" :v$="v$" :value="model.senderName" @is-sync="model.senderName = $event.value" :max-length="100" />
                            </div>
                            <div class="p-col-12">
                                <form-lookup id="languageID" :required="true" sourceType="array" :source="languageSource.variant" :textField="'name'" :valueField="'id'" :value="model.languageID" :label="((model?.parentID ?? 0) !== 0 ? 'Variant' : 'Language')" @is-sync="model.languageID = $event?.value ?? model.languageID" :v$="v$" :sorted="false"></form-lookup>
                                <form-input id="senderEmail" label="Sender Email" :v$="v$" :value="model.senderEmail" @is-sync="model.senderEmail = $event.value" placeholder="alert@no-reply.com" :max-length="100" />
                            </div>
                        </div>
                        <div class="p-col-12 p-grid" style="margin-top: -2.4rem; padding: 0.8rem">
                            <div class="p-col-12" style="padding:0.70rem;">
                                <form-input id="subject" label="Subject" :v$="v$" :value="model.subject" @is-sync="model.subject = $event.value" :max-length="200" :ph="'[{{COMPANYNAME}}] Dispatch Alert (Order # {{ORDERREFERENCE}})'" />
                                <form-input v-if="isRTLSupport" id="bodyRTL" type="editor" :required="true" :hideLabel="true" :rtl="true" :rows="13" :editorContentResize="true" v-model="model.body" />
                                <form-input v-else id="body" type="editor" :hideLabel="true" :required="true" :rtl="false" :rows="13" :editorContentResize="true" v-model="model.body" />
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4" style="max-height:45rem; overflow-x:auto;">
                        <pv-accordion :activeIndex="[0,1,2]" :multiple="true">
                            <pv-accordion-tab>
                                <template #header>
                                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="pi pi-bars"></i></div>
                                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="GENERAL SETTINGS">
                                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`GENERAL SETTINGS`"></span></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="card" style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:-1rem; font-size: 12px; ">
                                    <div v-if="(model?.parentID ?? 0) === 0" style="font-size: 12px; color: red; background: #ffdede !important; border-color: #D40112 !important; padding: 0.5rem">Please be aware that this template and configuration will always be applied for the <u><b>Rest of the World</b></u>.</div>
                                    <b>IMPORTANT NOTES:</b>
                                    <ul>
                                        <li>All keywords are <b>case sensitive</b>.</li>
                                        <li>Only configured keywords are allowed.</li>
                                    </ul>
                                    <b>SAMPLE USAGES:</b>
                                    <ul>
                                        <li><b>TEMPLATE:</b> [&#x007b;&#x007b;COMPANYNAME}}]  Alert (Order # &#x007b;&#x007b;ORDERREFERENCE}})</li>
                                        <li><b>OUTPUT:</b> [{{keywordSource[`COMPANYNAME`]}}]  {{ $vm.$enums['alerttype'].filter((x) => x.id == model?.alertType)[0]?.text }} Alert (Order # {{keywordSource[`COMPANYNAME`]}}"])</li>
                                    </ul>
                                    <!--<div class="returns-alert-info" v-if="model?.alertType !== 100">
                                <b>ALERT TYPE</b>
                                <p class="error">Returns alert is a special alert that will only works if returns profile is active and the shipment is marked as returnable. Please be aware that this type of alert always returns provider confirmation reference in order to be sent out.</p>
                            </div>-->
                                </div>
                            </pv-accordion-tab>
                            <pv-accordion-tab style="padding:0 !important;">
                                <template #header>
                                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="pi pi-bars"></i></div>
                                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="SUBJECT KEYWORDS">
                                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`SUBJECT KEYWORDS`"></span></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="card" style="font-size:12px;">
                                    <table style="width:calc(100% + 2rem);padding:0; margin:-2rem -1rem; margin-bottom:-1rem;" cellspacing="0">
                                        <thead>
                                            <tr><th class="p-code-text" style="text-align:left;background:var(--surface-d); padding:0.25rem 0.5rem" width="150px"><b>KEYWORDS</b></th><th class="p-code-text" style="text-align:left;background:var(--surface-d); padding:0.25rem 0.5rem">SAMPLE OUTPUT</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr><th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">&#x007b;&#x007b;COMPANYNAME}}</th><td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">{{keywordSource[`COMPANYNAME`]}}</td></tr>
                                            <tr><th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">&#x007b;&#x007b;TRANSACTIONID}}</th><td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">{{keywordSource[`TRANSACTIONID`]}}</td></tr>
                                            <tr><th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">&#x007b;&#x007b;ORDERREFERENCE}}</th><td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">{{keywordSource[`ORDERREFERENCE`]}}</td></tr>
                                            <tr><th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">&#x007b;&#x007b;CARRIER}}</th><td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">{{keywordSource[`CARRIER`]}}</td></tr>
                                            <tr><th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">&#x007b;&#x007b;CARRIERREFERENCE}}</th><td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d);">{{keywordSource[`CARRIERREFERENCE`]}}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </pv-accordion-tab>
                            <pv-accordion-tab>
                                <template #header>
                                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="pi pi-bars"></i></div>
                                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="BODY KEYWORDS">
                                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`BODY KEYWORDS`"></span></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="card" style="font-size:12px;">
                                    <table style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                                        <thead>
                                            <tr><th class="p-code-text" style="text-align:left;background:var(--surface-d); padding:0.25rem 0.5rem" width="150px"><b>KEYWORDS</b></th><th class="p-code-text" style="text-align:left;background:var(--surface-d); padding:0.25rem 0.5rem">SAMPLE OUTPUT</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(value,key) in keywordSource" :key="value" style="border-bottom:thin; border:0.01rem;">
                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">&#x007b;&#x007b;{{key}}}}</th>
                                                <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="value"></div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </pv-accordion-tab>
                        </pv-accordion>
                    </div>
                </div>
                
            </div>
        </is-dialog>
    </keep-alive>
    <!--PREVIEW-->
    <is-dialog header="PREVIEW" :visible="dialog.preview" confirm="Send" confirmIcon="pi pi-fw pi-send" @is-confirm="onSendAlert(model)" @is-cancel="this.dialog.preview = false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
        <div class="confirmation-content p-fluid p-grid">
            <div class="p-col-12 p-grid">
                <div class="p-col-12">
                    <form-input id="senderFrom" label="FROM" :v$="v$" :value="fromEmail" />
                    <form-input id="subjectPreview" label="SUBJECT" :v$="v$" :value="model.subjectPreview" :disabled="true" />
                    <form-input id="toPreview" label="TO" :v$="v$" :value="model.toPreview" :disabled="true" />
                    <form-input id="toPreview" label="E-Mail" :required="true" :v$="v$" :value="model.toEmailPreview" @is-sync="model.toEmailPreview = $event.value" />
                    <form-input v-if="isRTLSupport" id="bodyRTL" type="editor" :hideLabel="true" :value="model.bodyPreview" :disabled="true" :rtl="true" :rows="10" :editorContentResize="true" @is-sync="model.bodyPreview = $event.value" />
                    <form-input v-else id="body" type="editor" :hideLabel="true" :value="model.bodyPreview" :rtl="false" :disabled="true" :rows="10" :editorContentResize="true" @is-sync="model.bodyPreview = $event.value" />
                </div>
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManageAlert(model)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data).replace("Text","") }}</th>
                        <td>:</td>
                        <td> {{ forDeleteAlert[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>

    <!-- ALERT SCOPE CONFIGURATION -->
    <keep-alive>
        
        <is-dialog :header="`${headerText}`" :visible="dialog.scope" @is-cancel="this.dialog.scope = false;this.dialog.assignCountry = false;" :showConfirm="false" :breakpoints="{'500px': '75vw', '400px': '100vw'}" :style="{width: '60vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-col-12">
                    <company-alertscope :formData="model" :sourceData="(source?.data ?? []).filter(x => x.alertType == model.alertType)" :manageCountry="dialog.assignCountry" @is-sync="onSyncScope($event)"></company-alertscope>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    
    <cosmos-grid id="CompanyAlertList" sourceType="url" :source="`/services/settings/companyalert/customer/id/${this.$props.formData?.id}/parent/`" :forceRefresh="forceRefresh.parent" :showCheckbox="false" :sourcePaged="true" :multiLevel="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="true" :showOperation="false" @is-source="onSyncSource($event)">
        <template #toolbar>
            <div class="p-d-flex p-jc-between p-ai-center">
                <span>
                    <pv-button v-if="(limitedAccess ? !limitedAccess : showAdd)" icon="pi pi-fw pi-plus" label="Add New Alert" style="margin-right: 5px;" @click="onAlertCreate"></pv-button>

                </span>
            </div>
        </template>
        <pv-grid-column field="code" header="Alert Type" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div>
                        <div class="p-d-flex p-jc-between" style="min-width: 7rem; margin-bottom: 5px;">
                            {{ $vm.$enums['alerttype'].filter((x) => x.id == slotProps.data?.alertType)[0]?.text }}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="languageID" header="Default Language" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div>
                        <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                            {{ $vm.$languages?.filter((x) => x.id == slotProps.data?.languageID)[0]?.name }}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="subject" header="Subject" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%">
                        <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                            {{  slotProps.data?.subject  }}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="subject" header="Alert Sent" headerStyle="min-width: 5rem; max-width: 13rem; width: 5rem" style="min-width: 5rem; max-width: 13rem;text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%">
                        <div class="p-d-flex p-jc-right" style="min-width: 10rem; margin-bottom: 5px;">
                            {{  slotProps.data?.isActive ? "0" : "0" }}
                        </div>
                    </div>
                    <slot :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="isActive" header="Status" headerStyle="min-width: 11rem; max-width: 11rem; width: 11rem" style="width: 11rem; min-width: 11rem; max-width: 11rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
            <template #body="slotProps">
                <pv-toggle :disabled="limitedAccess"  v-model="slotProps.data.isActive" @change="onSetStatus($event,slotProps?.data)" :style="[{'width': '8rem'}, {'object-position': 'bottom'}, {'align-content': 'end' }]" class="p-inputgroup-prepend" :onIcon="'pi pi-check'" :offIcon="'pi pi-times'" :onLabel="'ACTIVE'" :offLabel="'INACTIVE'"></pv-toggle>
            </template>
        </pv-grid-column>
        <pv-grid-column field="subject" headerStyle="min-width: 15rem; max-width: 23rem; width: 23rem" style="width: 23rem; min-width: 15rem; max-width: 23rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 100%;">
                    <div p-col>
                        <pv-button  :disabled="limitedAccess"  class="p-mr-1" label="Add Languange" icon="fa fa-language fa-fw" @click="onAddLanguage(slotProps.data)"></pv-button>
                    </div>
                    <div p-col>
                        <pv-button  :disabled="limitedAccess"  class="p-mr-1" label="Manage Country" icon="fa fa-globe fa-fw" @click="onManageScopeCountry(slotProps.data,false)"></pv-button>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" headerStyle="min-width: 10.5rem; max-width: 10.5rem; width: 10.5rem" style="`width: 10.5rem;min-width: 10.5rem; max-width: 10.5rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 100%;">
                    <div class="p-col">
                        <pv-button icon="k-icon k-i-file-txt" iconPos="right" :loading="loading" @click="onAlertPreview(slotProps.data)" title="Preview" />
                    </div>
                    <div class="p-col">
                        <pv-button  :disabled="limitedAccess"  icon="pi pi-pencil" iconPos="right" @click="onAlertEdit(slotProps.data)" class="p-button-info" />
                    </div>
                    <div class="p-col">
                        <pv-button  :disabled="limitedAccess"  icon="pi pi-trash" iconPos="right" @click="onAlertDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
        <template #expansion="slotProps">
            <div class="p-d-flex p-col-12">
                <div class="p-col-12">
                    <cosmos-grid :id="`SubAlertList_${slotProps.data.code}`" :showCheckbox="false" sourceType="url" :source="`/services/settings/companyalert/parent/id/${slotProps.data.id}/`" :forceRefresh="forceRefresh.sub" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="false" :showOperation="false">
                        <pv-grid-column field="languageID" header="Default Language" headerStyle="min-width: 13rem; max-width: 13rem; width: 13rem" style="min-width: 13rem; max-width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                            <template #body="slotProps">
                                <div class="p-d-flex">
                                    <div style="width:80%">
                                        <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                                            {{ $vm.$languages?.filter((x) => x.id == slotProps.data?.languageID)[0]?.name }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </pv-grid-column>
                        <pv-grid-column field="subject" header="Subject" headerStyle="min-width: 5rem; max-width: 25rem;" style="min-width: 5rem; max-width: 25rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap;">
                            <template #body="slotProps">
                                <div class="p-d-flex">
                                    <div style="width:100%">
                                        <div class="p-d-flex p-jc-between" style="min-width: 10rem; margin-bottom: 5px;">
                                            {{  slotProps.data?.subject  }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </pv-grid-column>
                        <pv-grid-column field="subject" headerStyle="min-width: 13rem; max-width: 13rem; width: 13rem" style="min-width: 13rem; max-width: 13rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; ">
                            <template #body="slotProps">
                                <div class="p-d-flex" style="width: 2rem;">
                                    <div p-col>
                                        <pv-button class="p-mr-1" label="Manage Country" icon="fa fa-globe fa-fw" @click="onManageScopeCountry(slotProps.data,true)"></pv-button>
                                    </div>
                                </div>
                            </template>
                        </pv-grid-column>
                        <pv-grid-column field="mask" headerStyle="min-width: 10.5rem; max-width: 10.5rem; width: 10.5rem" style="`width: 10.5rem;min-width: 10.5rem; max-width: 10.5rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
                            <template #body="slotProps">
                                <div class="p-d-flex" style="width:100%;">
                                    <div class="p-col">
                                        <pv-button icon="k-icon k-i-file-txt" iconPos="right" :loading="loading" @click="onAlertPreview(slotProps.data)" title="Preview" />
                                    </div>
                                    <div class="p-col">
                                        <pv-button icon="pi pi-pencil" iconPos="right" @click="onAlertEdit(slotProps.data)" class="p-button-info" />
                                    </div>
                                    <div class="p-col">
                                        <pv-button icon="pi pi-trash" iconPos="right" @click="onAlertDelete(slotProps.data)" class="p-button-danger" />
                                    </div>
                                    <slot name="operation" :data="slotProps.data">
                                    </slot>
                                </div>
                            </template>
                        </pv-grid-column>
                    </cosmos-grid>
                </div>
            </div>
        </template>
    </cosmos-grid>
</template>
<style>
</style>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { FilterMatchMode } from 'primevue/api';
    import { required } from '@vuelidate/validators';
    const moduleName = 'companyalert';
    import scopeForm from '@/components/settings/company/companyalertscope-form.vue';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate({ $scope: false })  }),
        props: {
            formData: { type: Object },
        },
        components: {
            'company-alertscope': scopeForm
        },
        data() {
            return {
                expandedRows: [],
                editingRows: [],
                originalRows: null,
                filename: 'export',
                filter: {},
                filters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                },
                searchFilter: ['code', 'name'],
                forDelete: null,
                selectedItems: [],
                activity: { loading: true, processing:false },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false,
                    scope: false,
                    assignCountry:false
                },
                forDeleteAlert: null,
                forDeleteItem: null,
                deleteDisplay: ["alertTypeText", "languageText","subject"],
                itemDialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false,
                    preview: false
                },
                parentSource: null,
                source: {
                    total: 0,
                    data: []
                },
                languageSource: {
                    parent: [],
                    variant: []
                },
                scopeSource: [],
                forceRefresh: {
                    parent: false,
                    sub: false
                },
                keywordSource: [],
                tempModel:null
            }
        },
        watch: {
            v$: {
                handler() { this.modelState = this.v$ }, deep: true
            },
            'model.parentLanguageID': {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.parentLanguageID) {
                            this.onManageVariant().then(() => {
                                if (this?.model?.id === 0)
                                    this.model.languageID = this.languageSource.variant[0]?.id;
                            });
                        }
                    });
                }, deep: true
            },
            'model.languageID': {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.languageID) {
                            this.$nextTick(() => {
                                this.model.body = this.model?.body?.replace("dir=\"rtl\"", "");
                            });
                        }
                    });
                }, deep: true
            }
        },
        validations() {
            let validator = {
            };

            validator = {
                model: {
                    body: { required, $autoDirty: true },
                    subject: { $autoDirty: true },
                    senderName: { $autoDirty: true },
                    senderEmail: { $autoDirty: true }
                }
            }
            return validator;
            //return {

            //    model: {
            //        /*   code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },*/
            //        //code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
            //        //name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
            //        //weight: { required, decimal, between: between(0.001, 9999999999.999), $autoDirty: true },
            //        //consignmentValue: { required, decimal, between: between(0.01, 9999999999.99), $autoDirty: true },
            //        //length: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
            //        //width: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
            //        //height: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true }
            //    }
            //    //,
            //    //itemModel: {
            //    //    code: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
            //    //    name: { required, minLength: minLength(1), maxLength: maxLength(50), $autoDirty: true },
            //    //    weight: { required, decimal, between: between(0.001, 9999999999.999), $autoDirty: true },
            //    //    consignmentValue: { required, decimal, between: between(0.01, 9999999999.99), $autoDirty: true },
            //    //    length: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
            //    //    width: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true },
            //    //    height: { decimal, between: between(0.00, 9999999999.99), $autoDirty: true }
            //    //}
            //}
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            },
            showAdd() {
                let exludeIDs = [];
                exludeIDs = (this.parentSource?.data ?? []).map(x => x.alertType);
                exludeIDs.push(0);
                return this.$vm.$enums['alerttype'].filter(x => exludeIDs.indexOf(x.id) === -1)?.length > 0 ;
            },
            alertTypeDS() {
                let exludeIDs = [];
                if ((this.model?.id ?? 0) === 0 && this.parentAlert === null) {
                    exludeIDs = (this.parentSource?.data ?? []).map(x => x.alertType);
                }
                exludeIDs.push(0);
                return this.$vm.$enums['alerttype'].filter(x => exludeIDs.indexOf(x.id) === -1);
            },
            fromEmail() {
                return this.model?.senderName + ' <' + this.model?.senderEmail + '>';
            },
            headerText() {
                return !this.dialog.assignCountry ? this.$vm.$enums['alerttype'].filter((x) => x.id == this.model?.alertType)[0]?.text.toUpperCase() + " COUNTRY SCOPE CONFIGURATION" : "MANAGE COUNTRY SCOPE";
            },
            parentAlert() {
                return this.model.parentID > 0 ? this.source.data.filter(x => x.id === this.model.parentID || x.temporaryID === this.model.parentID)[0] : null;
            },
            isRTLSupport() {
                let parentSourceLanguage = (this.$vm?.$languages ?? []).filter(x => x.id === (this?.model?.languageID ?? 0))[0];
                let variantLanguage = (this.$vm?.$languages ?? []).filter(x => x.id === (this?.model?.languageID ?? 0))[0];
                let parentLanguage = (this.$vm?.$languages ?? []).filter(x => x.id === (variantLanguage?.parentID ?? 0));
                return parentLanguage?.rtlSupport ?? variantLanguage?.rtlSupport ?? parentSourceLanguage?.rtlSupport ?? false;
                
            },
            modelInit() {
                return {
                    id: 0,
                    temporaryID: 0,
                    alertType: 100,
                    parentLanguageID: null,
                    parentID: null,
                    languageID: 1,
                    countryID: null,
                    senderName: null,
                    senderEmail: null,
                    subject: null,
                    body: null,
                    isActive: false,
                    includeAttachment: false,
                    customerID: null,
                    code: null,
                    name: null,
                    mask: null
                };
            },
            alertRecord() { return this.$store.getters["companyalert/record"]; },
            alertErrors() { return this.$store.getters["companyalert/errors"]; }
            
        },
        
        methods: {
            async update(modulename, model) { return await this.$store.dispatch(modulename + "/modifyRecord", model); },
            async insert(modulename, model) { return await this.$store.dispatch(modulename + "/addRecord", model); },
            async delete(modulename, mask) { return await this.$store.dispatch(modulename + "/deleteRecordByMask", mask); },
            async getEntity(modulename, mask) { return await this.$store.dispatch(modulename + "/getRecord", mask); },
            body_onChange(e) {
                if (e.value && e.text) {
                    setTimeout(() => { this.model.body = e.value ?? this.model.body; }, 500);
                }
                
            },
            onSyncSource(data) {
                this.parentSource = data;
                this.getAlerts();
            },
            async getAlerts() {
                if (this.$props.formData?.id > 0 && (this.source?.data?.length ?? 0) === 0) {
                    await this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/` + moduleName + `/customer/id/${this.$props.formData?.id}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.source.data = response.data.result;
                            this.source.total = response.data.result.length;
                        })
                        .catch((ex) => { this.errors = ex.data; });
                }  
            },
            onRowExpand() {
            },
            onRowCollapse() {
            },
            expandAll() {
                this.expandedRows = this.source.data.filter(p => p.id);
            },
            collapseAll() {
                this.expandedRows = null;
            },
            onRowEditInit(event) {
                this.originalRows[event.index] = { ...this.source.data[event.index] };
            },
            onRowEditCancel(event) {
                this.source.data[event.index] = this.originalRows[event.index];
            },
            onExport() {

            },
            rowClick(e) {
                if (this.isDisabled)
                    e.preventDefault();
                this.onAlertEdit(e.data);
            },
            onGetAlertCount(e) {
                console.log(e);
                return 0;
            },
            onAlertCreate() {
                this.model = {
                    id: 0,
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    alertType: this.alertTypeDS[0]?.id,
                    parentLanguageID: null,
                    parentID: null,
                    languageID: 1,
                    countryID: null,
                    senderName: this.$props.formData.altName ?? this.$props.formData.name,
                    senderEmail: null,
                    subject: null,
                    body: null,
                    isActive: false,
                    includeAttachment: false,
                    customerID: this.$props.formData.id,
                    code: ('TEMP' + Math.random().toString().replace(".", "")).slice(0, 20),
                    name: ('TEMP' + Math.random().toString().replace(".", "")).slice(0, 20),
                    mask: null

                };
                this.onManageLanguage(this.model);
                this.onManageVariant().then(() => {
                    if (this?.model?.id === 0)
                        this.model.languageID = this.languageSource.variant[0]?.id;
                });
                this.$nextTick(() => {
                    this.model.alertType = this.alertTypeDS[0]?.id;
                });
                this.onKeywords(this.model);
                this.dialog.delete = true;
                this.dialog.add = true;
            },
            onAlertEdit(data) {
                this.model = this.$filters.init(data);
                let _language = (this.$vm?.$languages ?? []).filter(x => x.id === this.model.languageID)[0];
                if ((this.model.parentID ?? 0) > 0)
                    this.model.parentLanguageID = (_language?.parentID ?? 0) > 0 ? _language?.parentID : _language.id;
                this.onManageLanguage(this.model);
                this.onManageVariant();
                this.onKeywords(this.model);
                this.dialog.delete = true;
                this.dialog.edit = true;
            },
            onAlertDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
                this.forDeleteAlert = this.source.data.filter(x => this.model.mask != null ? x.id === this.model.id : x.temporaryID === this.model.temporaryID)[0];
                if (this.forDeleteAlert) {
                    this.forDeleteAlert.alertTypeText = (this.$vm?.$enums['alerttype'] ?? []).filter(x => x.id === this.forDeleteAlert.alertType)[0]?.text;
                    this.forDeleteAlert.languageText = (this.$vm?.$languages ?? []).filter(x => x.id === this.forDeleteAlert.languageID)[0]?.name;
                }
            },
            onManageAlert(e) {
                this.activity.processing = true;
                e.senderEmail = (e.senderEmail?.length ?? 0) === 0 ? "alert@no-reply.com" : e.senderEmail;
                e.subject = (e.subject?.length ?? 0) === 0 ? "[{{COMPANYNAME}}] " + this.$vm.$enums['alerttype'].filter((x) => x.id == this.model?.alertType)[0]?.text +" Alert (Order # {{ORDERREFERENCE}})" : e.subject;
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                if (index === -1) {
                    this.source.data.push(this.$filters.init(e));
                    if (this.$route.params.mask ?? this.formData.mask) {
                        this.insert("companyalert", this.model).then(() => {
                            if (this.alertErrors) {
                                this.source.data.splice(index, 1);
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.alertErrors[0].message, life: 3000 });
                                
                            }
                            else {
                                index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                                this.getEntity("companyalert", this.alertRecord.mask).then(() => {
                                    this.source.data[index] = this.$filters.init(this.alertRecord);
                                    this.source.total = this.source.data.length;
                                    if (e.parentID > 0) {
                                        this.forceRefresh.sub = true;
                                    }
                                    else {
                                        this.forceRefresh.parent = true;
                                    }
                                });
                                this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                            }
                            this.activity.processing = false;
                        }).catch((err) => {
                            this.source.data.splice(index, 1);
                            this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                            this.activity.processing = false;
                        });
                    }
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        this.source.data[index] = this.$filters.init(e);
                        if (location.mask !== null && location.mask !== "") {
                            this.update("companyalert", this.model).then(() => {
                                if (this.alertErrors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.alertErrors[0].message, life: 3000 });

                                if (e.parentID > 0) {
                                    this.forceRefresh.sub = true;
                                }
                                else {
                                    this.forceRefresh.parent = true;
                                }
                                this.activity.processing = false;
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                                this.activity.processing = false;
                            });
                        }
                    }
                    else {
                        this.source.data.splice(index, 1);
                        if (location.mask !== null && location.mask !== "") {
                            this.delete("companyalert", location.mask).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: this.errors[0].message, life: 3000 });

                                if (e.parentID > 0) {
                                    this.forceRefresh.sub = true;
                                }
                                else {
                                    this.forceRefresh.parent = true;
                                }
                                this.activity.processing = false;
                               
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: err.xhr, life: 3000 });
                                this.activity.processing = false;
                            });
                        }
                    }
                }
                this.source.total = this.source.data.length;
                this.model = this.modelInit;
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
                this.forceRefresh.parent = false;
                this.forceRefresh.sub = false;
                this.$nextTick(() => {
                    this.getAlerts();
                });
            },
            onAlertPreview(e) {
                if (e.id > 0) {
                   this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/companyalert/${e.mask}/preview`.replaceAll("//", "/"))
                        .then((response) => {
                            this.model = response.data.result;
                            this.dialog.preview = true;
                        })
                }
            },
            onSendAlert(e) {
                if (e.id > 0) {
                    this.$axios
                        .post(this.$config.config.endpoint.api + `/services/settings/companyalert/${e.mask}/send`.replaceAll("//", "/"), e)
                        .then(() => {
                            this.dialog.preview = false;
                            this.model = this.modelInit;
                            this.$toast.add({ severity: 'success', summary: 'Alert Sent!', detail: 'The alert was successfully sent.', life: 3000 });
                        }).catch(() => {
                            this.$toast.add({ severity: 'error', summary: 'Alert Failed!', detail: 'The alert sent failed.', life: 3000 });
                        });

                }
            },
            onKeywords(e) {
                if ((e?.mask ?? null) === null && e.parentID === null) {
                    e.body = "TEST";
                    e.subject = "TEST";
                }
                this.$axios
                    .post(this.$config.config.endpoint.api + `/services/settings/companyalert/` + (e?.mask ?? 'X00e9839c143ca58894f47d3419be06a') + `/keyword`.replaceAll("//", "/"), e)
                    .then((response) => {

                        this.keywordSource = response.data.result;
                    }).catch(() => {

                    });

                if ((e?.mask ?? null) === null && e.parentID === null) {
                    e.body = null;
                    e.subject = null;
                }
            },
            onAddLanguage(parentAlert) {
                this.model = {
                    id: 0,
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    alertType: parentAlert.alertType,
                    parentID: parentAlert.temporaryID > 0 ? parentAlert.temporaryID : parentAlert.id,
                    parentLanguageID: parentAlert.languageID,
                    languageID: 1,
                    countryID: null,
                    senderName: parentAlert.senderName,
                    senderEmail: null,
                    subject: parentAlert.subject,
                    body: parentAlert.body,
                    isActive: false,
                    includeAttachment: false,
                    customerID: this.$props.formData.id,
                    code: ('TEMP' + Math.random().toString().replace(".", "")).slice(0, 20),
                    name: ('TEMP' + Math.random().toString().replace(".", "")).slice(0, 20),
                    mask: null
                };
                this.onManageLanguage(this.model).then(() => {
                    if (this.languageSource.parent.findIndex(x => x.id === this.model.parentLanguageID) === -1)
                        this.model.parentLanguageID = this.languageSource.parent[0]?.id;
                });
                this.onManageVariant();
                this.onKeywords(this.model);
                this.dialog.delete = true;
                this.dialog.add = true;
            },
            async onManageLanguage(_model) {
                let _sourceAlert = (this.source?.data ?? []).filter(x => x.alertType === _model.alertType);
                let _laguange = this.$vm?.$languages ?? [];
                let parentSource = _laguange.filter(x => (x?.parentID ?? 0) === 0 || x.parentID === undefined || x.parentID === null);
                let parentIDs = parentSource.map(x => x.id);
                let exludeParentIDs = _sourceAlert.filter(x => parentIDs.indexOf(x.languageID) !== -1).map(x => x.languageID);
                if (_model?.id > 0) {
                    let languageIndex = exludeParentIDs.findIndex(x => x === (_model.parentLanguageID ?? _model.languageID));
                    if (languageIndex !== -1)
                        exludeParentIDs.splice(languageIndex, 1);
                }
                
                (exludeParentIDs ?? []).forEach(language => {
                    let _languageVariant = _laguange.filter(p => p.parentID == language).map(p => p.id);
                    let exludevariantIDs = _sourceAlert.filter(x => _languageVariant.indexOf(x.languageID) !== -1).map(x => x.languageID);
                    
                    if ((_languageVariant.filter(variantID => exludevariantIDs.indexOf(variantID) === -1)?.length ?? 0) !== 0)
                        exludeParentIDs.splice(exludeParentIDs.findIndex(f => f === language), 1);
                })
                parentSource = parentSource.filter(x => exludeParentIDs.indexOf(x.id) === -1);
                this.languageSource.parent = parentSource;
            },
            async onManageVariant() {
                let _sourceAlert = (this.source?.data ?? []).filter(x => x.alertType === this?.model?.alertType);
                let _laguange = this.$vm?.$languages ?? [];
                if ((this?.model?.parentLanguageID ?? 0) > 0) {
                    let parentSource = _laguange.filter(x => x.id === (this?.model?.parentLanguageID ?? 0))[0];
                    let _languageVariant = _laguange.filter(p => p.parentID == parentSource.id);
                    let _languageVariantIDS = _languageVariant.map(p => p.id);
                    let exludevariantIDs = _sourceAlert.filter(x => _languageVariantIDS.indexOf(x.languageID) !== -1).map(x => x.languageID);
                    let excludeIndex = exludevariantIDs.findIndex(f => f === this.model.languageID);
                    if (excludeIndex !== -1)
                        exludevariantIDs.splice(exludevariantIDs.findIndex(f => f === this.model.languageID), 1);

                    let variangeSource = [];

                    if ((this.parentAlert?.languageID ?? 0) !== (this.model?.parentLanguageID ?? 0)) {
                        variangeSource.push({
                            id: parentSource.id,
                            id2: parentSource.id2,
                            mask: parentSource.mask,
                            code: parentSource.code,
                            name: "Standard",
                            parentID: parentSource?.parentID ?? null,
                            rtlSupport: parentSource.rtlSupport,
                            nonAlphabet: parentSource.nonAlphabet,
                        });
                    }
                    if (variangeSource?.length === 0) {
                        variangeSource = _languageVariant.filter(variant => exludevariantIDs.indexOf(variant.id) === -1);
                    }
                    else {
                        _languageVariant.filter(variant => exludevariantIDs.indexOf(variant.id) === -1).forEach((val) => {
                            variangeSource.push(val);
                        });
                    }
                    

                    this.languageSource.variant = variangeSource;
                }
                else {
                    this.languageSource.variant  = this.languageSource.parent
                }
            },
            onManageScopeCountry(e,assignCountry) {
                this.model = this.$filters.init(e);
                let _language = (this.$vm?.$languages ?? []).filter(x => x.id === this.model.languageID)[0];
                this.model.parentLanguageID = (_language?.parentID ?? 0) > 0 ? _language?.parentID : _language.id;
                this.dialog.scope = true;
                this.dialog.assignCountry = assignCountry;
            },
            async onSetStatus(event, source) {
                this.$nextTick(() => {
                    if (event) {
                        if (event.target != null) {
                            this.update("companyalert", source);
                        }
                    }
                });   
            },
            async getAlertScope() {
                if (((this.model?.parentID ?? 0 > 0) ? this.model?.parentID : this.model?.id) > 0) {
                    await this.$axios
                        .get(this.$config.config.endpoint.api + `/services/settings/companyalertscope/alert/id/${((this.model?.parentID ?? 0 > 0) ? this.model?.parentID : this.model?.id)}`.replaceAll("//", "/"))
                        .then((response) => {
                            this.scopeSource = response.data.result;
                        })
                        //.catch((ex) => {
                }
            },
            onSyncScope(event) {
                this.scopeSource = event ?? [];
            }
           
        },
        async created() {
            this.$nextTick(() => {
                this.getAlerts();
            });
        },
        async mounted() {
            this.$nextTick(() => {
                this.getAlerts();
            });
        }

    }
</script>

<style>
    div.card.panelAlert table { width: 100%; }
    div.card.panelAlert table th, .is-popup-form table td { padding: 5px 10px; vertical-align: top; }
    div.card.panelAlert tbody > tr:nth-child(even) { background: whitesmoke; }
    div.card.panelAlert thead th { font-size: 12px; border-bottom: 1px solid lightgray; }
    div.card.panelAlert tbody > tr:nth-child(odd) { background: #FFF; }
    div.image-container > img {
        width: 75px !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
    }
    .image-center {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 50% !important;
    }
</style>