<template>
    <pv-card>
        <template v-slot:title>
            Order
        </template>
        <template v-slot:subtitle>
            Please provide your package and item details.
        </template>
        <template v-slot:content>
            <orderpackage-form :formData="model" :recordformData="this.$props.formData" @is-sync="onSync($event)"></orderpackage-form>
        </template>
        <template v-slot:footer>
            <div class="p-grid p-nogutter p-justify-between">
                <pv-button label="Previous" @click="prevPage()" icon="pi pi-angle-left" />
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid" />
            </div>
        </template>
    </pv-card>
</template>
<script>
    //import validator from '@/assets/_js/cosmos-validators.js';
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import orderpackageForm from '@/components/transaction/tms/consignment/orderpackage-form.vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required, maxLength, minLength, helpers } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);

    export default {
        name: 'OrderPackageTemplate',
        mixins: [mixins.ORDERPACKAGE.STEPPER],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'orderpackage-form': orderpackageForm
        },
        data() {
            return {
                mainModel: null
            }
        },
        computed: {
            isValid() {
                return !this.v$.$invalid;
            },
            //isValid() {
            //    if ((this.model?.isHazardous) && (this.model?.autoNumber || (this.model?.customerReference?.length ?? 0) > 0) && ((this.model?.packages?.length ?? 0) > 0)) {
            //        return ((this.model?.dG_EmergencyContact?.length ?? 0) > 0) && ((this.model?.dG_EmergencyTelephone?.length ?? 0) > 0)
            //    }
            //    return (this.model?.autoNumber || (this.model?.customerReference?.length ?? 0) > 0) && ((this.model?.packages?.length ?? 0) > 0);
            //},
        },
        validations() {
            let validator = {
                model: {
                    autoNumber: { $autoDirty: true },
                    customerReference: { $autoDirty: true },
                    customerReference2: { $autoDirty: true },
                    customerReference3: { $autoDirty: true },
                    customerReference4: { $autoDirty: true },
                    customerReference5: { $autoDirty: true },
                    customerReference6: { $autoDirty: true },
                    customerReference7: { $autoDirty: true },
                    deliveryInstruction: { $autoDirty: true },
                    safePlace: { $autoDirty: true },
                    packageType: { $autoDirty: true },
                    currencyID: { $autoDirty: true },
                    weightUnit: { $autoDirty: true },
                    dimensionUnit: { $autoDirty: true },
                    notification: { $autoDirty: true },
                    isReturnable: { $autoDirty: true },
                    isHazardous: { $autoDirty: true },
                    dG_EmergencyContact: { $autoDirty: true },
                    dG_EmergencyTelephone: { $autoDirty: true },
                    packages: { required, $autoDirty: true }
                }
            }

            if (((this.model.customerReference?.length ?? 0) > 0))
                validator.model.customerReference = { maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | - `, regex('^[a-zA-Z0-9#_./\\|-]{0,20}$')) };
            if (!(this.model?.autoNumber ?? false))
                validator.model.customerReference = { required, maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | - `, regex('^[a-zA-Z0-9#_./\\|-]{1,20}$')) };
            if (this.model?.isHazardous ?? false) {
                validator.model.dG_EmergencyContact = { required, maxLength: maxLength(35), $autoDirty: true };
                validator.model.dG_EmergencyTelephone = { required, maxLength: maxLength(35), $autoDirty: true, regex: helpers.withMessage(`Invalid Format`, regex('^[a-zA-Z0-9]{1,25}$')) };
                if ((this.model.dG_EmergencyTelephone?.length ?? 0) > 0)
                    validator.model.dG_EmergencyTelephone = { required, maxLength: maxLength(25), minLength: minLength(7), $autoDirty: true, regex: helpers.withMessage(`Invalid Telephone format. It must be a numeric string with at least one non-zero character and a length between 7 and 25 characters.`, regex('^(?!0+$)[0-9]{7,25}$')) }
            }

            return validator;
        },
        methods: {
            nextPage() {
                if (this.isValid) {
                    this.$nextTick(() => {
                        this.$emit('next-page', { formData: this.model, pageIndex: 2 });
                    })
                } 
            },
            prevPage() {
                this.$emit('prev-page', { formData: this.model, pageIndex: 2 });
            },
          
        }
        
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>