<template>
    <print-form :formData="model" :showDialog="dialog.show" :documentType="dialog.type" @is-confirm="onConfirm($event)" @is-cancel="onCancel($event)"></print-form>
    <send-form :formData="dialog.sendLabel.model" :showDialog="dialog.sendLabel.show" :documentType="dialog.type" @is-confirm="onConfirmSend($event)" @is-cancel="onCancelSend($event)"></send-form>

    <is-dialog :header="`DEALLOCATE ${model?.code}`" :visible="dialog.deallocate.show" @is-confirm="onDeallocateConfirm(model)" @is-cancel="dialog.deallocate.show = false; dialog.deallocate.reason = null;" :confirmLoading="dialog.deallocate.processing" :confirmDisabled="isInvalidDeallocate || dialog.deallocate.processing" :cancelDisabled="dialog.deallocate.processing" :breakpoints="{'360px': '75vw', '340px': '100vw'}" :style="{width: '25vw'}">
        <div class="confirmation-content p-d-flex">
            <form-input id="reason" label="Reason" :v$="v$" :max-length="255" vBase="deallocateModel" vCode="reason" :required="true" :value="dialog.deallocate.reason" placeholder="Please provide your reason" type="textarea" rows="5" @is-sync="deallocateModel.reason = dialog.deallocate.reason = $event.value" />
        </div>
    </is-dialog>
    <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:-1rem;">
        <pv-accordion :activeIndex="[0]" :multiple="true" id="ConsignmentAccordion">
            <pv-accordion-tab>
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa fa-info-circle"></i></div>
                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="SUMMARY DETAILS">
                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`SUMMARY: ${$vm.$enums['consignmentstatustype'].filter(x => x.id === model?.status)[0]?.text?.toUpperCase()}  CONSIGNMENT ${model?.code?.toUpperCase()} (${model?.customerReference?.toUpperCase()})`"></span></div>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -0.75rem; margin-bottom: -2rem;">
                    <div class="p-grid p-col-12">
                        <div class="p-col-12 p-md-4">
                            <h4>RECIPIENT DETAIL<i :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.code.toLowerCase()}`" style="margin-top: -0.15rem"></i> </h4>
                            <div class="card" :style="`background: var(--surface-a); min-height: 21rem; height: calc(100% * 1.75rem);`">
                                <form-contact :disabled="true" id="Delivery" :value="deliveryContact" :useRegistrationNumber="true" disabledView="perline" />
                                <form-address id="DeliveryAddress" :value="deliveryAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                            </div>
                            <h4>SENDER DETAIL<i :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.code.toLowerCase()}`" style="margin-top: -0.15rem"></i> </h4>
                            <div class="card" :style="`background: var(--surface-a); min-height: 21rem; height: calc(100% * 1.75rem); ` ">
                                <form-contact :disabled="true" id="Collection" :value="collectionContact" :useRegistrationNumber="true" disabledView="perline" />
                                <form-address id="CollectionAddress" :value="collectionAddress" :useCompany="true" :disabled="true" disabledView="perline" />
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <h4>ORDER INFO</h4>
                            <div class="card" :style="`background:var(--surface-a); min-height: 46rem; `">
                                <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <h4 style="margin: -0.25rem -0.5rem;">
                                                    {{model?.customerName}}
                                                </h4>
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Trade Type</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.tradeTypeText} ${model?.thirdParty ? '(Third-Party)' : ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Transaction ID</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;"> <a :href="`/track/${model?.code}`" target="_self" style="font-weight: bold;">{{model?.code}}</a></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 1</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 2</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference2 ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 3</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference3 ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 4</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference4 ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 5</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference5 ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 6</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference6 ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 7</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.customerReference7 ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Instruction</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.deliveryInstruction ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Safe Place</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.safePlace ?? ''}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Package Type</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['serviceproviderproductpackagingtype'].filter((x) => x.id == model?.packageType)[0]?.text?.toUpperCase()}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Currency</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$currencies?.filter((x) => x.id == model?.currencyID)[0]?.code?.toUpperCase()}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Weight Unit</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['weightunit'].filter((x) => x.id == model?.weightUnit)[0]?.text?.toUpperCase()}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Dimension Unit</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['lengthunit'].filter((x) => x.id == model?.dimensionUnit)[0]?.text?.toUpperCase()}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Notification</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.notification > 0 ? ( model?.notification == 1 ?  'SMS' :  model?.notification == 2 ?  'EMAIL' : 'SMS EMAIL' ) : ''}`"></div></td>
                                        </tr>
                                        <tr v-if="(model?.isHazardous ?? false)">
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <h4 style="margin: -0.25rem -0.5rem;">
                                                    HAZMAT CONTACT
                                                </h4>
                                            </th>
                                        </tr>
                                        <tr v-if="(model?.isHazardous ?? false)" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Name</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.dG_EmergencyContact ?? ''}`"></div></td>
                                        </tr>
                                        <tr v-if="(model?.isHazardous ?? false)" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Telephone</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.dG_EmergencyTelephone ?? ''}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic">
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <h4 style="margin: -0.25rem -0.5rem">
                                                    INTERNATIONAL FORMS
                                                </h4>
                                            </th>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Incoterm</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['incotermtype'].filter((x) => x.id == model?.incoterm)[0]?.text}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Customs</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['customscategorytype'].filter((x) => x.id == model?.customsCategoryType)[0]?.text}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Export Reason</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['exportreasontype'].filter((x) => x.id == model?.exportReason)[0]?.text}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">CPC Number</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.cpcNumber ?? ''}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Content</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.packageContent ?? ''}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Paperless</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.isPaperlessTrade ? 'YES' : 'NO'}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic">
                                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                <h4 style="margin: -0.25rem -0.5rem">
                                                    {{$vm.$enums['invoicetype'].filter((x) => x.id == model?.invoiceType)[0]?.text.toUpperCase()}}
                                                </h4>
                                            </th>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Invoice Number</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.invoiceNumber ?? ''}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Invoice Date</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatDateUTCFormat(model?.invoiceDate,'DD/MMM/YYYY')}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Invoice Note</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.invoiceNote ?? ''}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Shipping Cost</th>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatCurrency(model?.shippingCost ?? 0.00,$vm.$currencies?.filter((x) => x.id == model?.currencyID)[0]?.code?.toUpperCase(),$vm.$countries?.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.code,2)}`"></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <h4>ALLOCATION INFO</h4>
                            <div class="card" :style="`background:var(--surface-a); min-height: 46rem; `">
                                <table style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem; margin-bottom: -1rem;" cellspacing="0">
                                    <tr>
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">COLLECTION INFO</h4></th>
                                    </tr>
                                    <!--<tr style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Type</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['pickuptype'].filter((x) => x.id == model?.pickupType)[0]?.text}`"></div></td>
                                    </tr>-->
                                    <tr style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Collection Date</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatDateUTCFormat(model?.request_CollectionDate,'DD/MMM/YYYY')}`"></div></td>
                                    </tr>
                                    <tr style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Ready Time</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatDateUTCFormat(model?.request_ReadyTime, 'HH:mm')}`"></div></td>
                                    </tr>
                                    <tr style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Closing Time</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatDateUTCFormat(model?.request_ClosingTime, 'HH:mm')}`"></div></td>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">SHIPPING INFO</h4></th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">
                                            <div class="p-d-flex p-col-12">
                                                <img style="width: 30%; max-height:4rem; object-fit: contain; object-position: center;" :src="`/_assets/${(model?.serviceProviderCarrierID != null ? `_carrier/${$vm.$carriers.filter((x) => x.id == model?.serviceProviderCarrierID)[0]?.mask}` : `_integration/${$vm.$providers.filter((x) => x.id == model?.serviceProviderID)[0]?.mask}`)}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                                                <div class="p-col" style="text-align:right">
                                                    <h3 style="word-break: break-word; margin: -0.25rem -0.5rem"><a :href="`${$filters.formatString($vm.$providers.filter((x) => x.id == model?.serviceProviderID)[0]?.trackingURL, model?.primaryCarrierReference,formData.shipTo_PostalCode)}`" target="_blank" style="font-weight: bold;">{{ model?.primaryCarrierReference }}</a></h3>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                    <tr v-if="model?.requestServiceGroupID" style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service Group</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${shippingCarrier?.servicegroup?.text}`"></div></td>
                                    </tr>
                                    <tr style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${(model?.serviceProviderCarrierID != null ? `${$vm.$carriers.filter((x) => x.id == model?.serviceProviderCarrierID)[0]?.name} (${$vm.$providers.filter((x) => x.id == model?.serviceProviderID)[0]?.code})` : `${$vm.$providers.filter((x) => x.id == model?.serviceProviderID)[0]?.name}`)}`"></div></td>
                                    </tr>
                                    <tr style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${shippingCarrier?.product?.text}`"></div></td>
                                    </tr>
                                    <tr v-if="(shippingCarrier?.option?.text)" style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${shippingCarrier?.option?.text ?? ''}`"></div></td>
                                    </tr>
                                    <tr v-if="model?.sP_ParcelReleaseCode" style="border-bottom:thin; border:0.01rem;">
                                        <th style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.sP_ParcelReleaseCode}`"></div></td>
                                    </tr>
                                    <tr v-if="model?.sP_ParcelShopCode" style="border-bottom:thin; border:0.01rem;">
                                        <th style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Access Point Location</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${((model?.sP_ParcelShopCode ?? 'NOACCESSPOINTID') === 'NOACCESSPOINTID' ? '' : ('[' + model?.sP_ParcelShopCode + ']'))}${model?.sP_ParcelShopName} ${model?.sP_ParcelShopAddress}`"></div></td>
                                    </tr>
                                    <tr v-if="model?.collectionCarrierReference" style="border-bottom:thin; border:0.01rem;">
                                        <th style="white-space:nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Collection Reference</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.collectionCarrierReference}`"></div></td>
                                    </tr>
                                    <tr v-if="(model?.isReturnable ?? false)">
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">RETURN INFO</h4></th>
                                    </tr>
                                    <tr v-if="(model?.isReturnable ?? false)">
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">
                                            <div class="p-d-flex p-col-12">
                                                <img style="width: 30%; max-height:4rem; object-fit: contain; object-position: center;" :src="`/_assets/${(model?.serviceProviderReturnCarrierID != null ? `_carrier/${$vm.$carriers.filter((x) => x.id == model?.serviceProviderReturnCarrierID)[0]?.mask}` : `_integration/${$vm.$providers.filter((x) => x.id == model?.serviceProviderReturnID)[0]?.mask}`)}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                                                <div class="p-col" style="text-align:right">
                                                    <h3 style="word-break: break-word; margin: -0.25rem -0.5rem">{{ model?.returnReference }}</h3>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr v-if="(model?.isReturnable ?? false)" style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${(model?.serviceProviderReturnCarrierID != null ? `${$vm.$carriers.filter((x) => x.id == model?.serviceProviderReturnCarrierID)[0]?.name} (${$vm.$providers.filter((x) => x.id == model?.serviceProviderReturnID)[0]?.code})` : `${$vm.$providers.filter((x) => x.id == model?.serviceProviderReturnID)[0]?.name}`)}`"></div></td>
                                    </tr>
                                    <tr v-if="(model?.isReturnable ?? false)" style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${returnCarrier?.product?.text ?? ''}`"></div></td>
                                    </tr>
                                    <tr v-if="(model?.isReturnable ?? false) && returnCarrier?.option?.text" style="border-bottom:thin; border:0.01rem;">
                                        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${returnCarrier?.option?.text ?? ''}`"></div></td>
                                    </tr>
                                    <!--<tr v-if="(model?.isReturnable ?? false)" style="border-bottom:thin; border:0.01rem;">
        <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${''}`"></div></td>
    </tr>-->
                                    <tr v-if="(model?.isReturnable ?? false) && (model?.returnTo_Address1)" style="border-bottom:thin; border:0.01rem;">
                                        <th style="white-space:nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Alternate Return Address</th>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                        <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${''}`"></div></td>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">DOCUMENT</h4></th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-col" style="text-align: left; padding: 0.25rem 0.5rem;">
                                            <pv-button label="Shipping Label" icon="pi pi-print" @click="onPrint('label')" class="p-button" style="margin-right:5px; margin-bottom:5px;" />
                                            <pv-button v-if="documentTypes.findIndex(x => x === 200) !== -1" label="Return Label" icon="pi pi-print" @click="onPrint('return')" class="p-button" style="margin-right: 5px; margin-bottom: 5px;" />
                                            <pv-splitbutton v-if="(buttonItems?.length ?? 0) > 0" label="Document" icon="pi pi-print" :model="buttonItems" style=" margin-bottom: 5px;"></pv-splitbutton>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">ACTION</h4></th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-col" style="text-align: left; padding: 0.25rem 0.5rem;">
                                            <pv-button label="Send Labels" icon="pi pi-fw pi-send" class="p-button" @click="onSendLabel(model)" style="margin-right:5px;" />
                                            <pv-button label="Deallocate" icon="pi pi-ban" class="p-button" @click="{dialog.deallocate.show = true;}" style="margin-right:5px;" />
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">ACTIVITY</h4></th>
                                    </tr>
                                    <tr style="border-bottom:thin; border:0.01rem;">
                                        <td colspan="3" class="p-code-text" style="padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">
                                            <div style="font-size:1.1rem;font-family: inherit !important; text-align:justify;font-weight:bold; padding-top:0.5rem;" v-html="`${model?.lastScan_Activity ?? ''}`"></div>
                                            <div style="font-size:0.9rem;font-family: inherit !important; text-align:justify; padding-top:0.5rem;" v-html="`${model?.lastScan_Location  ?? ''}`"></div>
                                            <div style="font-size:0.9rem; font-family: inherit !important; text-align: justify; padding-top: 0.5rem;" v-html="`${$filters.formatDateUTCFormat(model?.lastScan_Date,'DD/MMM/YYYY HH:mm')}`"></div>

                                        </td>
                                    </tr>
                                    
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </pv-accordion-tab>
            <pv-accordion-tab>
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa fa-cubes"></i></div>
                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="PACKAGES / RETURNS INFO">
                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`PACKAGES / RETURNS INFO`"></span></div>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                    <pv-tab>
                        <pv-tabpanel header="Packages">
                            <package-grid :formData="formData" :parentID="model?.id" :currencyID="model?.currencyID" :weightUnit="model?.weightUnit" :dimensionUnit="model?.dimensionUnit" :isHazardous="model?.isHazardous" :countryID="model?.shipFrom_CountryID" :isDisabled="true"> </package-grid>
                        </pv-tabpanel>
                        <pv-tabpanel v-if="((model?.id ?? 0) > 0) && (model?.isReturnable ?? false)" header="Returns">
                            <returns-grid :parentID="model?.id"></returns-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </pv-accordion-tab>
            <pv-accordion-tab>
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i class="fa fa-history"></i></div>
                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="ACTIVITY THREAD / LOGS">
                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`ACTIVITY THREAD / LOGS`"></span></div>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                    <pv-tab>
                        <pv-tabpanel header="Threads">
                            <thread-grid :formData="model" :forceRebindTicker="forceRebindTicker.thread"></thread-grid>
                        </pv-tabpanel>
                        <pv-tabpanel header="Logs" v-if="user.userType <= 3">
                            <auditlog-grid :consignmentID="model?.id"></auditlog-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </pv-accordion-tab>
        </pv-accordion>
    </div>
</template>
<script>
    import consignmentpackage from '@/components/transaction/tms/consignment/record/package-formnew.vue';
    import consignmentthread from '@/components/transaction/tms/consignment/thread-grid.vue';
    import consignmentreturns from '@/components/transaction/tms/returns/returns-grid.vue';
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    import sendform from '@/components/transaction/tms/consignment/record/send-form.vue';
    import auditloggrid from "@/components/facility/auditlogs/auditlog-grid.vue";
    import { required, maxLength } from '@vuelidate/validators';
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'ViewTemplate',
        props: {
            formData: { type: Object }
        },
        components: {
            'package-grid': consignmentpackage,
            'thread-grid': consignmentthread,
            'returns-grid': consignmentreturns,
            'print-form': printform,
            'send-form': sendform,
            'auditlog-grid': auditloggrid
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: null,
                documentTypes: [],
                shippingInfo: {
                    product:null
                },
                returnInfo: {
                    product: null
                },
                deallocateModel: {
                    reason : null
                },
                dialog: {
                    show: false,
                    type: 'label',
                    deallocate: {
                        show: false,
                        processing: false,
                        reason: null
                    },
                    sendLabel: {
                        show: false,
                        model: null
                    }
                },
                forceRebindTicker: { thread: null, logs: null }
            }
        },
        validations() {
            let validator = {
                deallocateModel: {
                    reason: { $autoDirty: true },
                }
            }

            if (this.dialog.deallocate.show ?? false) {
                validator.deallocateModel.reason = { required, maxLength: maxLength(255), $autoDirty: true };
            }

            return validator;
        },
        watch: {
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            },
            'model.status': {
                handler() {
                    this.$nextTick(() => {
                        if ((this.model?.status ?? 0 > 100)) {
                            this.getConsignmentDocument();
                            this.getServiceProviderProduct();
                        }
                    });

                },
                deep: true
            },
        },
        computed: {
            sessionKeys() { return this.$store.getters["auth/session"]; },
            user() { return this.$store.getters["auth/currentUser"]; },
            cardHeight() {
                return 57;
            },
            isInvalidDeallocate() {
                return (this.deallocateModel.reason?.length ?? 0) === 0 || (this.deallocateModel.reason?.length ?? 0) > 255;
            },
            collectionContact() {
                return {
                    firstName: this.model?.shipFrom_FirstName,
                    lastName: this.model?.shipFrom_LastName,
                    email: this.model?.shipFrom_Email,
                    mobile: this.model?.shipFrom_Mobile,
                    telephone: this.model?.shipFrom_Telephone,
                    eori: this.model?.shipFrom_EORI,
                    ioss: this.model?.shipFrom_IOSSNumber,
                    ukims: this.model?.shipFrom_UKIMS,
                    vat: this.model?.shipFrom_TaxID,
                    idNumber: this.model?.shipFrom_IdentityNumber,
                    idType: this.model?.shipFrom_IdentityNumberType ?? 300
                };
            },
            collectionAddress() {
                return {
                    address1: this.model?.shipFrom_Address1,
                    address2: this.model?.shipFrom_Address2,
                    address3: this.model?.shipFrom_Address3,
                    city: this.model?.shipFrom_City,
                    state: this.model?.shipFrom_State,
                    postalCode: this.model?.shipFrom_PostalCode,
                    countryID: this.model?.shipFrom_CountryID,
                    companyName: this.model?.shipFrom_Company,
                    establishmentType: this.model?.shipFrom_EstablimentType
                };
            },
            deliveryContact() {
                return {
                    firstName: this.model?.shipTo_FirstName,
                    lastName: this.model?.shipTo_LastName,
                    email: this.model?.shipTo_Email,
                    mobile: this.model?.shipTo_Mobile,
                    telephone: this.model?.shipTo_Telephone,
                    eori: this.model?.shipTo_EORI,
                    ioss: this.model?.shipTo_IOSSNumber,
                    ukims: this.model?.shipTo_UKIMS,
                    vat: this.model?.shipTo_TaxID,
                    idNumber: this.model?.shipTo_IdentityNumber,
                    idType: this.model?.shipTo_IdentityNumberType ?? 300
                };
            },
            deliveryAddress() {
                return {
                    address1: this.model?.shipTo_Address1,
                    address2: this.model?.shipTo_Address2,
                    address3: this.model?.shipTo_Address3,
                    city: this.model?.shipTo_City,
                    state: this.model?.shipTo_State,
                    postalCode: this.model?.shipTo_PostalCode,
                    countryID: this.model?.shipTo_CountryID,
                    companyName: this.model?.shipTo_Company,
                    establishmentType: this.model?.shipTo_EstablimentType
                };
            },
            buttonItems() {
                let _buttonItems = [
                    {
                        label: 'Commercial Invoice',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "commercial";
                        }
                    },
                    {
                        label: 'Proforma Invoice',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "proforma";
                        }
                    },
                    {
                        label: 'CN22',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "cn22";
                        }
                    },
                    {
                        label: 'CN23',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "cn23";
                        }
                    },
                    {
                        label: 'Return Commercial Invoice',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "return-commercial";
                        }
                    },
                    {
                        label: 'Archived Label',
                        icon: 'pi pi-file',
                        command: () => {
                            this.dialog.show = true;
                            this.dialog.type = "archivedlabel";
                        }
                    },
                ];

                if (this.documentTypes.findIndex(x => x === 150) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Archived Label');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 300) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'CN22');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 400) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'CN23');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 500) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Commercial Invoice');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 600) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Proforma Invoice');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                if (this.documentTypes.findIndex(x => x === 550) === -1) {
                    let index = _buttonItems.findIndex(x => x.label === 'Return Commercial Invoice');
                    if (index !== -1) {
                        _buttonItems.splice(index, 1);
                    }
                }
                return _buttonItems;
            },
            apiDeallocate() {
                return {
                    Source: "WEB",
                    Request: {
                        TransactionID: this.$props.formData?.code,
                        Reason: this.dialog.deallocate.reason
                    }
                };
            },
            consignmentRecord() { return this.$store.getters["consignment/record"]; },
            OriginISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID)[0]?.code;
            },
            DestinationISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID)[0]?.code;
            },
            isDomestic() {
                return this.$filters.isDomestic(this.OriginISO2, this.model?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.model?.shipTo_PostalCode ?? '');
            },
            shippingCarrier() {
                return this.shippingInfo;
            },
            returnCarrier() {
                return this.returnInfo;
            }
        },
        methods: {
            async getEntityRecord(mask) { return await this.$store.dispatch("consignment/getRecord", mask); },
            async getByMask() {
                return await this.$axios.get(this.$config.config.endpoint.api + `/services/tms/consignment/` + this.model.mask + ``.replaceAll("//", "/"));
            },
            async deallocate() {
                return await this.$axios({
                    method: 'DELETE',
                    url: this.$config.config.endpoint.api + `/api/polaris/consignment/` + this.$props.formData?.code + `/deallocate`.replaceAll("//", "/"),
                    data: this.apiDeallocate
                });
            },
            //async print() {
            //    return await this.$store.dispatch("consignment/getURL", { url: '/services/tms/consignment/' + this.model.mask + '/printlabel' });
            //},
            async getConsignmentDocument() {
                if (this.model.mask) {
                    this.$axios.get(this.$config.config.endpoint.api + `/services/tms/consignment/` + this.model.mask + `/document`.replaceAll("//", "/"))
                        .then((response) => {
                            this.documentTypes = (response.data.result ?? []).map(x => x.documentType);
                        })
                        .catch((ex) => {
                            console.log(ex);
                        });
                }
                return [];
            },
            async getServiceProviderProduct() {
                if (this.model?.serviceProviderProductID > 0) {
                    this.$store.dispatch("providerproduct/getURL", { url: '/services/settings/serviceproviderproduct/id/' + this?.model?.serviceProviderProductID }).then((response) => {
                        this.shippingInfo.product = response.result;
                    }).catch(() => { });
                }
                if (this.model?.serviceProviderOptionID > 0) {
                    this.$store.dispatch("providerproduct/getURL", { url: '/services/settings/serviceproviderproduct/id/' + this?.model?.serviceProviderOptionID }).then((response) => {
                        this.shippingInfo.option = response.result;
                    }).catch(() => { });
                }
                if (this.model?.requestServiceGroupID > 0) {
                    this.$store.dispatch("servicegroup/getURL", { url: '/services/facility/servicegroup/id/' + this?.model?.requestServiceGroupID }).then((response) => {
                        this.shippingInfo.servicegroup = response.result;
                    }).catch(() => { });
                }
                if (this.model?.serviceProviderReturnProductID > 0) {
                    this.$store.dispatch("providerproduct/getURL", { url: '/services/settings/serviceproviderproduct/id/' + this?.model?.serviceProviderReturnProductID }).then((response) => {
                        this.returnInfo.product = response.result;
                    }).catch(() => { });
                }  
                if (this.model?.serviceProviderReturnOptionID > 0) {
                    this.$store.dispatch("providerproduct/getURL", { url: '/services/settings/serviceproviderproduct/id/' + this?.model?.serviceProviderReturnOptionID }).then((response) => {
                        this.returnInfo.option = response.result;
                    }).catch(() => { });
                }  
            },
            async getTracking() {
                if (this.model.status >= 200 && this.model.status < 3000) {
                    return await this.$axios.get(this.$config.config.endpoint.api + `/api/polaris/consignment/` + this.$props.formData?.code + `/track`.replaceAll("//", "/"));
                }
            },
            onPrint(type) {
                this.dialog.show = true;
                this.dialog.type = type ?? "label";
            },
            onConfirm() {
                //if (this.dialog.type === "label") {
                //    this.print();
                //}
                this.dialog.show = false;
                this.dialog.type = "label";

                if (this.model.status == 200) {
                    setTimeout(() => {
                        this.getByMask().then(response => {
                            this.model = response.data.result;
                            this.rebindTicker();
                        });
                    }, 2000);
                }
                else {
                    this.rebindTicker();
                }
            },
            onCancel() {
                this.dialog.show = false;
                this.dialog.type = "label";
            },
            onDeallocateConfirm() {
                let _self = this;
                this.$nextTick(() => {
                    this.dialog.deallocate.processing = true;
                });
                
                this.deallocate().then((deallocResponse) => {
                    if (deallocResponse.status === 200) {
                        try {
                            var warningErrors = "";
                            if (deallocResponse.data.Errors !== null && deallocResponse.data.Errors !== undefined) {
                                (deallocResponse.data.Errors ?? []).forEach((x) => {
                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }
                            if (deallocResponse.data.Faults !== null && deallocResponse.data.Faults !== undefined) {
                                (deallocResponse.data.Faults ?? []).forEach((x) => {
                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }
                            if (warningErrors.length > 0) {
                                _self.$toast.add({ severity: 'error', summary: 'Record Deallocate Failed!', detail: warningErrors, life: 10000 });
                            }
                            else if (deallocResponse.data.Status.indexOf("SUCCESS") != -1) {
                                _self.getEntityRecord(this.$props.formData?.mask).then(() => {
                                    _self.model = _self.consignmentRecord;
                                    _self.$toast.add({ severity: 'success', summary: 'Record Deallocate Success!', detail: 'The record was deallocated successfully.', life: 6000 });
                                    this.$nextTick(() => {
                                        this.dialog.deallocate.processing = false;
                                    });
                                    _self.$router.push({ name: 'consignment-edit', params: { mask: _self.consignmentRecord.mask, deallocated: true } });
                                    //setTimeout(() => _self.$emit("is-deallocate", _self.consignmentRecord), 100);
                                })
                            }
                        }
                        catch {
                            this.$nextTick(() => {
                                this.dialog.deallocate.processing = false;
                            });
                            this.$toast.add({ severity: 'error', summary: 'Record Deallocate Failed!', detail: deallocResponse.data.Status, life: 6000 });
                        }
                        //if (deallocResponse.data.Status !== "FAILED") {
                        //    _self.getEntityRecord(this.$props.formData?.mask).then(() => {
                        //        _self.model = _self.consignmentRecord;
                        //        _self.$toast.add({ severity: 'success', summary: 'Record Deallocate Success!', detail: 'The record was deallocated successfully.', life: 6000 });
                        //        setTimeout(() => _self.$emit("is-deallocate",_self.consignmentRecord), 100);
                        //    })
                        //}
                        //else {
                        //    this.$toast.add({ severity: 'error', summary: 'Record Deallocate Failed!', detail: deallocResponse.data.Errors[0].Description, life: 6000 });
                        //}
                    }
                }).catch((err) => {
                    this.$nextTick(() => {
                        this.dialog.deallocate.processing = false;
                    });
                    this.$toast.add({ severity: 'error', summary: 'Record Deallocate Failed!', detail: err.xhr, life: 6000 });
                }).finally(() => {
                    this.$nextTick(() => {
                        this.dialog.deallocate.processing = false;
                    });
                    this.dialog.deallocate.show = false;
                });

                
            },
            onSendLabel(e) {
                if (e.id > 0) {
                    this.$axios
                        .get(this.$config.config.endpoint.api + `/services/tms/consignment/` + e?.mask + `/send`.replaceAll("//", "/"))
                        .then((response) => {
                            this.dialog.sendLabel.model = response.data.result;
                            this.dialog.sendLabel.show = true;
                        })
                }
            },
            onConfirmSend() {
                this.dialog.sendLabel.show = false;

                if (this.model.status == 200) {
                    setTimeout(() => {
                        this.getByMask().then(response => {
                            this.model = response.data.result;
                            this.rebindTicker();
                        });
                    }, 2000);

                }
                else {
                    this.rebindTicker();
                }
            },
            onCancelSend() {
                this.dialog.sendLabel.show = false;
            },
            onConsignmentRebind() {
                if (this.model.status >= 200 && this.model.status < 3000) {
                    setTimeout(() => {
                        this.getByMask().then(response => {
                            this.model = response.data.result;
                        });
                    }, 2000);
                }
            },
            rebindTicker() {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.forceRebindTicker.thread = Math.random();
                    }, 500)
                    //setTimeout(() => {
                    //    this.forceRebindTicker.logs = Math.random();
                    //}, 1000)
                });
            },
        },
        mounted() {
            let _self = this;
            this.model = _self.$props.formData;
            this.getTracking().then(() => {
                this.onConsignmentRebind();
            });
        },
        created() { this.activeKeys = sessionStorage.getItem('activekeys'); }
    }
</script>

<style>

    #ConsignmentAccordion .p-accordion-header-link {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid var(--gray-200);
    }

    .p-picklist-header {
        padding: 0 10px !important;
    }
    .responsive-iframe {
        width: 100%;
        height: 450px;
    }
    .p-code-text * :not(.fa), .p-code-text * :not(.pi)
    {
        font-family: inherit !important;
    }
    .p-code-text .fa-duotone {
        font-family: "Font Awesome 6 Duotone" !important;
    }
    tr[style='border: 0.01rem;'] th:first-child {
        font-family: Roboto !important;
    }
    tr th > h4 {
        font-family: Roboto !important;
        font-weight: bold;
        font-size: 1.1rem !important;
    }
</style>
