<template>
    <is-dialog :header="`PRINT ${$filters.titleize(headerText).toUpperCase()} ${model?.code ?? ''}`" :visible="dialog.show" @is-confirm="onConfirm(model)" confirm="Print" confirmIcon="pi pi-print" @is-cancel="onCancel" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
        <div v-if="printer.source.length <= 2" class="p-d-flex p-jc-end" style="margin-bottom: 1rem; font-size: 0.8rem;"><a :href="`https://cdn.integro360.com/kbase/installer/wcpp/4.0.18/${wccpFile}?${Math.random()}`" target="_blank" style="margin-right: 0.25rem; " download><i class="fa fa-download fa-fw" style="margin-top: -1rem; margin-right: 0.25rem;"></i> Download Neodynamics WebClient Printer Installer</a></div>
        <div class="p-d-flex p-jc-between">
            <form-lookup id="SelectPrinter" :hideLabel="true" preLabel="Select Printer" sourceType="array" :source="printer.source" :value="printer.id" @is-sync="printer.id = $event?.value ?? 1; printer.name =$event.text" :sorted="false" :nullable="false" style="margin: -1rem -0.4rem -0.4rem -0.4rem; width: calc(100% - 1.8rem) "></form-lookup>
            <pv-button icon="fa fa-refresh fa-fw" title="Refresh Printer List" @click="onRefresh" style="padding: 0.25rem 0.25rem; margin-top: -0.5rem; height: 2.8rem;"></pv-button>
        </div>
        <div class="confirmation-content p-d-flex">
                <embed v-if="model?.code && (!(printer.source.length <= 2) || showContent)" id="documentPreview" class="responsive-iframe" :src="`${sourceURL}#toolbar=0&navpanes=0&scrollbar=1`" style="border: 1px solid lightgray;" />
                <div v-else class="p-d-flex p-grid p-col">
                    <div class="p-col-12" style="text-align:center;">
                        <pv-progress-spinner style="padding:3rem;">PLEASE WAIT</pv-progress-spinner>
                    </div>
                    <div class="p-col-12" style="text-align:center;">
                        <span class="p-code-text" style="text-align:center"><b>...Retrieving {{$filters.titleize(headerText)}}... Please Wait....</b></span>
                    </div>

                </div>
        </div> 
        <template #footer>
            <div class="p-col">
                <pv-splitbutton id="printSplitButton" v-if="(buttonItems?.length ?? 0) > 0" label="Print" icon="pi pi-print" @click="buttonItems[0]?.command()" :model="buttonItems" style="margin:0;"></pv-splitbutton>
                <pv-button v-else label="Print" icon="pi pi-print" @click="onConfirm(model)" class="p-button" autofocus />
                <pv-button label="Cancel" icon="pi pi-pw pi-times" @click="onCancel" class="p-button-outlined" />
            </div>

        </template>
    </is-dialog>
</template>
<script>
    const neodynamics = require('@/assets/lib/cosmos/_js/neodynamics.js');
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    
    export default {
        name: 'PrintFormTemplate',

        props: {
            formData: { type: Object },
            foreignkeyid: { type: Number, default: null },
            documentType: { type: String, default: 'label', validator: (value) => ['label', 'return', 'commercial', 'proforma', 'cn22', 'cn23','archivedlabel','returnarchivedlabel'].indexOf(value.toLowerCase()) !== -1 },
            showDialog: { type: Boolean, default: false },
            headerDisplay: { type: String, default: null },
            fileUrl: { type: String, default: null },
            module: { type: String, default: 'consignment' },
            area: { type: String, default: 'tms' },
            isBatchPrint: { type: Boolean, default: false },
            selectedItems: { type: Array }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                sessionID: null,
                printer: {
                    source: [],
                    id: 1,
                    name: 'Use Default Printer'
                },
                dialog: {
                    show  : false
                },
                model: {  
                },
                source: {
                    file: null,
                    documents: null
                },
                showContent: false
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                        if (this.$props.isBatchPrint) {
                            this.getBatchDocument();
                        }
                        this.getDocument();
                    });
                }, deep: true
            },
            'showDialog': {
                handler() {
                    this.$nextTick(() => {
                        this.dialog.show = this.$props.showDialog;
                    });
                }, deep: true
            },
            'dialog.show': {
                handler() {
                    this.$nextTick(() => {
                        if (this.dialog.show) {
                            setTimeout(() => {
                                if ((this.printer.source ?? []).length <= 2) {
                                    this.onRefresh();
                                }
                            },1000);
                            setTimeout(() => {
                                if ((this.printer.source ?? []).length <= 2) {
                                    this.onRefresh();
                                }
                            }, 10000);
                        }     
                    });
                }, deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },  
            headerText() {
                let fileType = (this.documentType !== 'label' ? (this.documentType.toUpperCase()) : '');
                fileType = (fileType.indexOf('commercial'.toUpperCase()) !== -1 || fileType.indexOf('proforma'.toUpperCase()) !== -1) ? ' INVOICE' : '';
                return (this.headerDisplay ?? (this.documentType + fileType)).toUpperCase().replace("COLLECTION", "").replace("ARCHIVEDLABEL","ARCHIVED LABEL");
            },
            sourceURL() {
                let fileType = (this.documentType !== 'label' ? '-' + (this.documentType.toUpperCase()) : '');
                fileType += (fileType.indexOf('commercial'.toUpperCase()) !== -1 || fileType.indexOf('proforma'.toUpperCase()) !== -1) ? 'INVOICE' : '';
                if ((this.$props.module ?? 'consignment') === "collection")
                    fileType = (fileType.indexOf('summary'.toUpperCase()) !== -1 || fileType.indexOf('details'.toUpperCase()) !== -1) ? ('-MANIFEST' + this.documentType.toUpperCase().replace("COLLECTION","")) : '';
                return this.fileUrl ?? (this.cdnUrl + '/' + this.tenantCode + '/' + (this.$props.module ?? 'consignment') + '/documents/' + this.model?.code + fileType + '.pdf?' + Math.random());
            },
            cdnUrl() {
                return this.$config.config.endpoint.cdn;
            },
            tenantCode() {
                return ((this.$config.common.$tenant() ?? []).filter((x) => x.id === this.model?.tenantID ?? 1)[0]?.code ?? "cosmos").toLowerCase();
            },
            fileDocuments() {
                return this.source.documents;
            },
            buttonItems() {
                
                let _buttonItems = [];
                if (this.documentTypeValue <= 250) {
                    let documents = (this.fileDocuments ?? []).filter(x => x.documentType === this.documentTypeValue);
                    if (documents) {
                        let zplFormat = ((this.$props.documentType === 'return') ? 'RETURN' : '') + '.zpl';
                        let eplFormat = ((this.$props.documentType === 'return') ? 'RETURN' : '') + '.epl';
                        let pdfFormat = ((this.$props.documentType === 'return') ? 'RETURN' : '') + '.pdf';
                        let a4Format = ((this.$props.documentType === 'return') ? 'RETURN' : '') + '.html';
                        if (documents.filter(x => x.fileName.indexOf(zplFormat) !== -1)[0]) {
                            _buttonItems.push({
                                label: 'ZPL',
                                icon: 'fas fa-file',
                                command: () => {
                                    this.dialog.show = false;
                                    this.$emit('is-confirm');
                                    if (this.allowedModule)
                                        this.printConfirm(documents.filter(x => x.fileName.indexOf(zplFormat) !== -1)[0].documentType);
                                    this.$print(this.sessionID, this.printer.name, documents.filter(x => x.fileName.indexOf(zplFormat) !== -1)[0].fileName, this.tenantCode, this.$props.module + '/documents');
                                }
                            });
                        }
                        if (documents.filter(x => x.fileName.indexOf(eplFormat) !== -1)[0]) {
                            _buttonItems.push({
                                label: 'EPL',
                                icon: 'fas fa-file',
                                command: () => {
                                    this.dialog.show = false;
                                    this.$emit('is-confirm');
                                    if (this.allowedModule)
                                        this.printConfirm(documents.filter(x => x.fileName.indexOf(eplFormat) !== -1)[0].documentType);
                                    this.$print(this.sessionID, this.printer.name, documents.filter(x => x.fileName.indexOf(eplFormat) !== -1)[0].fileName, this.tenantCode, this.$props.module + '/documents');
                                }
                            });
                        }
                        if (documents.filter(x => x.fileName.indexOf(pdfFormat) !== -1)[0]) {
                            _buttonItems.push({
                                label: 'PDF',
                                icon: 'fas fa-file-pdf',
                                command: () => {
                                    this.dialog.show = false;
                                    this.$emit('is-confirm');
                                    if (this.allowedModule)
                                        this.printConfirm(documents.filter(x => x.fileName.indexOf(pdfFormat) !== -1)[0].documentType);
                                    this.$print(this.sessionID, this.printer.name, documents.filter(x => x.fileName.indexOf(pdfFormat) !== -1)[0].fileName, this.tenantCode, this.$props.module + '/documents');
                                }
                            });
                        }
                        if (documents.filter(x => x.fileName.indexOf(a4Format) !== -1)[0]) {
                            _buttonItems.push({
                                label: 'A4',
                                icon: 'fas fa-page',
                                command: () => {
                                    this.dialog.show = false;
                                    this.$emit('is-confirm');
                                    if (this.allowedModule)
                                        this.printConfirm(documents.filter(x => x.fileName.indexOf(a4Format) !== -1)[0].documentType);
                                    this.$print(this.sessionID, this.printer.name, documents.filter(x => x.fileName.indexOf(a4Format) !== -1)[0].fileName, this.tenantCode, this.$props.module + '/documents');
                                }
                            });
                        }
                    }
                }
                else if ((this.documentTypeValue >= 700 && this.documentTypeValue <= 800)) {
                    for (const document of (this.fileDocuments ?? [])) {
                        _buttonItems.push({
                            label: document.documentType === 700 ? 'SUMMARY' : 'DETAILS',
                            icon: 'fas fa-file-pdf',
                            command: () => {
                                this.dialog.show = false;
                                this.$emit('is-confirm');
                                if (this.allowedModule)
                                    this.printConfirm(document.documentType);
                                this.$print(this.sessionID, this.printer.name, document.fileName, this.tenantCode, this.$props.module + '/documents');
                            }
                        });
                    }
                }
                
                return _buttonItems;
            },
            documentTypeValue() {
                switch (this.$props.documentType.toLowerCase()) {
                    case 'label':
                        return 100;
                    case 'archivedlabel':
                        return 150;
                    case 'return':
                        return 200;
                    case 'returnarchivedlabel':
                        return 250;
                    case 'cn22':
                        return 300;
                    case 'cn23':
                        return 400;
                    case 'commercial':
                        return 500;
                    case 'return-commercial':
                        return 550;
                    case 'proforma':
                        return 600;
                    case 'collectionsummary':
                        return 700;
                    case 'collectiondetails':
                        return 800;
                    default:
                        return 100;
                }
            },
            allowedModule() {
                let modules = [];
                modules.push("consignment")
                modules.push("collection")
                modules.push("invoice")
                return modules.filter(x => x === this.$props.module)[0] !== undefined;
            },
            wccpFile() {
                if (navigator.appVersion.indexOf("Win") != -1) return "wcpp-windows.exe";
                if (navigator.appVersion.indexOf("Mac") != -1) return "wcpp-macos.dmg";
                if (navigator.appVersion.indexOf("Linux") != -1) return "wcpp-linux.rpm";
                return null;
            }
        },
        methods: {
            async printConfirm(documentType) {
                return await this.$store.dispatch((this.$props.module ?? 'consignment') + "/getURL", { url: '/services/' + (this.$props.area ?? 'tms') + '/' + (this.$props.module ?? 'consignment') + '/' + this.model?.mask + '/printlabel?docType=' + (documentType ?? 100) + '&' + Math.random()});
            },
            async printConfirmBatch() {
                let documentType = this.$vm.$enums["documenttype"].filter(x => x.id == this.documentTypeValue)[0]?.code;
                let apiRequest = {
                    status: this.model?.status,
                    documentType: documentType,
                    foreignkeyid: this.$props.foreignkeyid,
                    transactionIDs: (this.$props?.selectedItems ?? []).filter(x => x.status == this.model?.status).map(x => x.code)
                }
                if (this.allowedModule)
                    return await this.$axios.post(this.$config.config.endpoint.api + `/services/` + (this.$props.area ?? 'tms') + `/` + (this.$props.module ?? 'consignment') + `/batchprintlabel`.replaceAll("//", "/"), apiRequest)
            },
            async document() {
                return await this.$axios.get(this.$config.config.endpoint.api + `/services/` + (this.$props.area ?? 'tms') + `/` + (this.$props.module ?? 'consignment') + `/` + this.model.mask + `/document`.replaceAll("//", "/"));
            },
            async batchDocument() {

                let documentType = this.$vm.$enums["documenttype"].filter(x => x.id == this.documentTypeValue)[0]?.code;
                let apiRequest = {
                    status: this.model?.status,
                    documentType: documentType,
                    foreignkeyid: this.$props.foreignkeyid,
                    transactionIDs: (this.$props?.selectedItems ?? []).filter(x => x.status == this.model?.status).map(x => x.code)
                }
                if (this.allowedModule)
                    return await this.$axios.post(this.$config.config.endpoint.api + `/services/` + (this.$props.area ?? 'tms') + `/` + (this.$props.module ?? 'consignment') +`/batchdocument`.replaceAll("//", "/"),  apiRequest)
            },
            async getBatchDocument() {
                if (this.allowedModule)
                    this.batchDocument().then((response) => {
                        if (response.data.success) {
                            if ((response.data?.result ?? []).length > 0) {
                                try {
                                    this.source.file = response.data.result;
                                    var result = response.data.result.filter(x => x.Filename.indexOf('pdf') !== -1)[0];
                                    this.model.code = result.Filename.substring(0, result.Filename.indexOf("."));
                                } catch { /*NO CATCH*/}
                                
                            }
                        }
                    });
            },
            async getDocument() {
                if (this.model?.mask) {
                    if (this.allowedModule)
                        this.document().then((response) => {
                            this.source.documents = response.data?.result;
                        })
                }
            },
            onCancel() {
                this.dialog.show = false;
                this.$emit('is-cancel');
            },
            onConfirm() {
                this.dialog.show = false;
                
                if (this.allowedModule) {
                    if (this.$props.isBatchPrint) {
                        this.printConfirmBatch().then(() => { this.$emit('is-confirm'); });
                    }
                    else
                        this.printConfirm(this.documentTypeValue).then(() => { this.$emit('is-confirm'); });
                }
                let fileType = (this.documentType !== 'label' ? '-' + (this.documentType.toUpperCase()) : '');
                fileType += (fileType.indexOf('commercial'.toUpperCase()) !== -1 || fileType.indexOf('proforma'.toUpperCase()) !== -1) ? 'INVOICE' : '';
                if ((this.$props.module ?? 'consignment') === "collection")
                    fileType = (fileType.indexOf('summary'.toUpperCase()) !== -1 || fileType.indexOf('details'.toUpperCase()) !== -1) ? ('-MANIFEST' + this.documentType.toUpperCase()) : '';
                this.$print(this.sessionID, this.printer.name, `${this.model?.code}${fileType}.pdf`, this.tenantCode, this.$props.module + '/documents');
            },
            onRefresh() {
                this.initNeodynamics().then(() => {
                    setTimeout(() => {
                        if ((this.printer.source ?? []).length <= 2) {
                            this.initNeodynamics()
                        }
                    }, 5000);
                    //document.getElementById("documentPreview").src = this.sourceURL + Math.random() + '#toolbar=0&navpanes=0&scrollbar=1&';
                });
            },
            async initNeodynamics() {
                neodynamics.default.init();
                new Promise(() => {
                    neodynamics.default.ping();
                    for (var i = 0; i < 10; i++)
                        setTimeout(() => { neodynamics.default.printers(Math.random()); }, (i + 1) * 1000);
                });
                neodynamics.default.ping(this.sessionID);
                let i = 100;
                do {
                    setTimeout(() => {
                        neodynamics.default.printers(this.sessionID);
                        this.printer.source = this.$config.common.$printers(sessionStorage.getItem("wcpp_printers") ?? btoa("Use Default Printers|Use Printer Dialog"));
                    }, 1000);
                    i--;
                }
                while ((this.printer.source ?? []).length < 3 && i > 0);
                if (((this.printer.source ?? []).length <=2  && i <= 0))
                    this.showContent = true;
            }
        },
        created() {
            this.sessionID = sessionStorage.getItem("session-id");

            
        },
        async mounted() {
            try {
                const noRightClick = document.getElementsByTagName("embed")[0];
                noRightClick.addEventListener("contextmenu", e => e.preventDefault())
            }
            catch { /**/ }
            this.model = this.$props.formData;
            this.getDocument();
            if (this.$props.isBatchPrint) {
                this.getBatchDocument();
            }
            this.sessionID = sessionStorage.getItem("session-id");
            //if ((this.printer.source ?? []).length <= 2)
            //    this.initNeodynamics();
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');

            //// Use Neodynamics
            //const neodynamics = require('@/assets/lib/cosmos/_js/neodynamics.js');
            //neodynamics.default.init();
            //new Promise(() => {
            //    neodynamics.default.ping();
            //    for (var i = 0; i < 10; i++)
            //        setTimeout(() => { neodynamics.default.printers(Math.random()); }, (i + 1) * 1000);
            //});
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
    #printSplitButton {
        margin:0 !important;
    }
</style>