<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" @is-send="sendRecord" :title="title" :showSendButton="true">
        <div class="p-grid">
            <div class="p-col-12 p-md-4">
                <form-input id="username" :value="model.username" :v$="v$" @is-sync="model.username = $event.value" v-lowercase v-focus :pattern="(model.type === 1 ? '^([a-zA-Z0-9._\\-]{1,45}-api|api)$' : null)" :pattern-message="(model.type === 1 ? 'Username must always ends with \'-api\'.' : null)" />
                <form-input v-show="(model.type == 1 && model.id > 0 && model.autoConfirm) || model.type != 1" id="password" :disabled="model.id > 0 && user.userType != 0" :type="'password'" :passwordLastDigit="0" :value="model.password ?? (model.id > 0 ? '**********' : null)" placeholder="(Leave blank to auto-generate)" :required="model?.id > 0" :v$="v$" @is-sync="model.password = $event.value" :max-length="1000" />
                <form-input v-if="(model.type == 1 && model.id > 0 && model.autoConfirm)" id="apiPassword" label="API Password" :disabled="true" :type="model.type == 1 ? 'text' : 'password'" :passwordLastDigit="0" :value="apiPassword" :v$="v$" :max-length="1000" />
                <form-input v-if="model && model.mask && model.type == 1 && model.autoConfirm" id="clientID" :value="model.code" :disabled="true" label="Client ID" :showButton="false" labelWidth="200px" />
                <form-input v-if="model && model.mask  && model.type == 1 && model.autoConfirm" id="clientSecret" :value="model.mask" :disabled="true" label="Client Secret" :showButton="false" labelWidth="200px" />
                <form-input id="email" :value="model.email" :v$="v$" @is-sync="model.email = $event.value?.toLowerCase()" v-lowercase :max-length="100" />
                <form-input id="firstName" :value="model.firstName" :v$="v$" @is-sync="model.firstName = $event.value" />
                <form-input id="lastName" :value="model.lastName" :v$="v$" @is-sync="model.lastName = $event.value" />
                <form-lookup id="companyID" :disabled="model.type === 0 || (model.id != 0 && model.type > 4) || (companyDS ?? []).length < 2" label="Company" :source="companyDS" :value="model.companyID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.companyID = $event.value" :showCopy="false" />
                <!--<form-lookup id="departmentID" v-if="model.type > 1" label="Department" :source="departmentDS" :disabled="disabledDepartment" :textField="'name'" :value="model.departmentID" :v$="v$" @is-sync="model.departmentID = $event.value" :showCopy="false" />
    <form-lookup id="teamID" v-if="model.type > 1" label="Team" :value="model.teamID" :source="teamDS" :disabled="disabledTeam" :textField="'name'" :v$="v$" @is-sync="model.display = teamID" :showCopy="false" />-->
            </div>
            <div class="p-col-12 p-md-4">
                <form-lookup v-if="model.type != 1" id="theme" type="enum" source="theme" :v$="v$" :value="model.theme ?? 200" @is-sync="model.theme = $event.value"></form-lookup>
                <form-lookup v-if="model.type != 1" id="displayScale" source="displaysize" type="enum" :value="model.displayScale ?? 14 " @is-sync="{ model.displayScale = $event.value; model.display = $event.value; } " :v$="v$" :required="true"></form-lookup>
                <!--<form-lookup v-if="model.type != 1" id="menuView" type="enum" source="menuview" :value="model.menuView" @is-sync="model.menuView = $event.value" :v$="v$"></form-lookup>-->
                <form-numeric v-if="model.type != 1" id="GridRecords" type="integer" minValue="1" maxValue="100" :suffix="recordDisplay" :value="model.pageSize" :min="1" :max="100" @is-sync="model.pageSize = $event.value" :v$="v$"></form-numeric>
                <form-boolean id="autoConfirm" v-model="model.autoConfirm" v-if="(model?.verificationConfirmDate === null || model?.verificationConfirmDate === undefined) || model.type == 1" :value="model.autoConfirm" onText="YES" offText="NO" :label="autoConfirmMessage" width="120px" textPosition="left" @is-sync="model.autoConfirm = $event.value" :v$="v$"></form-boolean>
                <form-lookup v-if="model.type != 1 && false" type="flag" :value="model.permission" id="permission" label="Permission" :source="'userpermission'" @is-sync="model.permission = $event.value"></form-lookup>

            </div>
            <div class="p-col-12 p-md-4">
                <!--<is-imageupload id="profileImage">

            </is-imageupload>-->
                <!--<div>
                <img :src="`data:image/jpeg;base64, ${model.mask}`" style="max-height: 100px; object-fit: contain; border-radius: 100%;" />
                <pv-file id="image" name="mode.mask" mode="basic" chooseLabel="Select Profile Image" accept="image/*" :auto="true" url="https://cosmos-api.localhost.com/file/upload" @upload="onUpload" fileLimit="1"></pv-file>
            </div>-->
                <form-datetime v-if="model && model.mask" id="VerificationSentDate" :value="model.verificationSentDate" :disabled="true" :hideLabel="true" preLabel="Verification Sent Date" :showButton="false" labelWidth="200px" type="datetime" :showCopy="false" />
                <form-datetime v-if="model && model.mask" id="ConfirmationDate" :value="model.verificationConfirmDate" :disabled="true" :hideLabel="true" preLabel="Confirm Date" :showButton="false" labelWidth="200px" type="datetime" :showCopy="false"/>
                <form-datetime v-if="model && model.mask" id="LastLoginDate" :value="model.lastLoginDate" :disabled="true" :hideLabel="true" preLabel="Last Login Date" :showButton="false" labelWidth="200px" type="datetime" :showCopy="false"/>
                <form-input v-if="model && model.mask" id="ActiveSessionID" :value="model.sessionID" :disabled="true" :hideLabel="true" preLabel="Session ID" :showButton="false" labelWidth="200px" :showCopy="false"/>
                <form-input v-if="model && model.mask" id="ActiveSessionIP" :value="model.sessionIP" :disabled="true" :hideLabel="true" preLabel="Session IP" :showButton="false" labelWidth="200px" :showCopy="false"/>
            </div>
        </div>
        <div class="p-grid" style="margin: 0 -20px;" v-if="model.id != 0">
            <div class="p-col-12">
                <pv-tab>
                    <pv-tabpanel header="SCOPES" v-if="model.type != 2 && model.type != 0 && model.type != 5">
                        <user-scope :is_parentID="parentID" :is_parentMask="parentMask"></user-scope>
                    </pv-tabpanel>
                    <pv-tabpanel header="SETTINGS">
                        <user-general-setting :showSetting="true" :is_parentMask="parentMask" @is-sync="sync_generalSetting($event)" @is-initial-sync="sync_generalSetting($event)" @is-mounted="isMounted($event)" />
                    </pv-tabpanel>
                    <!--<pv-tabpanel header="PERMISSIONS">
                    <div class="p-col-4">
                    </div>
                </pv-tabpanel>-->
                </pv-tab>
            </div>
        </div>
    </form-record>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength,email } from '@vuelidate/validators';
    import generalsetting from "@/views/settings/defaults/user/generalsetting.vue";
    import scope from "@/views/settings/defaults/user/scope.vue";
    export default {
        name: 'UserRecord',
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        components: {
            'user-general-setting': generalsetting,
            'user-scope': scope

        },
        data() {
            return {
                departmentDS: [],
                teamDS: [],
                isChildMounted: false,
                keys: null,
                session: null
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            apiPassword() { return btoa(this.model.password); },
            appSessionKeys() { return this.$vm.$appSession; },
            parentID() {
                return this.model.id;
            },
            parentMask() {
                return this.model.mask;
            },
            ds() { return this.$store.getters["company/ds"]; },
            type() { return this.$route.meta.typeID ?? 0; },
            companyDS() {
                return this.model?.type === 5 || this.model?.type === 6 || this.model?.type === 7 ? this.$config.common.$customer(this.user?.environmentID, this.session?.warehouse?.id ?? this.$vm.$appSession.warehouse.id, this.user) :
                    this.$config.common.$environment().filter((x) => this.user?.userType === 0 ? true : x.environmentID === this.user?.environmentID ?? this.appSessionKeys.environment.id);
            },
            disabledDepartment() {
                return !(this.departmentDS && this.departmentDS.length > 0);
            },
            disabledTeam() {
                return !(this.teamDS && this.teamDS.length > 0) || this.disabledDepartment;
            },
            recordDisplay() {
                return this.$filters.quantifiedDisplay("record", this.model.pageSize) + " per page";
            },
            autoConfirmMessage() {
                return this.model.type == 1 ? "Approved Credential?" : "Auto-Confirm Email?";
            },
            title() {
                if (this.model?.firstName || this.model?.lastName)
                    return `${this.model?.firstName ?? ""} ${this.model?.lastName ?? ""}`;
                return this.$route.title;
            }
            
        },
        methods: {
            async getCompanyList() {
                return this.$store.dispatch("company/getAll", { sort: null, filter: null, where: null });
            },
            filterDepartment() {
                return this.ds.data.filter((x) => x.type === 64 && x.environmentID === this.model.companyID)
            },
            filterTeam() {
                return this.ds.data.filter((x) => x.type === 128 && x.companyID === this.model.companyID && x.departmentID === this.model.departmentID)
            },
            onUpload(e) {
                var image = JSON.parse(e.xhr.responseText);
                this.model.mask = image.file;
            },
            async isMounted(value) {
                this.isChildMounted = value;
                this.v$.$reset();
               
            },
            async sync_generalSetting(model) {
                this.model.generalSetting = model;
                if (this.model.generalSetting.userID === null)
                    this.model.generalSetting.userID = this.model.id;
                if (this.isChildMounted)
                    this.v$.$touch();
            },
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.appSessionKeys;
                });
            },
            'model.companyID':
                {
                handler() {
                        //this.$nextTick(() => {
                        //    this.departmentDS = this.filterDepartment();
                        //    this.teamDS = this.filterTeam();
                        //})
                },
                deep: true
            },
            'model.type':
            {
                handler() {
                    this.$nextTick(() => {
                        if (this.model.type === 1 && this.model.id === 0 && (this.model.password === null || this.model.password === undefined)) {
                            this.model.password = "TEMPXXX" + new Date().getMilliseconds().toString();
                            this.model.lastName = "API"
                        }
                        else {
                            if (this.model.id === 0 && (this.model.type === null || this.model.type === undefined)) {                                
                                switch (this.$router.currentRoute.value.meta.type.toLowerCase()) {
                                    case "sysadmins":
                                        if (this.model.type !== 2)
                                            this.model.type = 2;
                                        break;
                                    case "admins":
                                        if (this.model.type !== 3)
                                            this.model.type = 3;
                                        break;
                                    case "agents":
                                        if (this.model.type !== 4)
                                            this.model.type = 4;
                                        break;
                                    case "custadmins":
                                        if (this.model.type !== 5)
                                            this.model.type = 5;
                                        break;
                                    case "customers":
                                        if (this.model.type !==6)
                                            this.model.type = 6;
                                        break;
                                    case "retailers":
                                        if (this.model.type !== 7)
                                            this.model.type = 7;
                                        break;
                                    case "apis":
                                        if (this.model.type !== 1)
                                            this.model.type = 1;
                                        if (this.model?.id === 0 && this.model.companyID === null) {
                                            this.model.companyId = this.$vm.$appSession.environment.id;
                                        }
                                        break;
                                }
                            }
                        }

                        this.$nextTick(() => {
                            if (this.model?.id === 0 && this.model.companyID === null && this.companyDS?.length > 0) {
                                if (this.model.type !== 5 && this.model.type !== 6 && this.model.type !== 7) {
                                    if (this.model.companyID !== this.$vm.$appSession.environment?.id)
                                        this.model.companyID = this.$vm.$appSession.environment?.id;
                                }
                                else {
                                    if (this.model.companyID !== this.$vm.$appSession.customer?.id ?? 0)
                                        this.model.companyID = this.$vm.$appSession.customer?.id ?? 0;
                                }

                            }
                        })
                    })
                },
                deep: true
            },
            //companyID() {
            //    this.filterDepartment();
            //    this.filterTeam();
            //},
            //departmentID() {
            //    this.filterTeam();
            //}
        },
        validations() {
            return {
                model: {
                    username: { required, minLength: minLength(1), maxLength: maxLength(20), $autoDirty: true },
                    password: { minLength: minLength(10), maxLength: maxLength(50), $autoDirty: true },
                    email: { required, email, maxLength: maxLength(100), $autoDirty: true },
                    firstName: { required, maxLength: maxLength(35), $autoDirty: true },
                    lastName: { required, maxLength: maxLength(35), $autoDirty: true },
                    companyID: { required, $autoDirty: true },
                    pageSize: { $autoDirty: true },
                    /*display: { required, $autoDirty: true },*/
                    theme: { required, $autoDirty: true },
                    menuView: { required, $autoDirty: true },
                    permission: { $autoDirty: true },
                    autoConfirm: { $autoDirty: true }
                }
            };
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            // await this.getCompanyList();
            switch (this.$router.currentRoute.value.meta.type.toLowerCase()) {
                case "sysadmins":
                    this.model.type = 2;
                    if (this.model?.id === 0 && this.model.companyID === null) {
                        this.model.companyID = this.$vm.$appSession.environment.id;
                    }
                    break;
                case "admins":
                    this.model.type = 3;
                    break;
                case "agents":
                    this.model.type = 4;
                    break;
                case "custadmins":
                    this.model.type = 5;
                    break;
                case "customers":
                    this.model.type = 6;
                    break;
                case "retailers":
                    this.model.type = 7;
                    break;
                case "apis":
                    this.model.type = 1;
                    if (this.model?.id === 0 && this.model.companyID === null) {
                        this.model.companyID = this.$vm.$appSession.environment.id;
                    }
                    
                    break;
            }

        },
        async unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
