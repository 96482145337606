<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 1rem;">
            <h3 style="margin-bottom: 0;">STEP 1: Platform</h3>
            <p>This step allows the customer to configure their platform credentials.</p>
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-4">
                    <h4 style="margin-bottom: 2rem;">CREDENTIALS</h4>
                    <form-lookup id="Customer" v-model="model.customerID" :defaultValue="accessScope.customers[0]?.id" :source="accessScope.customers" :required="true" />
                    <credential-form v-model="model.credentials"
                                     v-model:modelPlatform="platform"
                                     v-model:modelInvalid="validation.credential"
                                     v-model:modelPayload="payload" />
                </div>
                <div class="p-col-12 p-md-6 p-lg-8">
                    <instruction-form v-model="model.shopInfo"
                                      v-model:modelPlatform="platform"
                                      v-model:modelAccessScope="accessScope" />
                </div>
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <i></i>
            <pv-button @click="onNextPage()" :loading="activity.loading" :iconPos="'right'" :disabled="disableNext"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-shop fa-fw')}`" style="margin-right: 0.5rem"></i> {{(activity.loading ? 'Validating...' : 'Shop Info')}} <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
    </div>
</template>
<script>
    import credentialForm from '@/components/facility/ecommerce/api2cart/credential-form.vue';
    import instructionForm from '@/components/facility/ecommerce/instruction-form.vue';
    export default {
        name: "PlatformCredentialStep",
        emits: ["update", "update:modelValue", "isSync", "nextPage"],
        components: {
            'credential-form': credentialForm,
            'instruction-form': instructionForm
        },
        props: {
            modelValue: { type: Array, default: () => [] },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
        },
        data() {
            return {
                model: {
                    id: 0,
                    customerID: 0,
                    basicInfo: {
                        code: null,
                        name: null,
                        endSolution: 200,
                        thirdPartySolution: 100,
                        status: 100,
                        verificationDate: null,
                    },
                    defaults: {
                        warehouseID: 0,
                        currencyID: 0,
                        content: null,
                        email: null,
                        telephone: null,
                        packagingType: 4,
                        incoterm: 16,
                        weightUnit: 100,
                        dimensionUnit: 100
                    },
                    credentials: {
                        platformID: 1,
                        credential_01: null,
                        credential_02: null,
                        credential_03: null,
                        credential_04: null,
                        credential_05: null,
                        credential_06: null,
                        credential_07: null,
                        credential_08: null,
                        credential_09: null,
                        credential_10: null,
                        credential_11: null,
                        credential_12: null,
                        credential_13: null,
                        credential_14: null,
                        credential_15: null,
                        tokenIssued: null,
                        tokenExpiry: null
                    },
                    orderAutomation: {
                        type: 100,
                        webhook: 200,
                        fulfillOrder: false,
                        partialFulfillment: false,
                        notifyUser: false,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    productAutomation: {
                        type: 100,
                        webhook: 200,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    notification: {
                        failed: null,
                        success: null
                    },
                    shopInfo: {
                        code: null,
                        name: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        country: null,
                        countryID: null,
                        contact: null,
                        telephone: null,
                        email: null,
                        shopDomain: null,
                        storeDomain: null,
                        timezone: null,
                        ianaTimezone: null,
                        supportedCurrencies: null,
                        defaults: {
                            primaryLocationID: null,
                            currency: null,
                            weightUnit: null
                        },
                        taxConfiguration: {
                            taxInclusive: false,
                            taxShipping: false
                        }
                    },
                    environmentID: null,
                    recordStatus: 0
                },
                accessScope: null,
                validation: {
                    credential: false
                },
                platform: null,
                activity: {
                    loading: false
                },
                payload: null
            };
        },
        computed: {
            disableNext() { return this.validation.credential; }
        },  
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); }
        },
        methods: {
            onNextPage() {
                this.$nextTick(() => { this.activity.loading = true; });
                this.$store.dispatch("store/postURL", { url: "/api/ecommerce/validate", payload: this.payload })
                    .then((response) => {
                        if (response.Status === "SUCCESS" && response.Data)
                        {
                            var data = response.Data;
                            if (data && this.model.id === 0)
                            {
                                // Map Basic Info
                                this.model.basicInfo = {
                                    code: data?.ShopID,
                                    name: data?.Name,
                                    endSolution: 200,
                                    thirdPartySolution: 100,
                                    status: 200,
                                    verificationDate: new Date()
                                };
                                // Map Shop Info
                                this.model.shopInfo = {
                                    code: data?.ShopID ?? null,
                                    name: data?.Name ?? null,
                                    address1: data?.Address?.Address1 ?? null,
                                    address2: data?.Address?.Address2 ?? null,
                                    address3: data?.Address?.Address3 ?? null,
                                    city: data?.Address?.City ?? null,
                                    state: data?.Address?.State ?? null,
                                    stateCode: data?.Address?.StateCode ?? null,
                                    postalCode: data?.Address?.PostalCode ?? null,
                                    country: data?.Address?.CountryCode ?? null,
                                    countryID: this.$vm.$countries.filter(x => x.iso2 === data?.Address?.CountryCode ?? '99')[0]?.id,
                                    contact: `${data?.Contact?.FirstName} ${data?.Contact?.LastName ?? ''}`.trim(),
                                    telephone: data?.Contact?.Mobile ?? null,
                                    email: data?.Contact?.Email?.toLowerCase() ?? null,
                                    shopDomain: data?.Domain?.Platform ?? null,
                                    storeDomain: data?.Domain?.Store ?? null,
                                    timezone: data?.Timezone?.Timezone ?? null,
                                    ianaTimezone: data?.Timezone?.IANA_Timezone ?? null,
                                    supportedCurrencies: data?.Defaults?.PresentmentCurrencies ?? [...data?.Defaults?.CurrencyID],
                                    defaults: {
                                        primaryLocationID: data?.Defaults?.LocationID ?? null,
                                        currency: data?.Defaults?.Currency ?? null,
                                        weightUnit: data?.Defaults?.WeightUnit ?? null
                                    },
                                    taxConfiguration: {
                                        taxInclusive: data?.TaxImplementation?.TaxInclusive ?? false,
                                        taxShipping: data?.TaxImplementation?.TaxShipping ?? false
                                    }
                                };
                                // Map Defaults
                                this.model.defaults = {
                                    content: null,
                                    currencyID: this.$vm.$currencies.filter(x => x.code === (this.model.shopInfo.defaults.currency ?? "999"))[0]?.id,
                                    dimensionUnit: 100,
                                    email: null,
                                    incoterm: 16,
                                    packagingType: 4,
                                    telephone: null,
                                    warehouseID: 0,
                                    weightUnit: (this.$vm.$enums.weightunit.filter(x => x.code.toLowerCase() == (data?.Defaults?.WeightUnit ?? 'kg') || x.text.toLowerCase() == (data?.Defaults?.WeightUnit ?? 'kg')))[0]?.id
                                };
                                // Map Locations
                                this.model.locations = (data?.Locations ?? []).map(x => ({
                                    code: x.LocationID,
                                    name: x.Name,
                                    storeID: this.model.id,
                                    address1: x.Address?.Address1 ?? null,
                                    address2: x.Address?.Address2 ?? null,
                                    address3: x.Address?.Address3 ?? null,
                                    city: x.Address?.City ?? null,
                                    state: x.Address?.State ?? null,
                                    stateCode: x.Address?.StateCode ?? null,
                                    postalCode: x.Address?.PostalCode ?? null,
                                    country: x.Address?.CountryCode ?? null,
                                    countryID: this.$vm.$countries.filter(x => x.code === (x?.Address?.CountryCode ?? '99'))[0]?.id,
                                    warehouseID: null,
                                    environmentID: null,
                                    isDefault: x.LocationID === data?.Defaults?.LocationID,
                                    isActive: x.IsActive,
                                    isLegacy: x.IsLegacy
                                }));
                                // Map Webhooks
                                this.model.webhooks = (data?.AllowedWebhooks ?? []).map(x => ({
                                    code: `TEMP_${Math.random(1000)}`.replace("_0.", "_"),
                                    name: x,
                                    storeID: this.model.id,
                                    callback: null,
                                    isActive: false
                                }));
                                // Intialize Change Page
                                this.$emit('next-page', { formData: this.model, platform: this.platform, pageIndex: 0 });
                            }
                            this.$nextTick(() => { this.activity.loading = false; });
                        }
                        else if ((response.Errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Validate Credentails', detail: response.Errors[0], life: 3000 });
                            this.$nextTick(() => { this.activity.loading = false; });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.$nextTick(() => { this.activity.loading = false; });
                    });
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("update:modelPlatform", this.platform);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>