<template>
    <div class="p-d-flex">
        <div class="p-col-12">
            <pv-steps :model="items" style="margin: -2.4rem -1.5rem 0.5rem -1.5rem; border-bottom: 1px solid var(--surface-d); padding: 10px; background: var(--surface-c) " />
            <router-view name="stepper" v-slot="{Component}" :formData="model" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete" @manageOrder="manageOrder">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';

    export default {
        name: 'GuideTemplate',
        emits: ["is-sync"],
        mixins: [mixins.RECORD],
        data() {
            return {
                model: null,
                activeIDX: 0,
                modelCheck: {
                    oldCustomerID: null,
                    newCustomerID: null
                }
            }
        },
        setup: () => ({ v$: useVuelidate() }),
        props: {
            parentID: { type: Number, default: -1 },
            formData: { type: Object },
            activeIndex: { type: Number, default: 0 },
            toggleTicker: { type: Number, default: 0 },
        },
        validations() {
            return {
                model: {
                  
                }
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            v$: {
                handler() { this.modelState = this.v$ }, deep: true
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        for (let field in this.$props.formData) {
                            this.model[field] = this.$props.formData[field];
                        }
                    });
                }, deep: true
            },
            'toggleTicker': {
                handler() {
                    this.$nextTick(() => {
                        for (let field in this.$props.formData) {
                            this.model[field] = this.$props.formData[field];
                        }
                        this.activeIDX = this.$props.activeIndex;
                        this.$router.push(this.items[this.activeIDX].to);
                    });
                }, deep: true
            },
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            isValidCollection() {
                return true;
            },
            isValidDelivery() {
                return true;
            },
            OriginISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID)[0]?.code;
            },
            DestinationISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID)[0]?.code;
            },
            isDomestic() {
                return this.$filters.isDomestic(this.OriginISO2, this.model?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.model?.shipTo_PostalCode ?? '');
            },
            items() {
                let _items = [
                    {
                        label: 'Sender & Recipient',
                        to: '/transaction/consignment/create'
                    },
                    //{
                    //    label: 'Recipient',
                    //    to: '/transaction/consignment/step/recipient'
                    //},
                    {
                        label: 'International Forms',
                        to: '/transaction/consignment/step/international'
                    },
                    {
                        label: 'Package',
                        to: '/transaction/consignment/step/package'
                    },
                    {
                        label: 'Allocation',
                        to: '/transaction/consignment/step/allocation'
                    },
                    {
                        label: 'Confirmation',
                        to: '/transaction/consignment/step/confirmation'
                    }
                ];
                
                let index = _items.findIndex(x => x.label === 'International Forms');
                if (index !== -1) {
                    if (this.isDomestic) {
                        _items.splice(index, 1);
                    }
                    //_items = this.isDomestic ? _items.splice(index, 1) : _items;
                }
                return _items;
            },
            accespointAddress() {
                let _address = this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address;
                let _fullAddress = null;
                let _accesspointAddress = [];
                if (_address != null && _address !== undefined) {
                    _fullAddress = (_address?.Address1 ?? "") + " " + (_address?.Address2 ?? "") + (_address?.Address3 ?? "");
                    // Loop through the input string with a step size of 35
                    if (_fullAddress) {
                        for (let i = 0; i < _fullAddress.length; i += 35) {
                            // Use substring to get the next 35 characters and push it to the result array
                            _accesspointAddress.push(_fullAddress.substring(i, i + 35));
                        }
                    }
                }
                return _accesspointAddress;
            },
            apiAllocate() {

                if ((this.model.serviceGroupCode !== null && this.model.serviceGroupCode !== "" && this.model.serviceGroupCode !== undefined) &&
                    (this.model.productCode === null || this.model.productCode === "" || this.model.productCode === undefined)
                ) {

                    return {
                        Request: {
                            TransactionID: this.model.code,
                            AllocationDetail: {
                                ServiceGroupCode: (this.model.serviceGroupCode !== null && this.model.serviceGroupCode !== "" && this.model.serviceGroupCode !== undefined) ? this.model.serviceGroupCode : null,
                                AccessPointLocationCode: (this.model.accessPointLocationCode !== null && this.model.accessPointLocationCode !== "" && this.model.accessPointLocationCode !== undefined) ? this.model.accessPointLocationCode : null,
                                ParcelReleaseCode: (this.model.parcelReleaseCode !== null && this.model.parcelReleaseCode !== "" && this.model.parcelReleaseCode !== undefined) ? this.model.parcelReleaseCode : null,
                                PushReturnEntry: this?.model?.pushReturns ?? false,
                                CollectionDetail: {
                                    CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                    ReadyTime: "15:00",
                                    CollectionTime: "16:00",
                                    ClosingTime: "18:00",
                                    CutOffTime: "15:00"
                                },
                                Document: {
                                    Invoice: {
                                        Type: "COMMERCIAL",
                                        Date: this.$filters.formatDateWithFormat((this.model.invoiceDate != null ? this.model.invoiceDate : new Date()), "YYYY-MM-DD"),
                                        Reference: this.model.invoiceNumber,
                                        Notes : this.model.invoiceNote
                                    }
                                }
                                
                            }
                        }
                    };

                }
                return {
                    Request: {
                        TransactionID: this.model.code,
                        AllocationDetail: {
                            ServiceGroupCode: (this.model.serviceGroupCode !== null && this.model.serviceGroupCode !== "" && this.model.serviceGroupCode !== undefined) ? this.model.serviceGroupCode : null,
                            CarrierDetail: {
                                CarrierCode: this.model.carrierCode,
                                ProductCode: this.model.productCode,
                                ReturnProductCode: this.model.returnProductCode,
                                CarrierServiceCode: this.model.carrierAPICode
                            },
                            AccessPointLocationCode: (this.model.accessPointLocationCode !== null && this.model.accessPointLocationCode !== "" && this.model.accessPointLocationCode !== undefined) ? this.model.accessPointLocationCode : null,
                            ParcelReleaseCode: (this.model.parcelReleaseCode !== null && this.model.parcelReleaseCode !== "" && this.model.parcelReleaseCode !== undefined) ? this.model.parcelReleaseCode : null,
                            PushReturnEntry: this?.model?.pushReturns ?? false,
                            CollectionDetail: {
                                CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                ReadyTime: "15:00",
                                CollectionTime: "16:00",
                                ClosingTime: "18:00",
                                CutOffTime: "15:00"
                            },
                            Document: {
                                Invoice: {
                                    Type: "COMMERCIAL",
                                    Date: this.$filters.formatDateWithFormat((this.model.invoiceDate != null ? this.model.invoiceDate : new Date()), "YYYY-MM-DD"),
                                    Reference: this.model.invoiceNumber,
                                    Notes: this.model.invoiceNote
                                }
                            }
                        }
                    }
                };
            },
            apiAllocateConsignment() {
                let _accesspointAddress = this.accespointAddress;
                return {
                    Source: "WEB",
                    Request: {
                        TransactionID: this?.model?.code,
                        ReferenceOnly: true,
                        BookingForm: {
                            ShippingInfo: {
                                PickupInfo: {
                                    Type: this.$vm.$enums['pickuptype'].filter(x => x.id === this.model?.pickupType ?? -100)[0]?.code ?? "DROPOFF",
                                    Date: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
                                    ReadyTime: this.$filters.formatDateWithFormat(this.model.request_ReadyTime, "HH:mm"),
                                    ClosingTime: this.$filters.formatDateWithFormat(this.model.request_ClosingTime, "HH:mm"),
                                },  
                                ServiceGroup: this.model?.allocationDetail?.shippingInfo?.serviceGroup?.code,
                                ProviderInfo: this.model?.allocationDetail?.shippingInfo?.carrier?.code !== null ? {
                                    Carrier: this.model?.allocationDetail?.shippingInfo?.carrier?.code,
                                    Service: this.model?.allocationDetail?.shippingInfo?.product?.code,
                                    Option: this.model?.allocationDetail?.shippingInfo?.option?.code,
                                } : null,
                                AccessPointInfo: (this.model?.allocationDetail?.shippingInfo?.accesspoint?.code || (this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)) ? {
                                    Code: !(this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? this.model?.allocationDetail?.shippingInfo?.accesspoint?.code : null,
                                    ReleaseCode: this.model?.allocationDetail?.shippingInfo?.release?.code,
                                    Address: !(this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? {
                                        Company: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.CompanyName,
                                        //Address1: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address?.Address1,
                                        //Address2: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address?.Address2,
                                        //Address3: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address?.Address3,
                                        Address1: _accesspointAddress[0],
                                        Address2: _accesspointAddress.length > 1 ? _accesspointAddress[1] : null,
                                        Address3: _accesspointAddress.length > 2 ? _accesspointAddress[2] : null,
                                        City: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address?.City,
                                        State: null,
                                        PostalCode: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address?.PostalCode,
                                        Country: this.model?.allocationDetail?.shippingInfo?.accesspoint?.record?.Address?.Country
                                    } : {
                                        Company: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                                        Address1: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
                                        Address2: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
                                        Address3: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
                                        City: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
                                        State: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                                        PostalCode: this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                                        Country: this.$vm.$countries.filter(x => x.id === this.model?.allocationDetail?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID)[0]?.code
                                    }
                                } : null,
                                GenerateCustomsData: (this.model?.allocationDetail?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false) ? (this.model?.allocationDetail?.shippingInfo?.customization?.generateCustomsData ? "Y" : "N") : null,
                                AdditionalCode: (this.model?.allocationDetail?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? this.model?.allocationDetail?.shippingInfo?.additional?.code : null
                            },
                            IsReturnable: (this.model?.isReturnable ?? false),
                            ReturnsInfo: (this.model?.isReturnable ?? false) ? {
                                ProviderInfo: this.model?.allocationDetail?.returnInfo?.carrier?.code !== null ? {
                                    Carrier: this.model?.allocationDetail?.returnInfo?.carrier?.code,
                                    Service: this.model?.allocationDetail?.returnInfo?.product?.code,
                                    Option: this.model?.allocationDetail?.returnInfo?.option?.code,
                                }  : null,
                                Address: null,
                                AccessPointInfo: null,
                                AdditionalCode: (this.model?.allocationDetail?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? this.model?.allocationDetail?.returnInfo?.additional?.code : null
                            } : null
                        },
                        GenerateForm: !this.isDomestic ? {
                            Invoice: true,
                            Customs: [
                                {
                                    Type: "CN22",
                                    Customs: "CONSIGNMENT"
                                }
                            ]
                        } : null,
                    }
                }
            },
            apiManageConsignment() {
                let packages = [];                
                (this.model?.packages ?? []).forEach((_package) => {
                    let _items = [];
                    (_package?.items ?? []).forEach(_item => {
                        _items.push({
                                Product: {
                                    Code: _item?.code ?? null,
                                    Name: _item?.name ?? null,
                                    Attribute: _item?.attribute ?? null,
                                    SKU: _item?.sku ?? null,
                                    GTIN: _item?.gtin ?? null,
                                    Image: ((_item?.image?.length ?? 0) > 0) ? {
                                        Type: "IMAGE",
                                        FileURL: null,
                                        Base64File: _item.image[0].file,
                                        FileName: _item.image[0].filename,
                                        ContentType: _item.image[0]?.contentType
                                        } : null
                                },
                                Commodity: ((_item?.htsCode ?? null) !== null || (_item?.manufacturerCountryID > 0)) ? {
                                    HSCode: _item?.htsCode ?? null,
                                    Country: this.$vm.$countries.filter(x => x.id === _item?.manufacturerCountryID ?? -100)[0]?.code ?? null
                                } : null,
                                Quantity: (_item?.quantity ?? 0),
                                Weight: {
                                    GrossWeight: _item.weight,
                                    NetWeight: _item.weight//,
                                    //VolumetricWeight: 0.00,
                                },
                                Declaration: {
                                    DeclaredValue: _item?.consignmentValue ?? 0.00,
                                    CustomsValue: _item?.customsValue ?? 0.00,
                                    DiscountValue: _item?.discountValue ?? 0.00,
                                    InsuranceValue: _item?.insuranceValue ?? 0.00,
                                    DutyPaidValue: _item?.dutyValue ?? 0.00,
                                    TaxValue: _item?.taxValue ?? 0.00,
                                    TaxRate: _item?.taxRate ?? 0.00
                                },
                                Dimension: (((_item?.length ?? 0) > 0) || ((_item?.width ?? 0) > 0) || ((_item?.height ?? 0) > 0) || ((_item?.volume ?? 0) > 0)) ? {
                                            Length: (_item?.length ?? 0),
                                            Width: (_item?.width ?? 0),
                                            Height: (_item?.height ?? 0),
                                            VolumeCBM: (_item?.volume ?? 0),
                                            VolumeAutoCompute: (_item?.volumeAutoCompute ?? true)
                                        } : null,
                                HazMatForm: ((this.model?.isHazardous ?? false) && (_item?.dG_HazmatID > 0)) ? {
                                    HazMatInfo: {
                                        ContentID: "DG" + _item?.dG_HazmatID.toString(),
                                        UNCode: _item?.dG_UnCode ?? null,
                                    },
                                    DryIceInfo: _item?.dG_HazmatID === 1202 ? {
                                        NetWeight: _item?.dG_DryIceNetWeight,
                                        WeightUnit: this.$vm.$enums['dg_weightunit'].filter((x) => x.id == _item?.dG_DryIceUnitWeight)[0]?.code ?? null
                                    } : null,
                                    BatteryInfo: null,//{ BatteryType: "" },
                                    RegulatedCode: this.$vm.$enums['dg_regulationtype'].filter((x) => x.id == _item?.dG_RegulatedCode)[0]?.code ?? null
                                } : null
                        })
                    });
                    //if ()
                    packages.push({
                        CartonNumber: _package.code,
                        Weight: {
                            GrossWeight: _package?.weight ?? 0.00,
                            NetWeight: _package.netWeight ?? 0.00,
                            VolumetricWeight: 0.00
                        },
                        Declaration: {
                            DeclaredValue: _package?.consignmentValue ?? 0.00,
                            CustomsValue: _package?.customsValue ?? 0.00,
                            DiscountValue: _package?.discountValue ?? 0.00,
                            InsuranceValue: _package?.insuranceValue ?? 0.00,
                            DutyPaidValue: _package?.dutyValue ?? 0.00,
                            TaxValue: _package?.taxValue ?? 0.00,
                            TaxRate: _package?.taxRate ?? 0.00
                        },
                        Dimension: (((_package?.length ?? 0) > 0) || ((_package?.width ?? 0) > 0) || ((_package?.height ?? 0) > 0) || ((_package?.volume ?? 0) > 0)) ? {
                                Length: (_package?.length ?? 0),
                                Width: (_package?.width ?? 0),
                                Height: (_package?.height ?? 0),
                                VolumeCBM: (_package?.volume ?? 0),
                                VolumeAutoCompute: (_package?.volumeAutoCompute ?? true)

                            } : null,
                        AdditionalReferences: (_package?.ssccNumber) ? {
                            SSCCNumber: _package.ssccNumber,
                        } : null,
                        AdditionalHandling: this.$vm.$enums['packageadditionalhandlingtype'].filter(x => x.id === _package?.additionalHandling ?? -100)[0]?.code ?? null,
                        Customs: (((_package?.name ?? "").length > 0) || ((_package?.cpcNumber ?? "").length > 0)) ? {
                            Content: ((_package?.name ?? "").length > 0) ? _package?.name : null,
                            CPCNumber: ((_package?.cpcNumber ?? "").length > 0) ? _package?.cpcNumber : null
                        } : null,
                        Markings: {
                            IsPallet: _package?.isPallet ?? false,
                            IsFragile: _package?.isFragile ?? false,
                            HasLiquid: _package?.hasLiquid ?? false,
                            UseTailLift: _package?.useTailLift ?? false
                        },
                        Items: _items.length > 0 ? _items : null
                    });
                });
                var senderCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]);
                var recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);

                return {
                    Source: "Web",
                    Request: {
                        TradeType: this.getTradeType(),
                        Customer: (this.model?.customerCode ?? this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code ?? null),
                        Warehouse: this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0]?.code,
                        Sender: {
                            Address: {
                                Company: this.model?.shipFrom_Company ?? "",
                                Address1: this.model?.shipFrom_Address1 ?? "",
                                Address2: this.model?.shipFrom_Address2 ?? "",
                                Address3: this.model?.shipFrom_Address3 ?? "",
                                City: this.model?.shipFrom_City ?? "",
                                //State: this.model?.shipFrom_State ?? "",
                                State: (senderCountry?.stateRequired ?? false) ? ((senderCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipFrom_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipFrom_State ?? "")) : (this.model?.shipFrom_State ?? ""),
                                PostalCode: this.model.shipFrom_PostalCode ?? "",
                                Country: this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]?.code ?? "",
                                EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipFrom_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS",
                                AtRisk: this.model.shipFrom_AtRisk ?? false,
                            },
                            Contact: {
                                FirstName: this.model?.shipFrom_FirstName ?? "",
                                LastName: this.model?.shipFrom_LastName ?? "",
                                Email: this.model?.shipFrom_Email ?? "",
                                Telephone: this.model?.shipFrom_Telephone ?? "",
                                Mobile: this.model?.shipFrom_Mobile ?? "",
                            },
                            Identity: {
                                VAT: this.model?.shipFrom_TaxID ?? "", 
                                EORI: this.model?.shipFrom_EORI ?? "",
                                IOSS: this.model?.shipFrom_IOSSNumber ?? "",
                                UKIMS: this.model?.shipFrom_UKIMS ?? "",
                                PersonalID: (this.model?.shipFrom_IDentityNumber) ? {
                                    Number: this.model?.shipFrom_IDentityNumber ?? "",
                                    Type: this.$vm.$enums['identitynumbertype'].filter(x => x.id === this.model?.shipFrom_IDentityNumberType ?? -100)[0]?.code ?? "PASSPORT"
                                } : null
                            },
                            SaveToAddressBook: this.model?.shipFrom_SaveAddressBook ?? false
                        },
                        Recipient: {
                            Address: {
                                Company: this.model?.shipTo_Company ?? "",
                                Address1: this.model?.shipTo_Address1 ?? "",
                                Address2: this.model?.shipTo_Address2 ?? "",
                                Address3: this.model?.shipTo_Address3 ?? "",
                                City: this.model?.shipTo_City ?? "",
                                State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipTo_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipTo_State ?? "")) : (this.model?.shipTo_State ?? ""),
                                PostalCode: this.model.shipTo_PostalCode ?? "",
                                Country: this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]?.code ?? "",
                                EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipTo_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS",
                                AtRisk: this.model.shipTo_AtRisk ?? false,
                            },
                            Contact: {
                                FirstName: this.model?.shipTo_FirstName ?? "",
                                LastName: this.model?.shipTo_LastName ?? "",
                                Email: this.model?.shipTo_Email ?? "",
                                Telephone: this.model?.shipTo_Telephone ?? "",
                                Mobile: this.model?.shipTo_Mobile ?? "",
                            },
                            Identity: {
                                VAT: this.model?.shipTo_TaxID ?? "",
                                EORI: this.model?.shipTo_EORI ?? "",
                                IOSS: this.model?.shipTo_IOSSNumber ?? "",
                                UKIMS: this.model?.shipTo_UKIMS ?? "",
                                PersonalID: (this.model?.shipTo_IDentityNumber) ? {
                                    Number: this.model?.shipTo_IDentityNumber ?? "",
                                    Type: this.$vm.$enums['identitynumbertype'].filter(x => x.id === this.model?.shipTo_IDentityNumberType ?? -100)[0]?.code ?? "PASSPORT" //identitynumbertype
                                } : null
                            },
                            SaveToAddressBook: this.model?.shipTo_SaveAddressBook ?? false
                        },
                        Order: {
                            Reference1: (!(this.model?.autoNumber ?? true) || (this.model?.customerReference ?? "").length > 0) ? this.model?.customerReference : "cosmos-autonumber",
                            Reference2: this.model?.customerReference2,
                            Reference3: this.model?.customerReference3,
                            Reference4: this.model?.customerReference4,
                            Reference5: this.model?.customerReference5,
                            Reference6: this.model?.customerReference6,
                            Reference7: this.model?.customerReference7,
                            Instruction: this.model?.deliveryInstruction,
                            SafePlace: this?.model?.safePlace,
                            PackageType: this.$vm.$enums['serviceproviderproductpackagingtype'].filter(x => x.id === this.model?.packageType ?? -100)[0]?.code ?? "PACKAGE",
                            Currency: this.$vm.$currencies.filter(x => x.id === this.model?.currencyID ?? -100)[0]?.code ?? "GBP",
                            WeightUnit: this.$vm.$enums['weightunit'].filter(x => x.id === this.model?.weightUnit ?? -100)[0]?.code ?? "KG",
                            DimensionUnit: this.$vm.$enums['lengthunit'].filter(x => x.id === this.model?.dimensionUnit ?? -100)[0]?.code ?? "CM",
                            Notification: {
                                SMS: (this.$filters.getFlags(this.model?.notification ?? 0) ?? []).filter(x => x ===1)[0] > 0,
                                Email: (this.$filters.getFlags(this.model?.notification ?? 0) ?? []).filter(x => x === 2)[0] > 0,
                            },
                            InternationalForm: !this.isDomestic ? {
                                Customs: {
                                    Content: this.model?.packageContent,
                                    CPCNumber: this.model?.cpcNumber
                                },
                                Incoterm: this.$vm.$enums['incotermtype'].filter(x => x.id === this.model?.incoterm ?? -100)[0]?.code ?? "DDU",
                                CustomsCategory: this.$vm.$enums['customscategorytype'].filter(x => x.id === this.model?.customsCategoryType ?? -100)[0]?.code ?? "SAL",
                                ExportReason: this.$vm.$enums['exportreasontype'].filter(x => x.id === this.model?.exportReason ?? -100)[0]?.code ?? "SAL"
                            } : null,
                            InvoiceForm: {
                                Type: this.$vm.$enums['invoicetype'].filter(x => x.id === this.model?.invoiceType ?? -100)[0]?.code ?? "COMMERCIAL",
                                Date: this.$filters.formatDateWithFormat(this.model.invoiceDate ?? new Date(), "YYYY-MM-DD"),
                                Reference: this.model?.invoiceNumber ?? null,
                                Notes: this.model?.invoiceNote ?? null,
                                ShippingCost: this.model?.shippingCost ?? 0
                            },
                            HazMatContactInfo: (this.model?.isHazardous ?? false) ? {
                                Contact: this.model?.dG_EmergencyContact,
                                Telephone: this.model?.dG_EmergencyTelephone
                            } : null,
                            Packages: packages
                        }
                    }
                };
            }
        },
        methods: {
            getTradeType() {
                var warehouse = this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0];
                var warehouseAddress = (warehouse.address1 ?? "") + "" + (warehouse.address2 ?? "") + "" + (warehouse.address3 ?? "") + "" + (warehouse.city ?? "") + "" + "" + warehouse.postalCode + "" + warehouse.countryID;
                warehouseAddress = warehouseAddress.replace(/^a-zA-Z0-9 ]/g, '');
                var recipientAddress = (this.model?.shipTo_Address1 ?? "") + "" + (this.model?.shipTo_Address2 ?? "") + "" + (this.model?.shipTo_Address3 ?? "") + "" + (this.model?.shipTo_City ?? "") + "" + this.model?.shipTo_PostalCode + "" + this.model?.shipTo_CountryID;
                recipientAddress = recipientAddress.replace(/^a-zA-Z0-9 ]/g, '');
                var senderAddress = (this.model?.shipFrom_Address1 ?? "") + "" + (this.model?.shipFrom_Address2 ?? "") + "" + (this.model?.shipFrom_Address3 ?? "") + "" + (this.model?.shipFrom_City ?? "") + "" + this.model?.shipFrom_PostalCode + "" + this.model?.shipFrom_CountryID;
                senderAddress = senderAddress.replace(/^a-zA-Z0-9 ]/g, '');
               
                if (senderAddress !== warehouseAddress && recipientAddress !== warehouseAddress)
                    return "THIRDCOUNTRY";
                else if (senderAddress !== warehouseAddress && recipientAddress === warehouseAddress)
                    return "IMPORT";
                else
                    return "EXPORT";
            },
            async getByCode() {
                return await this.$axios.get(this.$config.config.endpoint.api + `/services/tms/consignment/code/` + this.model.code + ``.replaceAll("//", "/"));
            },
            async manage() {
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment`.replaceAll("//", "/"), this.apiManageConsignment)
            },
            async allocate() {
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/` + this.model.code + `/allocate`.replaceAll("//", "/"), this.apiAllocateConsignment)
            },
            async allocateOLD() {
                return await this.$axios.post(this.$config.config.endpoint.polaris +`/consignment/` + this.model.code + `/allocate`.replaceAll("//", "/"), this.apiAllocate);
            },
            nextPage(event) {
                this.$nextTick(() => {
                    this.model.nextButtonTicker = (this.model.nextButtonTicker ?? 0) + 1;
                    this.modelCheck.oldCustomerID = this.model.customerID;
                    for (let field in event.formData) {
                        this.model[field] = event.formData[field];
                    }
                    this.modelCheck.newCustomerID = this.model.customerID;
                    if (event.pageIndex == 2) {
                        this.model.resetProvider = true;
                    }
                    let itemIndex = event.pageIndex;
                    if (event.pageIndex === 1) {
                        itemIndex = event.pageIndex + 1;
                        //this.$nextTick(() => {
                        //    this.$router.push(this.items[event.pageIndex + 1].to);
                        //});
                    }
                    else {
                        if (event.pageIndex === 2 && this.items.length === 4) {
                            itemIndex = event.pageIndex;
                            //this.$router.push(this.items[event.pageIndex].to);
                        }
                        else {
                            if (this.items[event.pageIndex + 1] !== undefined) {
                                itemIndex = event.pageIndex + 1;
                                //this.$router.push(this.items[event.pageIndex + 1].to);
                            }
                            else {
                                itemIndex = event.pageIndex;
                                //this.$router.push(this.items[event.pageIndex].to);
                            }
                        }
                    }
                   
                    switch (this.items[itemIndex].label ?? "") {
                        case "International Forms":
                            if (this.model.resetDefaultData == undefined) {
                                this.model.resetDefaultData = true;
                                this.model.resetDefaultDataPackages = true;
                            }
                            if (this.model.resetDefaultData) {
                                if (this.model.initializeInternationalForms === undefined)
                                    this.model.initializeInternationalForms = this.modelCheck.oldCustomerID == undefined || this.modelCheck.oldCustomerID == null;
                                else if (this.modelCheck.oldCustomerID !== undefined) {
                                    this.model.initializeInternationalForms = this.modelCheck.oldCustomerID !== this.modelCheck.newCustomerID
                                }    
                                this.model.resetDefaultData = false;
                                this.model.resetDefaultData = undefined;
                            }
                            break;
                        case "Package":                            
                            if (this.model.resetDefaultData == undefined) {
                                this.model.resetDefaultData = true;
                                this.model.resetDefaultDataPackages = true;
                                this.model.initializeOrderForm = undefined;
                                this.model.initializePackages = undefined;
                            }
                            else if (this.model.resetDefaultData) {
                                this.model.resetDefaultData = false;
                                this.model.resetDefaultDataPackages = true;
                            }
                            //if (this.model.resetDefaultDataPackages) {
                            //    this.model.resetDefaultDataPackages = true;
                            //}
                            if (this.model.resetDefaultDataPackages) {
                                if (this.model.initializeOrderForm === undefined)
                                    this.model.initializeOrderForm = true; //this.modelCheck.oldCustomerID == undefined || this.modelCheck.oldCustomerID == null;
                                //else if (this.modelCheck.oldCustomerID !== undefined) {
                                //    this.model.initializeOrderForm = this.modelCheck.oldCustomerID !== this.modelCheck.newCustomerID
                                //}
                                if (this.model.initializePackages === undefined)
                                    this.model.initializePackages = true; // this.modelCheck.oldCustomerID == undefined || this.modelCheck.oldCustomerID == null;
                                //else if (this.modelCheck.oldCustomerID !== undefined) {
                                //    this.model.initializePackages = this.modelCheck.oldCustomerID !== this.modelCheck.newCustomerID
                                //}
                                if (this.model.initializePackages) {
                                    if (this.modelCheck.oldCustomerID !== undefined) {
                                        if (this.modelCheck.oldCustomerID !== this.modelCheck.newCustomerID) {
                                            this.model.resetPackage = false;
                                            this.model.resetProvider = true;
                                            this.model.packages = [];
                                        }
                                    }
                                    if (event.formData.resetPackage)
                                        this.model.packages = [];
                                }
                                this.model.resetDefaultDataPackages = false;
                            }
                            else {
                                this.model.resetDefaultData = false;
                                this.model.resetDefaultDataPackages = false;
                                this.model.initializeOrderForm = false;
                            }
                            
                            break;
                    }
                    this.$emit('is-sync', this.model);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.$router.push(this.items[itemIndex].to);
                        }, 250);
                        
                    });
                });
            },
            prevPage(event) {
                this.model.nextButtonTicker = (this.model.nextButtonTicker ?? 0) + 1;
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                if (this.items.length === 4 && event.pageIndex >= 1)
                    this.$router.push(this.items[event.pageIndex - 2].to);
                else {
                     this.$router.push(this.items[event.pageIndex - 1].to);
                }
                
                
            },
            completeOLD() {
                this.model.code = 'TEMP' + Math.random().toString().replace(".", "").substr(0, 16);
                this.model.name = 'TEMP' + Math.random().toString().replace(".", "").substr(0, 16);
                if (this.model.autoNumber && ((this.model?.customerReference?.length ?? 0) === 0)){
                    this.model.customerReference = 'TEMP' + Math.random().toString().replace(".", "").substr(0, 16);
                }
                this.model.warehouseID = this.$vm.$appSession.warehouse.id;
                this.model.environmentID = this.$vm.$appSession.environment.id;
                this.insert(this.model).then(() => {
                    if (!(this.errors)) {
                        let _self = this;
                        this.model.code = this.record.code;
                        this.allocateOLD().then((allocResponse) => {
                            if (allocResponse.status === 200) {
                                if (allocResponse.data.Status !== "FAILED") {
                                    _self.get(_self.record.mask).then(() => {
                                        _self.$router.push({ name: _self.router.module + '-edit', params: { mask: _self.record.mask } });
                                        _self.$toast.add({ severity: 'success', summary: 'Record Allocate Success!', detail: 'The record was allocated successfully.', life: 6000 });
                                        _self.resetModelState();
                                        setTimeout(() => _self.$emit("is-reset"), 500);
                                    })
                                }
                                else {
                                    if (_self.record.mask) {
                                        _self.get(_self.record.mask).then(() => {
                                            _self.$router.push({ name: _self.router.module + '-edit', params: { mask: _self.record.mask } });
                                            _self.resetModelState();
                                            setTimeout(() => _self.$emit("is-reset"), 500);
                                        })
                                    }
                                    this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: allocResponse.data.Status, life: 10000 });
                                }
                            }
                        }).catch((err) => {
                            this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 6000 });
                        });
                    }
                    else {
                        for (let err in this.errors) {
                            try {
                                for (let subError in this.errors[err]) {
                                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[err][subError], life: 10000 });
                                }
                            }
                            catch {
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[err], life: 10000 });
                            }
                        }
                    }
                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 10000 });
                });
            },
            manageOrder() {
                let _self = this;
                _self.model.manageProcess = false;
                var warningErrors = "";
                this.manage().then((recordResponse) => {
                    if (recordResponse.status === 200) {
                        if (recordResponse.data.Status === "SUCCESS") {
                            this.model.code = recordResponse?.data?.Response?.TransactionID
                            this.getByCode().then((getResponse) => {
                                if (getResponse.status === 200) {
                                    let recordMask = getResponse.data.result.mask;
                                    this.get(recordMask);
                                    _self.$router.push({ name: _self.router.module + '-edit', params: { mask: recordMask } });
                                    _self.resetModelState();
                                    _self.model.manageProcess = true;
                                    setTimeout(() => _self.$emit("is-reset"), 500);
                                    _self.$toast.add({ severity: 'success', summary: 'Record Create Success!', detail: 'The record was created successfully.', life: 6000 });
                                }
                            });
                        }
                        else {
                            if (recordResponse.data.Errors !== null && recordResponse.data.Errors !== undefined) {
                                (recordResponse.data.Errors ?? []).forEach((x) => {
                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }
                            if (recordResponse.data.Faults !== null && recordResponse.data.Faults !== undefined) {
                                (recordResponse.data.Faults ?? []).forEach((x) => {
                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }

                            if ((warningErrors ?? "").length > 0) {
                                _self.$toast.add({ severity: 'error', summary: 'Create Consignment Failed!', detail: warningErrors, life: 30000 });
                            }
                            _self.model.manageProcess = true;
                        }
                    }
                    else {
                        if (recordResponse.data.Errors !== null && recordResponse.data.Errors !== undefined) {
                            (recordResponse.data.Errors ?? []).forEach((x) => {
                                warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                            });
                        }
                        if (recordResponse.data.Faults !== null && recordResponse.data.Faults !== undefined) {
                            (recordResponse.data.Faults ?? []).forEach((x) => {
                                warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                            });
                        }

                        if ((warningErrors ?? "").length > 0) {
                            _self.$toast.add({ severity: 'error', summary: 'Create Consignment Failed!', detail: warningErrors, life: 30000 });
                        }
                        _self.model.manageProcess = true;
                    }
                }).catch(() => {
                    _self.model.manageProcess = true;
                    //this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 6000 });
                })
            },
            complete() {
                let _self = this;
                _self.model.manageProcess = false;
                this.manage().then((recordResponse) => {
                    if (recordResponse.status === 200) {
                        if (recordResponse.data.Status === "SUCCESS") {
                            this.model.code = recordResponse?.data?.Response?.TransactionID
                        
                            this.allocate().then((allocResponse) => {
                                this.getByCode().then((getResponse) => {
                                    if (getResponse.status === 200) {
                                        let recordMask = getResponse.data.result.mask;
                                        this.get(recordMask);
                                        if (allocResponse.status === 200) {
                                            var warningErrors = "";
                                            if (allocResponse.data.Status !== "FAILED") {
                                                _self.$router.push({ name: _self.router.module + '-edit', params: { mask: recordMask } });
                                                if (allocResponse.data.Errors !== null && allocResponse.data.Errors !== undefined) {
                                                    (allocResponse.data.Errors ?? []).forEach((x) => {
                                                        warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                                    });
                                                }
                                                if (allocResponse.data.Faults !== null && allocResponse.data.Faults !== undefined) {
                                                    (allocResponse.data.Faults ?? []).forEach((x) => {
                                                        warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                                    });
                                                }
                                                
                                                if ((warningErrors ?? "").length > 0) {
                                                    _self.$toast.add({ severity: 'error', summary: 'Allocate Consignment Failed!', detail: warningErrors, life: 20000 });
                                                }
                                                else {
                                                    _self.$toast.add({ severity: 'success', summary: 'Allocate Consignment Success!', detail: 'The record was allocated successfully.', life: 6000 });
                                                }
                                                _self.resetModelState();
                                                setTimeout(() => _self.$emit("is-reset"), 500);
                                            }
                                            else {
                                                try {
                                                    if (allocResponse.data.Errors !== null && allocResponse.data.Errors !== undefined) {
                                                        (allocResponse.data.Errors ?? []).forEach((x) => {
                                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                                        });
                                                    }
                                                    if (allocResponse.data.Warnings !== null && allocResponse.data.Warnings !== undefined) {
                                                        (allocResponse.data.Warnings ?? []).forEach((x) => {
                                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                                        });
                                                    }
                                                    if (allocResponse.data.Faults !== null && allocResponse.data.Faults !== undefined) {
                                                        (allocResponse.data.Faults ?? []).forEach((x) => {
                                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`; 
                                                        });
                                                    }
                                                } catch {/**/ }
                                                
                                                _self.$router.push({ name: _self.router.module + '-edit', params: { mask: recordMask } });
                                                _self.resetModelState();
                                                setTimeout(() => _self.$emit("is-reset"), 500);
                                                this.$toast.add({ severity: 'error', summary: 'Allocate Consignment Failed!', detail: warningErrors ?? allocResponse.data.Errors[0].Description, life: 20000 });
                                            }
                                        }
                                    }
                                });
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Allocate Consignment Failed!', detail: err, life: 20000 });
                            });   
                        }
                        else {
                            let errorMessage = "";
                            if (recordResponse.data.Errors !== null && recordResponse.data.Errors !== undefined) {
                                (recordResponse.data.Errors ?? []).forEach((x) => {
                                    errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }
                            if (recordResponse.data.Faults !== null && recordResponse.data.Faults !== undefined) {
                                (recordResponse.data.Faults ?? []).forEach((x) => {
                                    errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }

                            if ((errorMessage ?? "").length > 0) {
                                _self.$toast.add({ severity: 'error', summary: 'Create Consignment Failed!', detail: errorMessage, life: 30000 });
                            }
                            _self.$router.push(_self.items[_self.items.length - 1].to);
                            _self.model.manageProcess = true;
                        }
                    }
                    else {
                        let errorMessage = "";
                        if (recordResponse.data.Errors !== null && recordResponse.data.Errors !== undefined) {
                            (recordResponse.data.Errors ?? []).forEach((x) => {
                                errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                            });
                        }
                        if (recordResponse.data.Faults !== null && recordResponse.data.Faults !== undefined) {
                            (recordResponse.data.Faults ?? []).forEach((x) => {
                                errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                            });
                        }

                        if ((errorMessage ?? "").length > 0) {
                            _self.$toast.add({ severity: 'error', summary: 'Create Consignment Failed!', detail: errorMessage, life: 30000 });
                        }
                        _self.model.manageProcess = true;
                    }
                }).catch(() => {
                    _self.model.manageProcess = true;
                    //this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 6000 });
                });
            }
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.$nextTick(() => {
                this.model = this.$props.formData;
            });
            this.activity.loading = false;
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ } 
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>