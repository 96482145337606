<template>
    <pv-card>
        <template #content>
            <address-form :formData="model" :recordformData="formData" @is-sync="onSync($event)"></address-form>
        </template>
        <template #footer>
            <div class="p-grid p-nogutter p-justify-between">
                <i></i>
                <pv-button label="Next" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" :disabled="!isValid" />
            </div>

        </template>
    </pv-card>
</template>

<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import addressForm from '@/components/transaction/tms/consignment/address-form.vue';
    //import { max } from 'moment';
    export default {
        name: 'CollectionRecorder',
        mixins: [mixins.ADDRESSES.STEPPER],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'address-form': addressForm
        },
        computed: {
            isValid() {
                return !this.v$.$invalid;
            },
        },
        methods: {
            //created() {
            //    console.log('dumaan1');
            //    this.addressesFormModel = this.$filters.init(this.addressesFormModel);
            //},
            nextPage() {
                if (this.isValid) {
                    this.$nextTick(() => {
                        this.$emit('next-page', { formData: this.model, pageIndex: 0 });
                    })
                }
            },
            prevPage(event) {
                this.$router.push(this.items[event.pageIndex - 1].to);
            } }
        
    }
</script>
