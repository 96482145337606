<template>
    <cosmos-grid id="ConsignmentThreadList" sourceType="url" :source="`/services/tms/consignmentthread?foreignkeyid=${this.formData?.id ?? 0}`" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showOperation="false" :showAdd="false" :showCheckbox="false" :forceRefresh="forceRefresh">
        <pv-grid-column field="CreateDate" header="Action Date">
            <template #body="slotProps">
                <div>{{$filters.toDateFullTimeDisplay(slotProps.data?.createDate)}}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="CreateDate" header="Action By">
            <template #body="slotProps">
                <div>{{ slotProps.data?.createdBy }}</div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="details" header="Details" style="word-break: break-word !important; flex-wrap:wrap;">
            <template #body="slotProps">
               <span style="word-break: break-word !important;" v-html="slotProps.data?.details"></span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="type" header="Activity">
            <template #body="slotProps">
                        {{ slotProps.data?.typeText }}
            </template>
        </pv-grid-column>
    </cosmos-grid>
</template>

<script>
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    export default {
        name: 'ConsignmentThreadTemplate',
        mixins: [mixins.GRID],
        props: {
            formData: { type: Object },
            forceRebindTicker : {type: Number}
        },
        data() {
            return {
                type: -1,
                filters: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["details"],
                forceRefresh: false
              
            }
        },
        watch: {
            'forceRebindTicker': {
                handler() {
                    this.$nextTick(() => {
                        this.forceRefresh = true;
                        setTimeout(() => { this.forceRefresh = false }, 500);
                    });
                }, deep: true
            },
        },
        computed: {},
        methods: {},
        async created() {
        },
        async mounted() {
        }
    }
</script>