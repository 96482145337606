<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <slot name="header">
                <h1><span>CONSIGNMENT REPORT</span></h1>
                <p>{{ this.$props?.subtitle ?? router.subtitle }}</p>
            </slot>
        </div>
    </div>
    <div class="content-section body"> 
        <div class="card">
            <div class="p-d-flex p-grid">
                <div class="p-col-12 p-md-3">
                    <form-lookup id="warehouseID" label="Warehouse" :source="scopeWarehouseDS" :value="model.warehouseID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.warehouseID = $event?.value" />
                    <form-lookup id="customerID" label="Customer" :source="scopeCustomerDS" :value="model.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onCustomer($event)" />
                    <form-lookup id="reportType" label="Export Type" required="true" :hideLabel="false" :source="reportTypeDS" :value="model.reportType" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.reportType = $event?.value" />
                </div>
                <div class="p-col-12 p-md-3">
                    <form-lookup id="serviceProviderID" label="Carrier" :source="serviceProviderDS" :value="model.serviceProviderID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onProvider($event)" />
                    <form-lookup id="serviceProviderAccountID" label="Account" :source="serviceProviderAccountDS" :value="model.serviceProviderAccountID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onAccount($event)" />
                    <form-boolean id="includeArchived" :value="model.includeArchived" type="toggle" label="Include Archived?" textPosition="left" @is-sync="model.includeArchived = $event.value"></form-boolean>
                </div>
                <div class="p-col-12 p-md-3">
                    <form-datetime id="collectionDateFrom" :value="model.collectionDateFromValue" type="date" @is-sync="onCollectionDateFrom($event)" label="Collection Date From" :v$="v$"></form-datetime>
                    <form-datetime id="manifestDateFrom" :value="model.manifestDateFromValue" type="date" @is-sync="onManifestDateFrom($event)" label="Manifest Date From" :v$="v$"></form-datetime>
                    <form-datetime id="dispatchDateFrom" :value="model.dispatchDateFromValue" type="date" @is-sync="onDispatchDateFrom($event)" label="Dispatch Date From" :v$="v$"></form-datetime>
                </div>
                <div class="p-col-12 p-md-3">
                    <form-datetime id="collectionDateTo" :value="model.collectionDateToValue" type="date" @is-sync="onCollectionDateTo($event)" label="Collection Date To" :v$="v$"></form-datetime>
                    <form-datetime id="manifestDateTo" :value="model.manifestDateToValue" type="date" @is-sync="onManifestDateTo($event)" label="Manifest Date To" :v$="v$"></form-datetime>
                    <form-datetime id="dispatchDateTo" :value="model.dispatchDateToValue" type="date" @is-sync="onDispatchDateTo($event)" label="Dispatch Date To" :v$="v$"></form-datetime>
                </div>
            </div>
            
            <div class="p-d-flex p-grid p-col-12 p-jc-end">
                HELLO
                <pv-button class="p-mr-1" label="Inspector Cost" icon="pi pi-search" @click="onInspectorCost()"></pv-button>
                <pv-button class="p-mr-1" label="Cancel" icon="pi pi-refresh" @click="onReset()"></pv-button>
                <pv-button class="p-mr-1" label="Export To Excel" icon="pi pi-excel" @click="onExportToExcel()"></pv-button>
            </div>
            <div class="p-grid">
                <div class="p-col-12">
                    <pv-tab style="margin: 0 -14px !important;">
                        <pv-tabpanel header="RESULT">
                            <report-grid :formData="model"></report-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    const moduleName = 'report';
    import reportGrid from '@/views/report/consignment/report-grid-form.vue';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'report-grid': reportGrid
        },
        data() {
            return {
                model: {
                    warehouseID: 0,
                    customerID: 0,
                    serviceProviderID: 0,
                    serviceProviderAccountID: 0,
                    serviceProviderAccountCode: null,
                    collectionDateFrom: null,
                    collectionDateFromValue: null,
                    collectionDateTo: null,
                    collectionDateToValue: null,
                    manifestDateFrom: null,
                    manifestDateTo: null,
                    manifestDateFromValue: null,
                    manifestDateToValue: null,
                    dispatchDateFrom: null,
                    dispatchDateTo: null,
                    dispatchDateFromValue: null,
                    dispatchDateToValue: null,
                    includeArchived: false,
                    reportType: 200
                },
                activity: {
                    parentLoading: true,
                    loading: false
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                accountDS: [],
                customerAccountDS: []
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            serviceProviderDS() {
                let ds = [];
                if (this.model.customerID > 0) {
                    this.customerAccountDS?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProvider.id)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProvider.id) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                    });
                }
                else {
                    this.serviceAccounts?.data?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProviderID)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProviderID) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }

                    });
                }
                return ds;
            },
            serviceProviderAccountDS() {
                let ds = [];
                if (this.model.serviceProviderID > 0) {
                    if (this.model.customerID > 0) {
                        if (this.accountDS.length > 0) {
                            this.customerAccountDS?.forEach((x) => {
                                if (x.serviceProvider.id === this.model.serviceProviderID) {
                                    let account = this.serviceAccounts?.data?.filter(y => y.id === x.accountDetail.id)[0];
                                    if (ds.length === 0) {
                                        ds.push({ id: account.id, name: '[' + account.accountNumber + '] ' + x.name, code: account.code });
                                    }
                                    else if (ds?.findIndex(y => y.id === x.accountDetail.id) === -1) {
                                        ds.push({ id: account.id, name: '[' + account.accountNumber + '] ' + x.name, code: account.code });
                                    }
                                }
                                
                            });
                        }
                        
                    }
                    else {
                        this.accountDS?.forEach((x) => {
                            //let provider = this.$vm.$providers.find(y => y.id === x.serviceProviderID)
                            if (ds.length === 0) {
                                ds.push({ id: x.id, name: '[' + x.accountNumber + '] ' + x.name, code: x.code });
                            }
                            else if (ds?.findIndex(y => y.id === x.id) === -1) {
                                ds.push({ id: x.id, name: '[' + x.accountNumber + '] ' + x.name, code: x.code });
                            }
                        });
                    }
                    
                }
                return ds;
            },
            reportTypeDS() {
                return [{ id: 100, name : 'Shipper Report' }, { id: 200, name : 'Customer Report' }]
            },
            serviceAccounts() { return this.$store.getters["provideraccount/ds"]; },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.model?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.model.warehouseID)[0]?.code : null,
                        Customer: this.model?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.model.customerID)[0]?.code : null,
                        Account: this.model?.serviceProviderAccountCode,
                        Carrier: this.model?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.model?.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.model?.includeArchived,
                            ExportType: this?.model?.reportType === 100? "AR": "CR",
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.model.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.model.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.model.manifestDateFrom !== undefined && this.model.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.manifestDateTo !== undefined && this.model.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.model.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.model.dispatchDateFrom !== undefined && this.model.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.model.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.model.dispatchDateTo !== undefined && this.model.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.model.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            }
        },
        methods: {
            onProvider(e) {
                this.model.serviceProviderID = e?.value;
                this.getAccounts();
            },
            onAccount(e) {
                this.model.serviceProviderAccountID = e?.value;
                this.model.serviceProviderAccountCode = e?.record?.code;
            },
            onCustomer(e) {
                this.model.customerID = e?.value;
                this.getCustomerAccounts();
            },
            onCollectionDateFrom(e) {
                this.$nextTick(() => {
                    this.model.collectionDateFromValue = e.value;
                    this.model.collectionDateFrom = (e.record.data ?? new Date());
                });
            },
            onCollectionDateTo(e) {
                this.$nextTick(() => {
                    this.model.collectionDateToValue = e.value;
                    this.model.collectionDateTo = (e.record.data ?? new Date());
                });
            },
            onManifestDateFrom(e) {
                this.$nextTick(() => {
                    this.model.manifestFromValue = e.value;
                    this.model.manifestDateFrom = e.record.data;
                });
            },
            onManifestDateTo(e) {
                this.$nextTick(() => {
                    this.model.manifestDateToValue = e.value;
                    this.model.manifestDateTo = e.record.data;
                });
            },
            onDispatchDateFrom(e) {
                this.$nextTick(() => {
                    this.model.dispatchFromValue = e.value;
                    this.model.dispatchDateFrom = e.record.data;
                });
            },
            onDispatchDateTo(e) {
                this.$nextTick(() => {
                    this.model.dispatchDateToValue = e.value;
                    this.model.dispatchDateTo = e.record.data;
                });
            },
            onInspectorCost() {
                this.$toast.add({ severity: 'warn', summary: 'UNDERDEVELOPMENT!', detail: 'Module is on development', life: 8000 });
            },
            onReset() {
                this.$toast.add({ severity: 'warn', summary: 'UNDERDEVELOPMENT!', detail: 'Module is on development', life: 8000 });
            },
            onExportToExcel() {
                this.$axios.post(this.$config.config.endpoint.api + `/services/report/consignment/export`.replaceAll("//", "/"), this.apiData)
                    .then((response) => {
                        this.$config.common.$downloadFile(response.data.result.base64File, response.data.result.contentType, response.data.result.fileName);
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            async getProviderAccounts() {
                await this.$store.dispatch("provideraccount/getAll", { sort: null, where: null }).then(() => {
                });
            },
            async getAccounts() {
                if (this.model.serviceProviderID > 0) {
                    await this.$store.dispatch("provideraccount/getURL", { url: `/services/admin/serviceprovideraccount/byprovider/id/${this.model.serviceProviderID}` }).then((response) => {
                        this.accountDS = response.result;
                    });
                }
                else {
                    this.accountDS = [];
                }
            },
            async getCustomerAccounts() {
                if (this.model.customerID > 0) {
                    await this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.model.customerID}` }).then((response) => {
                        this.customerAccountDS = response.result;
                    });
                }
                else {
                    this.customerAccountDS = [];
                }
            }
        
        },
        async created() {
            this.loading = false;
            let startDate = new Date();
            startDate.setDate(1);
            this.model.collectionDateFromValue = startDate;
            this.model.collectionDateToValue = new Date();
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
            this.model.reportType = 200;
            let startDate = new Date();
            startDate.setDate(1);
            this.model.collectionDateFromValue = startDate;
            this.model.collectionDateToValue = new Date();
            this.getProviderAccounts();
            
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
</style>
