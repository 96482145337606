<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-8" style="position: relative; padding: 1rem 1rem 0 2rem;">
                    <p>Please ensure that you have mapped all required and conditional fields. <span class="is-text-danger" style="font-weight: bold;" v-if="!completed">({{ notYetMapped.length }} {{ $filters.quantifiedDisplay("fields", notYetMapped.length) }} left)</span></p>
                    <ol style="list-style: none; padding: 0; margin-bottom: 0;">
                        <li v-for="groupings in sources.groupNames" :key="groupings.groupName" class="field-groupname">
                            <h3 style="font-size: 1.2rem; margin: 1rem 0 0.5rem 0;">{{ groupings.groupName }}</h3>
                            <ol style="list-style: none; padding: 0;">
                                <li v-for="field in fields.filter(x => x.groupName === groupings.groupName && (!viewOnly ? x.code !== 'CUSTCODE' : true))" :key="field.id">
                                    <div class="p-d-flex p-flex-column p-flex-lg-row">
                                        <div :class="[{'field-required': field?.configuration?.required }, {'field-conditional': field?.configuration?.conditional }]" style="min-width: 25rem; padding: 0.5rem 0.75rem; border: 1px solid lightgray; margin-bottom: 3px; background: var(--surface-d); font-weight: bold;">{{ field.headerName }}</div>
                                        <div :class="[{'full-w': true}, {'field-general': true}, {'field-conditional': field?.configuration?.conditional || (field?.configuration?.required && sourceDefaults.filter(y => y.defaultID == defaults.filter(x => x.fieldID === field.id)[0]?.id)[0]?.value)}, {'field-required': field?.configuration?.required }]" style="width: 100%; border: 1px solid lightgray; margin-bottom: 3px;" @drop="onItemDrop($event, field.id, field.headerName)" @dragover.prevent @dragenter.prevent>
                                            <div v-if="model.filter(x => x.fieldID === field.id)[0]?.headerName" style="padding: 0.5rem 0.75rem; background-color: lightgoldenrodyellow; color: black; width: 100%; font-style: normal !important; ">
                                                <div class="p-d-flex p-jc-between">
                                                    <div class="flex flex-1" style="font-weight: bold;">{{model.filter(x => x.fieldID === field.id)[0]?.headerName}}</div>
                                                    <div class="flex flex-1"><i v-if="!viewOnly" class="fa fa-trash fa-fw" @click="onItemRemove(field.id)"></i></div>
                                                </div>
                                            </div>
                                            <div v-else style="padding: 0.5rem 0.75rem; font-family: 'Roboto Mono', monospace; font-size: 0.8rem; opacity: 0.7; color: var(--gray-500);">
                                                Drop The Template Field Here
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="field?.configuration?.detail || getDefaultValue(field.id)" class="p-d-flex p-flex-column p-flex-lg-row">
                                        <div style="min-width: 25rem; font-size: 0.7rem; font-weight: bold; display: block; color: var(--text-info) ">
                                            <div class="p-d-flex">
                                                <div style="width: 7rem; color: var(--text-color); font-family: 'Roboto Mono', monospace; padding-left: 1rem; font-weight: bold; ">DEFAULT:</div>
                                                <div style="width: 100%; text-align: right; font-family: 'Roboto Mono', monospace; font-size: 0.7rem; ">{{ getDefaultValue(field.id) }}</div>
                                            </div>
                                        </div>
                                        <div class="full-w" style="font-size: 0.7rem; font-weight: bold; padding: 0 0.5rem; font-family: 'Roboto Mono', monospace; text-align: right; display: block; color: var(--gray-500)"> {{field?.configuration?.detail ?? `The field '${field.headerName}' can be mapped optionally as default values are already configured.` }}</div>
                                    </div>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div class="p-col-12 p-md-4" style="position: relative; padding: 1rem 1rem 1rem 2rem;">
                    <h3 style="margin: 1rem 0 0.5rem 0;">USE DEFAULT TEMPLATE</h3>
                    <a :href="`https://cdn.integro360.com/cosmos/common/documents/templates/import/${template + (viewOnly ? '_GENERIC' : '')}.xlsx?${Math.random()}`" target="_blank" class="p-button p-component" style="margin-right: 0.25rem; margin-bottom: 0.25rem;"><i class="fa fa-download fa-fw" style="margin-right: 0.25rem;"></i> Download Template</a>
                    <pv-button v-if="!viewOnly" label="Load Default Template" icon="fa fa-list fa-fw" style="margin-right: 0.25rem; margin-bottom: 0.25rem;" @click="onLoadDefault(fields.filter(x => x.code !== 'CUSTCODE'))" />
                    <h3 v-if="!viewOnly" style="margin: 1rem 0 0.5rem 0;">USE CUSTOM TEMPLATE</h3>
                    <pv-file v-if="!viewOnly"
                             id="customTemplate"
                             ref="customTemplate"
                             name="files"
                             mode="basic"
                             chooseIcon="fa fa-upload fa-fw"
                             chooseLabel="Upload Template"
                             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                             auto="true"
                             :url="uploadURL"
                             :multiple="false"
                             :maxFileSize="maxFileSizeInBytes"
                             :customUpload="true"
                             @uploader="onTemplateUpload" class="p-button" style="margin-right: 0.25rem; margin-bottom: 0.25rem;" />
                    <h3 v-if="!viewOnly" style="margin: 1rem 0 0 0; position: sticky !important; top: 80px;">TEMPLATE FIELDS</h3>
                    <p v-if="!viewOnly" style="position: sticky !important; top: calc(80px + 1.75rem); margin: 0; padding: 0; ">Upload your template or load the template field.</p>
                    <ol v-if="sources.headers.length > 0 && !viewOnly" style="list-style: none; margin: 0; padding: 0.5rem; background: var(--surface-b); max-height: min(800px, 70vh); overflow-y: scroll; position: sticky !important; top: calc(80px + 3.25rem);">
                        <li v-for="header in sources.headers" :key="header.headerName" style="opacity: 1 !important; padding: 0.5rem 0.75rem; border: 1px solid lightgray; font-weight: bold; margin-bottom: 0.1rem; background-color: var(--surface-d); box-shadow: unset !important; " class="drag-el" draggable="true" @dragstart="onItemDrag($event, header)">
                            <i class="fa fa-input-text fa-fw" style="margin-right: 0.5rem"></i> {{ header?.headerName }}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- Dialog Boxes -->
    <keep-alive>
        <is-dialog header="Import Field Mapping" :visible="dialog.field" @is-confirm="onClearMapping(sources.fields)" @is-cancel="onLoadTemplate(sources.fields)" confirm="Clear Existing" cancel="Retain Existing">
            <div class="confirmation-content p-d-flex">
                <div>
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                </div>
                <div>
                    <span>There is an exising field mapping that may have conflict on the newly loaded template<br /><br />Before we load the new template fields, what do you want to do on the existing mapping?</span>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <keep-alive>
        <is-dialog header="Replace Field Mapping" :visible="dialog.replace" @is-confirm="onItemReplace(temporary)" @is-cancel="dialog.replace = false">
            <div class="confirmation-content p-d-flex">
                <div>
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                </div>
                <div>
                    <span>The property <b>[{{temporary?.targetHeader}}]</b> is currently mapped to <b>{{ temporary?.item?.header?.headerName }}</b>!!!<br /><br />Do you want to replace the current mapping with <b>{{ temporary?.header?.headerName }}</b>?</span>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    export default {
        name: 'ImportProfileMappings',
        props: {
            referentials: { type: Object },
            source: { type: Array, default: () => [] },
            sourceDefaults: { type: Array, default: () => [] },
            fields: { type: Array, default: () => [] },
            defaults: { type: Array, default: () => [] },
            template: { type: String, default: "template_CONSIGNMENT" },
            viewOnly: { type: Boolean, default: false },
            v$: { type: Object }
        },
        data() {
            return {
                chooseLabel: "Upload Template",
                maxFileSizeInMB: 10,
                accept: null,
                dialog: {
                    field: false,
                    replace: false
                },
                sources: {
                    warehouses: [],
                    servicegroups: [],
                    groupNames: [],
                    headers: [],
                    files: [],
                    fields: []
                },
                temporary: {
                    headerName: null,
                    event: null,
                    targetFieldID: null,
                    targetHeader: null,
                    headerIndex: -1,
                    header: null,
                    itemIndex: -1,
                    item: null
                }
            }
        },
        computed: {
            existingMapping() { return this.source.filter(x => x.header !== null || x.headerName != null).map(x => x.header); },
            uploadURL() { return `${config.config.endpoint.api}/file/upload/temp`; },
            columnURL() { return `${config.config.endpoint.api}/services/facility/importprofile/upload`; },
            properties() { return this.fields.filter(x => x.configuration &&  (x.configuration.required || x.configuration.conditional) && x.code !== "CUSTCODE"); },
            notYetMapped() {
                return this.fields.filter(x => x.configuration && (x.configuration.required || x.configuration.conditional)
                    && x.code !== "CUSTCODE"
                    && this.source.filter(a => a.headerName).map(y => y.fieldID).filter(z => z === x.id).length === 0).map(x => x.headerName);
            },
            completed() {
                return this.notYetMapped.length === 0;
            }
        },
        watch: {
            source: {
                handler() { this.model = this.source; },
                deep: true
            },
            fields: {
                handler() { this.generateGroupNames(); },
                deep: true
            }
        },
        methods: {
            generateGroupNames() {
                this.sources.groupNames = [];
                let groupName = '';
                this.fields.filter(x => x.code !== "CUSTCODE").forEach(x => {
                    if (x.groupName !== groupName) {
                        this.sources.groupNames.push({ groupName: x.groupName, column: x.displayColumn });
                        groupName = x.groupName;
                    }
                });
            },
            onLoadDefault(fields) {
                if (this.existingMapping.length > 0) {
                    this.sources.fields = fields;
                    this.dialog.field = true;
                }
                else
                    this.onLoadTemplate(fields);
            },
            onLoadTemplate(fields)
            {
                this.sources.headers = [];
                (fields ?? []).filter(x => this.existingMapping.findIndex(m => m !== undefined && m?.headerName === x.headerName) == -1)
                    .forEach(x => this.sources.headers.push({ headerName: x.headerName }));
                this.dialog.field = false;
            },
            onClearMapping(fields) {
                this.model.forEach(x => {
                    x.header = null;
                    x.headerName = null;
                });
                this.$emit("is-sync", this.model);
                this.onLoadTemplate(fields);
            },
            onItemDrag(event, header) {
                event.dataTransfer.dropEffect = 'move';
                event.dataTransfer.effectAllowed = 'move';
                event.dataTransfer.setData('headerName', header.headerName);
            },
            onItemDrop(event, targetFieldID, targetHeader) {
                // Retrieve data from the drag and drop event
                const headerName = event.dataTransfer.getData("headerName");
                // Retrieve header data
                const headerIndex = this.sources.headers.findIndex((item) => item.headerName === headerName);
                const header = this.sources.headers[headerIndex];
                // Check if there is an exising item with the same header name
                let itemIndex = this.model.findIndex((item) => item?.fieldID === targetFieldID);
                if (itemIndex === -1) {
                    this.model.push({ id: 0, fieldID: targetFieldID, headerName: null });
                    itemIndex = this.model.findIndex((item) => item?.fieldID === targetFieldID);
                }
                let item = this.model[itemIndex];
                // Check if the item has exising mapping.
                if (item.header === null || item.header === undefined)
                    this.onItemAssign(item, itemIndex, header, headerIndex);
                else {
                    this.temporary = { headerName, event, targetFieldID, targetHeader, headerIndex, header, itemIndex, item };
                    this.dialog.replace = true;
                }
            },
            onItemAssign(item, itemIndex, header, headerIndex) {
                if (itemIndex === -1)
                    this.model.push({ id: 0, fieldID: item.fieldID, headerName: header.headerName, header: header });
                else {
                    item.headerName = header.headerName;
                    item.header = header;
                }
                this.sources.headers.splice(headerIndex, 1);
                this.$emit("is-sync", this.model);
                this.v$.model.code.$touch();
            },
            onItemRemove(targetFieldID) {
                // Retrieve the model who holds the mapping
                const targetIndex = this.model.findIndex((item) => item.fieldID == targetFieldID);
                var header = this.model[targetIndex].header;
                // Remove the header assignment on the model
                this.model[targetIndex].header = null;
                this.model[targetIndex].headerName = null;
                // Check if the header already exists
                const sourceIndex = this.sources.headers.findIndex((item) => item.headerName == header.headerName);
                if (sourceIndex == -1)
                    this.sources.headers.push(header);
                this.$emit("is-sync", this.model);
                this.v$.model.code.$touch();
            },
            onItemReplace(data) {
                this.onItemRemove(data.targetFieldID);
                this.onItemAssign(data.item, data.itemIndex, data.header, data.headerIndex);
                this.dialog.replace = false;
                this.temporary = { headerName: null, event: null, targetFieldID: null, targetHeader: null, headerIndex: -1, header: null, itemIndex: -1, item: null };
            },
            onIconCorrection() {
                var fileUpload = document.querySelector(".p-fileupload-choose.p-button");
                var icon = fileUpload.querySelector(".p-button-icon.pi");
                if (icon) {
                    icon.classList.remove("pi")
                    icon.classList.remove("pi-plus");
                    icon.classList.add("fa");
                    icon.classList.add("fa-upload");
                    setTimeout(this.onIconCorrection(), 100);
                }
            },
            onTemplateUpload(event) {
                var formData = new FormData();
                formData.append("image", event.files[0]);
                this.$axios.post(this.uploadURL, formData, { headers: { 'Content-Type': "multipart/form-data" } }).then((file) => {
                    if ((file.data.errors ?? []).length > 0)
                        this.$toast.add({ severity: 'error', summary: 'File Validation Error', detail: file.data.errors[0].general/*, life: 3000*/ });
                    else {
                        this.sources.files[0] = file.data;
                        this.$axios.post(this.columnURL, file.data).then((mapping) => {
                            this.onLoadDefault(mapping.data);
                        });
                    }
                })
                event = null;
                this.$refs["customTemplate"].files = null;
            },
            getDefaultValue(id) {
                var field = this.defaults.filter(x => x.fieldID === id)[0];
                var value = this.sourceDefaults.filter(y => y.defaultID == field?.id)[0]?.value;
                if (field) {
                    var configuration = field.configurationDetail;
                    if (configuration.lookupSetting != null) {
                        let setting = configuration.lookupSetting;
                        switch (setting.lookupType.toLowerCase()) {
                            case "array":
                                return this.referentials[setting.lookupSource].filter(x => x.id === Number(value))[0]?.code;
                            case "enum":
                                return this.$vm.$enums[setting.lookupSource].filter(x => x.id === Number(value))[0]?.code;
                            case "dropdown":
                                return this.$vm["$" + this.$filters.pluralize(setting.lookupSource)].filter(x => x.id === Number(value))[0]?.code;
                        }
                    }
                    else if (configuration.booleanSetting != null)
                        return value ? "Y" : "N";
                    else if (configuration.decimalSetting != null)
                        return this.$filters.formatNumber(value, field.configurationDetail.decimalSetting.decimalPlaces);
                    else if (configuration.textSetting != null)
                        return value;
                }
            },
            onDefaultValue(field, value) {
                if (field.configurationDetail.lookupSetting != null) {
                    let setting = field.configurationDetail.lookupSetting;
                    switch (setting.lookupType.toLowerCase()) {
                        case "array":
                            return this.referentials[setting.lookupSource].filter(x => x.id === Number(value))[0]?.code;
                        case "enum":
                            return this.$vm.$enums[setting.lookupSource].filter(x => x.id === Number(value))[0]?.code;
                        case "dropdown":
                            return this.$vm["$" + this.$filters.pluralize(setting.lookupSource)].filter(x => x.id === Number(value))[0]?.code;
                    }
                }
                if (field.configurationDetail.booleanSetting != null)
                    return value ? "Y" : "N";
                if (field.configurationDetail.textSetting != null)
                    return value;
                 if (field.configurationDetail.decimalSetting != null)
                     return this.$filters.formatNumber(value, field.configurationDetail.decimalSetting.decimalPlaces);
            }
        },
        created() {
            this.generateGroupNames();
            this.model = this.source ?? [];
        },
        mounted() {
            setTimeout(() => {
                this.onIconCorrection();
            }, 0);
        }
    }
</script>
<style>
    .field-general {
        background: var(--surface-f);
        color: var(--text-color);
        font-style: italic;
    }
    .field-required {
        background: #fff0f3;
        color: var(--text-danger);
    }
    .field-conditional {
        background: #e6f6fe;
        color: var(--text-info);
    }
</style>