<template>
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-md-4">
            <form-input id="code" label="Transaction ID" :v$="v$" :value="model.code" :disabled="true"  />
            <form-input id="customerReference" label="Reference 1" :required="true" :v$="v$" vID="customerReference" :value="model.customerReference" @is-sync="model.customerReference = $event.value; this.v$.$touch();" v-focus :max-length="20"/>
            <form-input id="customerReference2" label="Reference 2" :v$="v$" vID="customerReference2"  :value="model.customerReference2" @is-sync="model.customerReference2 = $event.value"  :max-length="20"/>
            <form-input id="customerReference3" label="Reference 3" :v$="v$" vID="customerReference3" :value="model.customerReference3" @is-sync="model.customerReference3 = $event.value"  :max-length="20"/>
            <form-input id="customerReference4" label="Reference 4" :v$="v$" vID="customerReference4" :value="model.customerReference4" @is-sync="model.customerReference4 = $event.value"  :max-length="50"/>
            <form-input id="customerReference5" label="Reference 5" :v$="v$" vID="customerReference5" :value="model.customerReference5" @is-sync="model.customerReference5 = $event.value" :max-length="50"/>
            <form-input id="customerReference6" label="Reference 6" :v$="v$" vID="customerReference6" :value="model.customerReference6" @is-sync="model.customerReference6 = $event.value" :max-length="50"/>
            <form-input id="customerReference7" label="Reference 7" :v$="v$" vID="customerReference7" :value="model.customerReference7" @is-sync="model.customerReference7 = $event.value" :max-length="50"/>
        </div>
        <div class="p-col-12 p-md-4">
            <form-input id="deliveryInstruction" label="Delivery Instruction" :v$="v$"  vID="deliveryInstruction" :value="model.deliveryInstruction" @is-sync="model.deliveryInstruction = $event.value" :max-length="1000"/>
            <form-input id="safePlace" label="Safe Place" :v$="v$" :value="model.safePlace"  vID="safePlace" @is-sync="model.safePlace = $event.value" />
            <form-lookup id="packageType" :v$="v$"  vID="packageType" :required="true" type="enum" source="serviceproviderproductpackagingtype" :value="model.packageType" label="Package Type" @is-sync="model.packageType = $event.value" :isInsured="v$"></form-lookup>
            <form-lookup id="currencyID" :v$="v$"  vID="currencyID" :required="true" label="Currency" source="currency" :value="model.currencyID" @is-sync="model.currencyID = $event?.value ?? model.currencyID ;" />
            <form-lookup id="weightUnit" :v$="v$"  vID="weightUnit" :required="true" type="enum" source="weightunit" :value="model.weightUnit" label="Unit Weight" @is-sync="model.weightUnit = $event.value" ></form-lookup>
            <form-lookup id="dimensionUnit" :v$="v$"  vID="dimensionUnit" :required="true" type="enum" source="lengthunit" :value="model.dimensionUnit" label="Unit Length" @is-sync="model.dimensionUnit = $event.value" :isInsured="v$"></form-lookup>
            <form-lookup type="flag" :value="model.notification" :v$="v$"  vID="customerReference" id="notification" label="Notification" :sorted=false :source="'notificationtype'" @is-sync="model.notification = $event.value"></form-lookup>
        </div>
        <div class="p-col-12 p-md-4">
            <!--<form-boolean id="isInsured" :value="model.isInsured" type="toggle" label="Consignment is Insured?" textPosition="left" @is-sync="model.isInsured = $event.value"></form-boolean>-->
            <form-boolean id="isReturnable" :v$="v$"  vID="isReturnable" :value="model.isReturnable" type="toggle" label="Consignment is Returnable?" textPosition="left" @is-sync="model.isReturnable = $event.value"></form-boolean>
            <!--<form-boolean id="pushReturns" :value="model.pushReturns" type="toggle" label="Push Return Entry?" textPosition="left" @is-sync="model.pushReturns = $event.value"></form-boolean>-->
            <form-boolean id="isHazardous" :v$="v$"  vID="isHazardous" :value="model.isHazardous" type="toggle" label="Contains Dangerous Goods?" textPosition="left" @is-sync="model.isHazardous = $event.value"></form-boolean>
            <form-input v-if="model.isHazardous" :v$="v$"  vID="dG_EmergencyContact" id="dG_EmergencyContact" :required="model.isHazardous" label="Emergency Contact"  :value="model.dG_EmergencyContact" @is-sync="model.dG_EmergencyContact = $event.value" />
            <form-input v-if="model.isHazardous" :v$="v$"  vID="dG_EmergencyTelephone" id="dG_EmergencyTelephone" :required="model.isHazardous" label="Emergency Telephone"  :value="model.dG_EmergencyTelephone" @is-sync="model.dG_EmergencyTelephone = $event.value" :max-length="25" />
        </div>
        
    </div>
    <div class="p-fluid p-grid">
        <div class="p-col-12">
            <package-form :parentID="model?.id" :packageSource="model?.packages ?? []" :currencyID="model?.currencyID" :weightUnit="model.weightUnit" :dimensionUnit="model.dimensionUnit"  :defaultPackageContent="companySettingRecord?.default_ParcelContent" :defaultDeclaredValue="companySettingRecord?.default_DeclaredValue" :defaultPackageWeight="companySettingRecord?.default_Weight" :defaultPackageLength="companySettingRecord?.default_Length" :defaultPackageWidth="companySettingRecord?.default_Width" :defaultPackageHeight="companySettingRecord?.default_Height"   :defaultRegulatedCode="companySettingRecord?.default_DGRegulatedCode" :isHazardous="model.isHazardous" :countryID="model?.shipFrom_CountryID" :formConsignment="model" @is-source="onPackageSync($event)"> </package-form>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    import packageForm from '@/components/transaction/tms/consignment/record/package-formnew.vue';
    import { required, maxLength, minLength, helpers } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: 'GuideTemplate',
        emits: ["is-sync"],
        props: {
            formData: {type: Object}
        },
        components: {
            'package-form': packageForm
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: {  
                }
            }
        },
        validations() {
            let validator = {
                model: {
                    customerReference: { $autoDirty: true },
                    customerReference2: { $autoDirty: true },
                    customerReference3: { $autoDirty: true },
                    customerReference4: { $autoDirty: true },
                    customerReference5: { $autoDirty: true },
                    customerReference6: { $autoDirty: true },
                    customerReference7: { $autoDirty: true },
                    deliveryInstruction: { $autoDirty: true },
                    safePlace: { $autoDirty: true },
                    packageType: { $autoDirty: true },
                    currencyID: { $autoDirty: true },
                    weightUnit: { $autoDirty: true },
                    dimensionUnit: { $autoDirty: true },
                    notification: { $autoDirty: true },
                    isReturnable: { $autoDirty: true },
                    isHazardous: { $autoDirty: true },
                    dG_EmergencyContact: { $autoDirty: true },
                    dG_EmergencyTelephone: { $autoDirty: true },
                }
            }

            //if ((this.model.customerReference?.length ?? 0) > 0)
            //    validator.model.customerReference = { maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | - `, regex('^[a-zA-Z0-9#_./\\|-]{0,20}$')) };
            //if ((this.model.autoNumber ?? false))
                validator.model.customerReference = { required, maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | - `, regex('^[a-zA-Z0-9#_./\\|-]{1,20}$')) };
            if ((this.model.customerReference2?.length ?? 0) > 0)
                validator.model.customerReference2 = { maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | -`, regex('^[a-zA-Z0-9#_./\\|-]{0,20}$')) };
            if ((this.model.customerReference3?.length ?? 0) > 0)
                validator.model.customerReference3 = { maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | -`, regex('^[a-zA-Z0-9#_./\\|-]{0,20}$')) };
            if (this.model.isHazardous ?? false) {
                validator.model.dG_EmergencyContact = { required, maxLength: maxLength(35), $autoDirty: true };
                validator.model.dG_EmergencyTelephone = { required, maxLength: maxLength(25), $autoDirty: true};
                if ((this.model.dG_EmergencyTelephone?.length ?? 0) > 0)
                    validator.model.dG_EmergencyTelephone = { required, maxLength: maxLength(25), minLength: minLength(7), $autoDirty: true, regex: helpers.withMessage(`Invalid Telephone format. It must be a numeric string with at least one non-zero character and a length between 7 and 25 characters.`, regex('^(?!0+$)[0-9]{7,25}$')) }
            }
            return validator;
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            companySettingRecord() {
                return this.$store.getters["companygeneralsetting/record"];
            },
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID, (this.session?.warehouseID ?? this.$vm.$appSession?.warehouse?.id), this.user) ?? [];
            },
            customer() {
                return this.scopeCustomerDS.filter(x => x.id == (this.model?.customerID ?? this.$props?.formData?.customerID ?? this.$props?.recordformData?.customerID))[0];
            },
        },
        methods: {
            onPackageSync(e) {
                this.model.packages = e.data ?? [];
            },
            async getSetting(mask) {
                if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null)
                    return this.$store.dispatch("companygeneralsetting/getURL", { url: `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this.customer?.mask)}`.replaceAll("//", "/") });
            },
        },
        mounted() {
            this.model = this.$props.formData;
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            setTimeout(() => {
                this.getSetting();
            },1000)

        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>