<template>
    <div>
        <cosmos-grid :id="`MarkupAccountGrid_${parentID}`" :multiLevel="true" :source="source" sourceType="array" :showOperation="false">
            <pv-grid-column field="customerAccountDetail.name" header="Provider Name" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
            <pv-grid-column field="customerDetail.code" header="Account Details" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div>[<b>{{ slotProps.data.customerAccountDetail?.code }}</b>] {{ slotProps.data.accountDetail?.name}}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="accountDetail.altName" header="Account Number" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;"></pv-grid-column>
            <pv-grid-column field="countryDetail.code" header="Country" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div><i :class="`flag flag-${slotProps.data.countryDetail?.code.toLowerCase()}`"></i> [<b>{{ slotProps.data.countryDetail?.code }}</b>] {{ slotProps.data.countryDetail?.name}}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="customerDetail.code" header="Account Currency" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div>[<b>{{ slotProps.data.currencyDetail?.code }}</b>] {{ slotProps.data.currencyDetail?.name }} ({{slotProps.data.currencyDetail?.altName}})</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="useProviderRate" header="Rate Applied Type" headerStyle="width: 7rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.rateCardType" style="width: 10rem !important; text-align: center;" :class="`p-indicator definite-${(slotProps.data.rateCardType == 100 ? 'DOCUMENT' : 'NON-DOCUMENT')}`">{{ $vm.$enums.ratecardtype.filter(x => x.id === (slotProps.data.rateCardType ??  parentRateCardType))[0]?.text }}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="rateApplied" header="Markup Rate" headerStyle="width: 10rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em; text-align: right;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.rateCardType === 100" style="font-weight: 700">{{ slotProps.data.rateType === 200 ? slotProps.data.rateApplied.toFixed(2) : $filters.formatCurrency(slotProps.data.rateApplied, slotProps.data.currencyDetail.code, slotProps.data.countryDetail.code) }}{{ slotProps.data.rateType === 200 ? '%' : ''}}</div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="mask" :headerStyle="`min-width: 10.5rem; max-width: 10.5rem; width: 10.5rem`" style="border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex" style="width: 2rem;">
                        <div class="p-col">
                            <pv-button v-if="!slotProps.data.useProviderRate" icon="pi pi-book" iconPos="right" :loading="loading" @click="onCreateRule(slotProps.data)" title="Rules and Conditions" />
                            <div v-else style="min-width: 2.25rem;">&nbsp;</div>
                        </div>
                        <div class="p-col">
                            <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(slotProps.data)" title="Edit Record" />
                        </div>
                        <div class="p-col">
                            <pv-button :disabled="slotProps.data.recordStatus !== 0" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(slotProps.data)" title="Delete Record" />
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <template #expansion="slotProps">
                <markup-rules-grid v-if="!slotProps.data.useProviderRate" :parentID="slotProps.data.id" :accountID="slotProps.data.customerAccountID" :loadKey="action.reloadKey" :currencyCode="slotProps.data.currencyDetail?.code" :countryCode="slotProps.data.countryDetail?.code" />
                <div v-else style="background-color: var(--bg-warning); color: var(--gray-800)">
                    <div class="p-d-flex">
                        <div style="padding: 0.5rem;">
                            <i class="fa fa-info-circle fa-2x"></i>
                        </div>
                        <div style="padding: 0.75rem 0; font-size: 1.1rem;">
                            The carrier account's rate card type is <strong>PROVIDER</strong>, product level and rule based markup is not available. If you have previous configurations, all settings will be disabled and will be available once the rate card type is set to <strong>MARKUP</strong> again.
                        </div>
                    </div>
                </div>
            </template>
            <!-- Templates -->
            <template #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="(model?.id ?? 0) === 0 && accounts.length > 0" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </template>
        </cosmos-grid>
        <!-- Operation Dialogs-->
        <!-- Delete Dialog-->
        <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirmDelete(model?.mask)" @is-cancel="onCancel()">
            <div class="confirmation-content p-d-flex">
                <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
                <div>
                    You are about to delete this record:
                    <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                        <tr>
                            <th style="text-align:left;">Account Detail</th>
                            <td>:</td>
                            <td> <b>[{{ this.model?.customerAccountDetail?.code }}]</b> {{ this.model?.customerAccountDetail?.name }}</td>
                        </tr>
                    </table>
                    Please be aware of the following:
                    <ul>
                        <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                        <li>For records that has activity, the record will only be disabled and archived.</li>
                        <li>Also deleting a record may be irreversible action.</li>
                    </ul>
                    Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
        <!-- Create Dialog-->
        <is-dialog :header="(model?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" style="width: 40vw; min-width: 400px;" :visible="dialog.edit" @is-confirm="onConfirmEdit(model)" @is-cancel="onCancel()" :confirmDisabled="confirmDisabled">
            <div class="confirmation-content p-d-flex">
                <div class="p-col p-md-12">
                    <form-lookup id="account" :disabled="(this.model?.id ?? 0 ) > 0" sourceType="array" :source="accounts" :value="model.customerAccountID" @is-sync="{ model.customerAccountID = $event.value; model.accountID = $event.record?.accountDetail?.id; }" :required="true" :show-copy="false" />
                    <form-lookup id="rateCardType" type="enum" source="ratecardtype" :value="model.rateCardType" @is-sync="model.rateCardType = $event?.value" />
                    <form-lookup v-if="model.rateCardType === 100" id="markupType" type="enum" source="ratetype" :value="model.rateType" @is-sync="model.rateType = $event.value" :required="true" />
                    <form-numeric v-if="model.rateCardType === 100" id="rateApplied" :value="model.rateApplied" @is-sync="model.rateApplied = $event.value" :type="model.rateType ===100 ? 'currency' : 'decimal'" :currency="model.currencyDetail?.code" :suffix="suffix" :required="true" />
                </div>
            </div>
        </is-dialog>
        <!-- Create Rule Dialog-->
        <is-dialog :header="(ruleModel?.id ?? 0) === 0 ? 'Add New Record' : 'Edit Record'" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" :visible="dialog.rule" @is-confirm="onConfirmRule(ruleModel)" @is-cancel="onCancel()" :confirmDisabled="false">
            <div class="confirmation-content p-d-flex" style="margin: 0; padding: 0;">
                <div class="p-col p-md-12" style="padding: 0; margin: 0;">
                    <markup-ruledetail-form :parentID="ruleModel?.markupContractAccountID"
                                            :accountID="ruleModel?.customerAccountDetailID"
                                            :currencyCode="ruleModel?.currencyCode"
                                            :countryCode="ruleModel?.countryCode"
                                            :providerID ="ruleModel?.providerID"
                                            :currencyID ="ruleModel?.currencyID"
                                            :countryID="ruleModel?.countryID"
                                            @is-sync="this.ruleModel = $event" />
                </div>
            </div>
        </is-dialog>
    </div>
</template>
<script>
    import markupruledetailform from '@/components/facility/rates/markup/markup-ruledetail-form';
    import markuprulesgrid from '@/components/facility/rates/markup/markup-rules-grid';
    export default {
        name: 'MarkupAccountGrid',
        components: {
            'markup-ruledetail-form': markupruledetailform,
            'markup-rules-grid': markuprulesgrid
        },
        props: {
            parentID: { type: Number }, // Markup Customer ID
            customerID: { type: Number }, // Customer ID
            parentRateCardType: { type: Number }
        },
        data() {
            return {
                model: null,
                ruleModel: null,
                action: {
                    reloadKey: null
                },
                dialog: {
                    delete: false,
                    edit: false,
                    rule: false
                },
                sources: {
                    accounts: [],
                    grid: []
                }
            }
        },
        computed: {
            accounts() {
                if ((this.model?.id ?? 0) > 0)
                    return this.sources.accounts;
                return this.sources.accounts.filter(x => this.sources.grid.findIndex(y => y.customerAccountID === x.id) === -1);
            },
            source() {
                return this.sources.grid ?? [];
            },
            suffix() {
                return this.model.rateType === 200 ? '%' : '';
            }
        },
        methods: {
            onCreate() {
                this.model = {
                    accountID: this.accountID,
                    customerAccountID: null,
                    markupCustomerID: this.parentID,
                    rateType: 200,
                    rateApplied: 0.00,
                    useProviderRate: false
                }
                this.dialog.edit = true;
            },
            onCreateRule(data) {
                this.ruleModel = this.$filters.init({
                    id: 0,
                    markupContractAccountID: data?.id,
                    effectiveDateFROM: this.$moment.utc(),
                    effectiveDateTO: null,
                    rateType: 100,
                    rateApplied: 0,
                    rateChargeType: 100,
                    currencyCode: data?.currencyDetail?.code,
                    countryCode: data?.countryDetail?.code,
                    environmentID: data?.environmentID,
                    providerID: data?.providerDetail?.id,
                    currencyID: data?.currencyDetail?.id,
                    countryID: data?.countryDetail?.id,
                    customerAccountDetailID: data?.customerAccountID
                });
                this.dialog.rule = true;
            },
            onCancel() {
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.rule = false;
            },
            onDelete(data) {
                this.model = this.$filters.init(data);
                this.dialog.delete = true;
            },
            onEdit(data) {
                this.model = this.$filters.init(data);
                this.dialog.edit = true;
            },
            onConfirmDelete(mask) {
                this.$store.dispatch("markupcontractaccount/deleteRecordByMask", mask)
                    .then((response) => {
                        if (response.success) {
                            this.sources.grid = this.sources.grid.filter(x => x.mask !== mask);
                            this.$toast.add({ severity: 'success', summary: 'Customer Account Markup', detail: "The record was deleted successfully!", life: 3000 });
                            this.onCancel();
                        }
                        else if ((response.errors ?? []).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: response.errors[0]?.message, life: 3000 });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onConfirmEdit(data) {
                let index = this.source.findIndex(x => x.mask === data.mask);
                var model = this.$filters.init(data);
                if (index >= 0) {
                    this.$store.dispatch("markupcontractaccount/updateRecord", model)
                        .then((response) => {
                            if (response.success) {
                                this.$toast.add({ severity: 'success', summary: 'Customer Account Markup', detail: "The record was updated successfully!", life: 3000 });
                                this.onCancel();
                                this.sources.grid[index] = response.result;
                            }
                            else if ((response.errors ?? []).length > 0)
                                this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                        })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
                else {
                    this.$store.dispatch("markupcontractaccount/addRecord", model)
                        .then((response) => {
                            if (response.success) {
                                this.sources.grid.push(response.result);
                                this.$toast.add({ severity: 'success', summary: 'Customer Account Markup', detail: "The record was added successfully!", life: 3000 });
                                this.onCancel();
                            }
                            else if ((response.errors ?? []).length > 0)
                                this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                        })
                        .catch(ex => {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        });
                }
            },
            onConfirmRule(data) {
                var model = this.$filters.init(data);
                this.$store.dispatch("markupcontractrule/addRecord", model)
                    .then((response) => {
                        if (response.success) {
                            this.$toast.add({ severity: 'success', summary: 'Account Markup', detail: "The record was added successfully!", life: 3000 });
                            this.onCancel();
                            this.action.reloadKey = Math.floor(Math.random() * 9999999999);
                        }
                        else if ((response.errors ?? []).length > 0)
                            this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: response.errors[0]?.message, life: 3000 });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            onRebindSource() {
                if (this.parentID) {
                    this.$store.dispatch("markupcontractaccount/getURL", { url: `/services/facility/markupcontractaccount/bymarkupcustomer/id/${this.parentID}` })
                        .then((response) => {
                            this.sources.grid = response.result;
                            this.loading = false;
                        })
                        .catch(() => {
                            this.loading = false;
                            this.sources.grid = [];
                        });
                }
                if (this.customerID) {
                    this.$store.dispatch("markupcontractaccount/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.customerID}` })
                        .then((response) => {
                            this.sources.accounts = response.result;
                            this.loading = false;
                        })
                        .catch(() => {
                            this.loading = false;
                            this.sources.accounts = [];
                        });
                }
            }
        },
        created() {
            this.onRebindSource();
            this.action.reloadKey = Math.floor(Math.random() * 9999999999);
        }
    }
</script>