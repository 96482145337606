<template>
    <form-object :id="id" class="w-full" :autoFit="true" :label="label" :floatLabel="false" :hideLabel="false" :v$="v$" :required="required" :conditional="conditional">
        <template #default>
            <pv-file v-if="files.length === 0" :id="id"
                     :ref="id"
                     name="files"
                     :url="uploadURL"
                     :mode="type"
                     :multiple="multiple"
                     :accept="accept"
                     :auto="true"
                     :maxFileSize="maxFileSizeInBytes"
                     :chooseLabel="chooseButton"
                     :customUpload="true"
                     :disabled="disabled"
                     @uploader="myUploader" />
            <slot name="preview" slotProps="files" style="width: 100% !important;">
                <div style="margin-bottom: 1rem; width: 100% !important;">
                    <div v-for="(file, index) in activeFiles" :key="file.file" :style="[{ 'padding': '1rem 10% 0 10%' }, { 'background-color': `${background} !important` }, { 'border': '1px solid var(--gray-300)'}] ">
                        <div class="p-fluid" :style="[{'height': `${height}`}, { 'width': '100%' }, { 'display': 'block' }, { 'padding': '10px !mportant' }, { 'background': `${dynamicData(file)}` }, { 'background-position': 'center !important' }, { 'background-repeat': 'no-repeat !important'}, { 'background-size': 'contain !important' }] ">
                        </div>
                        <div class="p-fluid" style="width: calc(120% + 1rem) !important; text-align: center; margin: 0.5rem calc(calc(10% + 0.5rem) * -1); padding: 0.5rem 0; border-top: 1px solid var(--gray-300)">
                            <pv-file :id="id"
                                     :ref="id"
                                     name="files"
                                     :url="uploadURL"
                                     :mode="type"
                                     :multiple="multiple"
                                     :accept="accept"
                                     :auto="true"
                                     :maxFileSize="maxFileSizeInBytes"
                                     :chooseLabel="chooseButton"
                                     :customUpload="true"
                                     @upload="myUpload"
                                     @uploader="myUploader" style="width: 110px" />
                            <pv-button class="p-button-danger" v-if="files.length > 0 && !multiple" icon="fa fa-trash" label="Remove" style="margin-left: 5px; width: 100px;" @click="onRemoveFile(index)" />
                        </div>
                    </div>
                </div>
            </slot>
        </template>
    </form-object>

</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    export default {
        emits: ['is-sync'],
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            type: { type: String, default: 'basic', validator: (value) => /^(basic|advanced)*$/.test((value ?? 'basic').toLowerCase()) },
            fileType: { type: String, default: '*' },
            value: { type: Array, default: null },
            modelValue: { type: Array, default: null },
            // Label Related
            label: { type: String },
            chooseLabel: { type: String, default: 'Browse' },
            altLabel: { type: String, default: 'Replace' },
            multiple: { type: Boolean, default: false },
            maxFileSizeInMB: { type: Number, default: 10 },
            accept: { type: String, default: null },
            required: { type: Boolean, default: false },
            theme: { type: String, default: 'none' },
            height: { type: String, default: '10rem' },
            disabled: {type: Boolean, default: false}
        },
        data() {
            return {
                files: []
            }
        },
        computed: {
            chooseButton() { return this.files.length === 0 ? this.chooseLabel + '...' : this.altLabel + '...'; },
            uploadURL() { return `${config.config.endpoint.api}/file/upload/temp`; },
            downloadURL() { return `${config.config.endpoint.api}/file/download/temp`; },
            maxFileSizeInBytes() { return this.maxFileSizeInMB * 1024 * 1024; },
            background() { return this.theme.toLowerCase() === 'dark' ? '#222222' : (this.theme.toLowerCase() === 'light' ? '#EFEFEF' : this.theme.toLowerCase() === 'document' ? '#FFFFFF' : 'transparent'); },
            activeFiles() {
                return this.files;
            },
            cdn() { return `${config.config.endpoint.cdn}`; }
        },
        watch: {
            value() {
                this.files = this.value ?? [];
            },
            modelValue() {
                this.files = this.modelValue ?? [];
            }
        },
        methods: {
            myUploader(event) {
                var formData = new FormData();
                formData.append("image", event.files[0]);
                this.$axios.post(this.uploadURL, formData, { headers: { 'Content-Type': "multipart/form-data" } }).then((response) => {
                    this.files[0] = response.data;
                })
                event = null;
                this.$emit("is-sync", this.files);
                this.$emit("update:modelValue", this.files);
                this.$refs[this.id].files = null;
            },
            dynamicData(data) {
                let file = data;
                if (file.file)
                    return `url('data:${file.contentType};base64, ${file.file}')`;
                else
                    return `url('${this.cdn}/${file.url}?${Math.random()}')`;
            },
            onRemoveFile(index) {
                this.files.splice(index, 1);
            }
        },
        created() {
            this.files = this.modelValue ?? this.value ?? [];
        }
    }
</script>
<style>
    .p-fileupload.p-component {
        width: max-content;
        display: inline-block;
    }
</style>