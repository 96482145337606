<template>
    <div style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:1rem;">
        <pv-accordion :activeIndex="activeIndex" :multiple="false" id="ConsignmentAccordion">
            <pv-accordion-tab :style="`background: var(--surface-a)`">
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-between" style="width:100%" v-if="(model?.id ?? 0) > 0">
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">
                            <span><i style="margin-top: -0.15rem" :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.code.toLowerCase()}`"></i></span>
                            <span class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`${formData?.shipFrom_City ? formData.shipFrom_City?.toUpperCase() + ', ' : ''}${formData?.shipFrom_State ? formData.shipFrom_State?.toUpperCase() + ', ' : ''}${formData?.shipFrom_PostalCode ? formData?.shipFrom_PostalCode?.toUpperCase() + ', ' : ''}${($vm.$countries.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.code ?? '').toUpperCase()}, ${($vm.$countries.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.altName ?? $vm.$countries.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.name ?? '').toUpperCase()}`"></span> </span> 
                            <span style="padding-right:1rem; padding-left:1rem;"><i class="fa-sharp fa-solid fa-arrow-right"></i></span>
                            <span><i style="margin-top: -0.15rem" :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.code.toLowerCase()}`"></i></span>
                            <span class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`${formData?.shipTo_City ? formData.shipTo_City?.toUpperCase() + ', ' : ''}${formData?.shipTo_State ? formData.shipTo_State?.toUpperCase() + ', ' : ''}${formData?.shipTo_PostalCode ? formData?.shipTo_PostalCode?.toUpperCase() + ', ' : ''}${($vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.code ?? '').toUpperCase()}, ${($vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.altName ?? $vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.name ?? '').toUpperCase()}`"></span> </span>
                        </div>
                        <div style="margin-right: 0.5rem;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 7rem) !important;">
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><span><i class="fa-solid fa-location-dot"></i></span> &nbsp; <span v-html="`SENDER & RECIPIENT`"></span> </span>

                        </div>
                        
                    </div>
                </template>
                <div>
                    <address-form :formData="formData" @is-sync="onEventManageAddress($event)"></address-form>
                </div>
            </pv-accordion-tab>
            <!--<pv-accordion-tab>
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                        <div class="p-col-fixed p-as-start" style="width: 1.5rem"><i style="margin-top: -0.15rem" :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.code.toLowerCase()}`"></i></div>
                        <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="RECIPIENT">
                            <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; <span v-html="`RECIPIENT: ${formData?.shipTo_City ? formData.shipTo_City?.toUpperCase() + ', ' : ''}${formData?.shipTo_State ? formData.shipTo_State?.toUpperCase() + ', ' : ''}${formData?.shipTo_PostalCode ? formData?.shipTo_PostalCode?.toUpperCase() + ', ' : ''}${($vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.code ?? '').toUpperCase()}, ${($vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.altName ?? $vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.name ?? '').toUpperCase()}`"></span> </div>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100%); padding: 0;">
                    <recipient-form :formData="formData"></recipient-form>
                </div>
            </pv-accordion-tab>-->
            <pv-accordion-tab v-if="!isDomestic">
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-between" style="width:100%">
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">
                            <span><i class="fa-solid fa-file-lines"></i></span>
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"> &nbsp; <span v-html="`${$vm.$enums['incotermtype'].filter(x => x.id === model?.incoterm)[0]?.text ?? ''}`"></span></span>
                        </div>
                        <div style="margin-right: 0.5rem;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 7rem) !important;">
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> <span><i class="fa-sharp fa-solid fa-globe"></i></span> &nbsp; <span v-html="`INTERNATIONAL FORMS`"></span> </span>
                        </div>

                    </div>
                </template>
                <div style="width: calc(100%); padding: 0;">
                    <internationalforms-form :formData="formData"></internationalforms-form>
                </div>
            </pv-accordion-tab>
            <pv-accordion-tab v-if="(isDomestic || !Domestic)  && (model?.id > 0)">
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-between" style="width:100%">
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">
                            <span><i class="fa-solid fa-list-radio"></i></span>
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"> &nbsp; <span v-html="`${(model?.customerReference ?? '')}`"></span></span>
                        </div>
                        <div style="margin-right: 0.5rem;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 7rem) !important;">
                          
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">  <span><i class="fa-solid fa-box"></i></span> &nbsp; <span v-html="`PACKAGES`"></span> </span>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100%); padding: 0;">
                    <orderpackage-form :formData="formData"></orderpackage-form>
                </div>
            </pv-accordion-tab>
            <pv-accordion-tab v-if="(isDomestic || !Domestic) && (model?.id > 0)">
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-between" style="width:100%">
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">

                        </div>
                        <div style="margin-right: 0.5rem;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 7rem) !important;">
                            
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"><span><i class="fa-sharp fa-solid fa-truck-fast"></i></span> &nbsp; <span v-html="`SERVICE ALLOCATION`"></span> </span>
                        </div>
                    </div>
                    
                </template>
                <div style="width: calc(100%); padding: 0;">
                    <allocate-form :formData="formData" @is-allocate="$emit('is-allocate',$event)" @is-refresh="rebindTicker()"></allocate-form>
                </div>
            </pv-accordion-tab>
            <pv-accordion-tab v-if="(isDomestic || !Domestic)  && (model?.id > 0)">
                <template #header>
                    <div class="p-d-flex p-nogutter p-jc-between" style="width:100%">
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">
                        </div>
                        <div style="margin-right: 0.5rem;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 7rem) !important;">
                           
                            <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> <span><i class="fa fa-history"></i></span> &nbsp; <span v-html="`ACTIVITY THREAD / LOGS`"></span> </span>
                        </div>
                    </div>
                </template>
                <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                    <pv-tab>
                        <pv-tabpanel header="Threads">
                            <thread-grid :formData="model" :forceRebindTicker="forceRebindTicker.thread"></thread-grid>
                        </pv-tabpanel>
                        <pv-tabpanel header="Logs" v-if="user.userType <= 3">
                            <auditlog-grid :consignmentID="model?.id" :forceRebindTicker="forceRebindTicker.logs"></auditlog-grid>
                        </pv-tabpanel>
                    </pv-tab>
                </div>
            </pv-accordion-tab>
        </pv-accordion>
    </div>
</template>
<script>
    //import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    //import validator from '@/assets/_js/cosmos-validators.js';
    //import { useVuelidate } from '@vuelidate/core';
    //import { required, minLength, maxLength, between, decimal } from '@vuelidate/validators';

    //import collectionForm from '@/components/transaction/tms/consignment/record/collection-form.vue';
    //import recipientForm from '@/components/transaction/tms/consignment/record/recipient-form.vue';
    import orderpackageForm from '@/components/transaction/tms/consignment/record/order-form.vue';
    import internationalformsForm from '@/components/transaction/tms/consignment/record/internationalforms-form.vue';
    import allocateForm from '@/components/transaction/tms/consignment/record/allocate-formnew.vue';
    import consignmentthread from '@/components/transaction/tms/consignment/thread-grid.vue';
    //import allocateForm from '@/components/transaction/tms/consignment/allocate-form.vue';
    import auditloggrid from "@/components/facility/auditlogs/auditlog-grid.vue";
    import addressForm from '@/components/transaction/tms/consignment/address-form.vue';

    export default {
        name: 'EditGuideTemplate',
        //mixins: [mixins.RECORD],
        props: {
            formData: {type : Object }
        },
        data() {
            return { model: null, router: null, gbCountry: null, gbInternational: false, activeIndex: 0, forceRebindTicker: {thread: null , logs: null }  } },
        components: {
            'address-form': addressForm
            //,'collection-form': collectionForm
            //, 'recipient-form': recipientForm
            , 'orderpackage-form': orderpackageForm
            , 'internationalforms-form': internationalformsForm
            , 'allocate-form': allocateForm
            , 'auditlog-grid': auditloggrid
            ,'thread-grid': consignmentthread
            //,'confirmation-form': confirmationForm
        },
        watch: {
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            }
        },
        computed: {
            sessionKeys() { return this.$store.getters["auth/session"]; },
            user() { return this.$store.getters["auth/currentUser"]; },
            init() { return this.$filters.init(this.$store.getters[this.getModule + "/init"]) },
            getModule() {
                let router = this.$route;
                let meta = this.$router.currentRoute.value.meta;
                let routerName = router.name.replace("-index", "").replace("-add", "").replace("-edit", "");
                return meta.module ?? routerName;
            },
            OriginISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID)[0]?.code;
            },
            DestinationISO2() {
                return this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID)[0]?.code;
            },
            isDomestic() {
                return this.$filters.isDomestic(this.OriginISO2, this.model?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.model?.shipTo_PostalCode ?? '');
            },
            //activeIndex() {
            //    return this.isDomestic ? 2 : 3;
            //}
        },
        methods: {
            rebindTicker() {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.forceRebindTicker.thread = Math.random();
                    }, 500)
                    setTimeout(() => {
                        this.forceRebindTicker.logs = Math.random();
                    }, 1000)
                });
            },
            onCollectionSync(data) {
                data;
                
            },
            async onEventManageAddress(data) {
                data;
                this.$nextTick(() => {
                    //this.model.customerID = data.model.customerID;
                    //this.model.tradeType = data.model.tradeType;

                    //this.model.shipFrom_FirstName = data.model.shipFrom_FirstName;
                    //this.model.shipFrom_LastName = data.model.shipFrom_LastName;
                    //this.model.shipFrom_Email = data.model.shipFrom_Email;
                    //this.model.shipFrom_Mobile = data.model.shipFrom_Mobile;
                    //this.model.shipFrom_Telephone = data.model.shipFrom_Telephone;
                    //this.model.shipFrom_EORI = data.model.shipFrom_EORI;
                    //this.model.shipFrom_IOSSNumber = data.model.shipFrom_IOSSNumber;
                    //this.model.shipFrom_TaxId = data.model.shipFrom_TaxId;
                    //this.model.shipFrom_IdentityNumber = data.model.shipFrom_IdentityNumber;
                    //this.model.shipFrom_IdentityNumberType = data.model.shipFrom_IdentityNumberType;

                    //this.model.shipFrom_Address1 = data.model.shipFrom_Address1
                    //this.model.shipFrom_Address2 = data.model.shipFrom_Address2
                    //this.model.shipFrom_Address3 = data.model.shipFrom_Address3
                    //this.model.shipFrom_City = data.model.shipFrom_City
                    //this.model.shipFrom_State = data.model.shipFrom_State
                    //this.model.shipFrom_PostalCode = data.model.shipFrom_PostalCode
                    //this.model.shipFrom_CountryID = data.model.shipFrom_CountryID
                    //this.model.shipFrom_Company = data.model.shipFrom_Company
                    //this.model.shipFrom_EstablishmentType = data.model.shipFrom_EstablishmentType ?? this.model.shipFrom_EstablishmentType ?? 100;



                    //this.model.shipTo_FirstName = data.model.shipTo_FirstName;
                    //this.model.shipTo_LastName = data.model.shipTo_LastName;
                    //this.model.shipTo_Email = data.model.shipTo_Email;
                    //this.model.shipTo_Mobile = data.model.shipTo_Mobile;
                    //this.model.shipTo_Telephone = data.model.shipTo_Telephone;
                    //this.model.shipTo_EORI = data.model.shipTo_EORI;
                    //this.model.shipTo_IOSSNumber = data.model.shipTo_IOSSNumber;
                    //this.model.shipTo_TaxId = data.model.shipTo_TaxId;
                    //this.model.shipTo_IdentityNumber = data.model.shipTo_IdentityNumber;
                    //this.model.shipTo_IdentityNumberType = data.model.shipTo_IdentityNumberType;

                    //this.model.shipTo_Address1 = data.model.shipTo_Address1
                    //this.model.shipTo_Address2 = data.model.shipTo_Address2
                    //this.model.shipTo_Address3 = data.model.shipTo_Address3
                    //this.model.shipTo_City = data.model.shipTo_City
                    //this.model.shipTo_State = data.model.shipTo_State
                    //this.model.shipTo_PostalCode = data.model.shipTo_PostalCode
                    //this.model.shipTo_CountryID = data.model.shipTo_CountryID
                    //this.model.shipTo_Company = data.model.shipTo_Company
                    //this.model.shipTo_EstablishmentType = data.model.shipTo_EstablishmentType ?? this.model.shipTo_EstablishmentType ?? 100;
                });
            }
        
        },
        validations() {
            return {
                model: {
                }
            }
        },
        created() {
            this.model = this.$filters.init(this.model);
        },
        mounted() {
            let _self = this;
            this.model = _self.$props.formData;
            //console.log(this.model);
            setTimeout(() => {
                this.activeIndex = this.isDomestic ? 2 : 3;
            }, 1000);
        }
    }
</script>
<style>
    #ConsignmentAccordion .p-accordion-header-link {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid var(--gray-200);
    }

    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>