<template>
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-md-4">
            <form-lookup id="incoterm" :required="true" type="enum" source="incotermtype" :value="model.incoterm" label="Incoterm" @is-sync="model.incoterm = $event.value" :v$="v$"></form-lookup>
            <form-lookup id="customsCategoryType" :required="true" type="enum" source="customscategorytype" :value="model.customsCategoryType" label="Customs Category" @is-sync="model.customsCategoryType = $event.value" :v$="v$"></form-lookup>
            <form-lookup id="exportReason" :required="true" type="enum" source="exportreasontype" :value="model.exportReason" label="Export Reason" @is-sync="model.exportReason = $event.value" :v$="v$"></form-lookup>
            <!--<form-lookup v-show="false" id="shipmentType" :required="true" type="enum" source="parcelcategorytype" :value="model.shipmentType" label="Shipment Type" @is-sync="model.shipmentType = $event.value" :v$="v$"></form-lookup>-->
            <form-input id="cpcNumber" label="CPC Number" :v$="v$" vID="cpcNumber" :value="model.cpcNumber" @is-sync="model.cpcNumber = $event.value" :max-length="15"/>
            <form-input id="packageContent" type="textarea" label="Content" :v$="v$" :value="model.packageContent" @is-sync="model.packageContent = $event.value" :max-length="255"/>
        </div>
        <div class="p-col-12 p-md-4">
            <form-lookup id="invoiceType" :required="true" type="enum" source="invoicetype" :value="model.invoiceType" label="Invoice Type" @is-sync="model.invoiceType = $event?.value ?? 100" :v$="v$"></form-lookup>
            <form-input id="invoiceNumber" label="Invoice Number" :v$="v$" vID="invoiceNumber" :value="model.invoiceNumber" @is-sync="model.invoiceNumber = $event.value" :max-length="20" />
            <form-datetime-utc id="invoiceDate" :value="model.invoiceDate" type="date" @is-sync="model.invoiceDate = $event.value" label="Invoice Date" :v$="v$"></form-datetime-utc>
            <form-input id="invoiceNote" type="textarea" label="Invoice Note" :v$="v$" :value="model.invoiceNote" @is-sync="model.invoiceNote = $event.value" :max-length="1000"/>
            <form-numeric id="shippingCost" :value="model.shippingCost" label="Shipping Cost" decimalDigit="2" @is-sync="model.shippingCost = $event.value" type="decimal"></form-numeric>
        </div>
    </div>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/consignment-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { maxLength, helpers } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: 'InternationFormsTemplate',
        emits: ["is-sync"],
        mixins: [mixins.INTERNATIONALFORMS.FORM],
        setup: () => ({ v$: useVuelidate() }),
        validations() {
            let validator = {
                model: {
                    incoterm: { $autoDirty: true },
                    customsCategoryType: { $autoDirty: true },
                    exportReason: { $autoDirty: true },
                    cpcNumber: { $autoDirty: true },
                    packageContent: { $autoDirty: true },
                    invoiceNumber: { $autoDirty: true },
                    invoiceDate: { $autoDirty: true },
                    invoiceNote: { $autoDirty: true },
                    shippingCost: { $autoDirty: true },
                }
            }
            if (((this.model.invoiceNumber?.length ?? 0) > 0))
                validator.model.invoiceNumber = { maxLength: maxLength(35), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | - `, regex('^[a-zA-Z0-9#_./\\|-]{0,35}$')) };

            if (((this.model.cpcNumber?.length ?? 0) > 0))
                validator.model.cpcNumber = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid format. Only accepts alphanumeric values`, regex('^[a-zA-Z0-9]{0,15}$')) };

            return validator;
        },
        mounted() {
            setTimeout(() => {
                const kbButtons = document.getElementsByTagName("button");
                kbButtons.forEach(elem => {
                    elem.setAttribute('tabindex', '-1')
                });
            }, 100);
        }

    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>